.panel {
  background-color: #ffffff;
}

.titleContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.optionsCard {
  // padding: 6px 6px;
  margin: 5px 20px;
  border-radius: 100px;

  .ant-card-body {
    padding: 0px !important;
  }
}

.chartCard {
  margin: 30px 20px;
  padding: 10px 5px;
}

.showMoreBtn {
  float: right;
  background-color: transparent;
  border: none;
  color: #5a03d5;
  font-weight: bold;
  font-size: 1vw;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.chartTitle {
  font-size: 1.5vw;
  font-weight: bold;
  justify-content: flex-start;
}

.new-container {
  min-height: 140px;
  max-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.autodesigner-container {
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #bea6ff);
  min-height: 140px;
  max-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.template-container {
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #ed9163);
  min-height: 140px;
  max-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.plusIcon {
  background-color: #d9d9d9;
  text-align: center;
  border-radius: 8px;
  padding: 6px 2px;
  color: #333;
  font-weight: bold;
}

.cardIcon {
  background-color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 6px 2px;
  color: #333;
  font-weight: bold;
}

.card-text {
  color: #333;
  text-align: center;
  margin-top: 16px;
  font-size: 16px;
  font-weight: bold;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(250px, 1fr)
  ); /* Adjust card width as needed */
  gap: 10px; /* Adjust the gap between cards as needed */
  padding: 0 10px; /* Adjust padding as needed */
  margin: 0 auto; /* Center the container horizontally */
  max-width: 100%; /* Ensure the container doesn't exceed the screen width */
}

.card-group {
  display: flex;
  gap: 20px;
}
.card-group > * {
  width: 33.33%;
}

.row-container {
  padding: 20px;

  h2 {
    color: #888;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .chart-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .chart-col {
    width: calc(25% - 12px);
  }
}
