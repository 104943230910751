@import "../../../../../shared/variables";

.workflowCard {
  background-color: #d1d1d1;
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;

  .workflowItem {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
.more_btn {
  color: $primaryColor;
  font-size: 12px;
  cursor: pointer;
}
