.adLoader {
  animation: rotate 3s infinite;
  height: 50px;
  width: 50px;
  margin-bottom: 30px;
}
.adLoader:before,
.adLoader:after {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.adLoader:before {
  animation: box1 3s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 #2c0070;
  margin-bottom: 10px;
}
.adLoader:after {
  animation: box2 3s infinite;
  background-color: #2c0070;
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes box1 {
  0% {
    box-shadow: 30px 0 0 #2c0070;
  }
  50% {
    box-shadow: 0 0 0 #2c0070;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #2c0070;
    margin-bottom: 10px;
  }
}

@keyframes box2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}
