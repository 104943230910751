@import "../../../../../shared/variables";

.commentBox {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  position: relative;

  .commentDate {
    font-size: 8px;
  }

  .commentTitle {
    align-items: flex-start;
  }

  .deleteBtn {
    position: absolute;
    right: 0;
    top: 0;
  }

  .more_btn {
    color: $primaryColor;
    font-size: 10px;
  }
}
