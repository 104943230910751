.popup-container {
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  overflow: hidden;
  min-width: 400px;
}
.popup-heading,
.popup-body .popup-form,
.popup-footer {
  padding: 10px 15px;
  text-align: right;
}
.popup-heading {
  margin: 0;
  background-color: #ebebeb;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0;
  }

  button:hover {
    color: #008dd2;
  }
}
.popup-body {
  position: relative;

  .progress-bar {
    position: absolute;
    cursor: not-allowed;
    z-index: 99;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000, $alpha: 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 20px;

    .loading-icon {
      margin-left: -40px;
      margin-bottom: 15px;
    }
  }
  .popup-form {
    max-height: 400px;
    overflow: auto;
  }
  .popup-footer {
    background-color: #ebebeb;
    border-top: 1px solid #ccc;
  }
}
.d-grid {
  display: grid;
}
.form-group {
  margin-bottom: 15px;
}
.antDInput {
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  padding: 10px 15px;
}
.customBtn {
  display: block;
  cursor: pointer;
  padding: 10px 15px;
  border: 1px solid #303030;
  border-radius: 18px;
  text-align: center;
}

.error {
  color: red;
  margin-top: 5px;

  &::before {
    content: "* ";
  }
}

@keyframes pillsEffect {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.audio-btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-left: 5px;

  &.listening {
    animation: pillsEffect 0.5s linear infinite;
  }
}

.form-label {
  display: block;
  margin-bottom: 5px;
}
.dropzone p {
  border: 5px dashed #ccc;
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 150px;

  &:hover,
  &.dragging {
    border-color: #5a03d5;
    background-color: rgba($color: #5a03d5, $alpha: 0.1);
  }

  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    padding: 3px;
  }
}
.shape-dropdown {
  height: 50px;
}
.shapes-dropdown-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 200px;
  overflow: auto;

  li {
    padding: 5px 0;
    cursor: pointer;

    &:hover {
      background-color: #ebebeb;
    }
  }
}
.shapeImage {
  width: 50px;
  height: 20px;
  object-fit: contain;
}
// .tox .tox-notification--warn,
// .tox .tox-notification--warning,
// .tox-notifications-container {
//   display: none !important;
//   opacity: 0 !important;
//   pointer-events: none;
//   z-index: -999 !important;
//   position: static !important;
// }

.progress_loading {
  margin-left: 40px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: #fff;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -24px 0 #5a03d5, 24px 0 #fff;
  }
  66% {
    background: #5a03d5;
    box-shadow: -24px 0 #fff, 24px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -24px 0 #fff, 24px 0 #5a03d5;
  }
}

.filePicker {
  display: block;
  border: 5px dashed #ccc;
  text-align: center;
  padding: 30px 0;
  cursor: pointer;

  &:hover {
    border-color: #5a03d5;
    background-color: rgba($color: #5a03d5, $alpha: 0.1);
  }

  a {
    display: inline-block;
    background-color: #5a03d5;
    color: #fff;
    margin-top: 10px;
    padding: 10px 15px;
    border: 1px solid #5a03d5;
    border-radius: 50px;
  }

  input[type="file"] {
    display: none;
  }
}
