.sharePopupBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
.sharePopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: calc(100% - 30px);
  background-color: #fff;
  z-index: 999;
  border-radius: 5px;
  overflow: hidden;

  .sharePopupHeader {
    padding: 10px 15px;
    background-color: #5a03d5;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      color: inherit;
    }

    .close {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 24px;
      padding: 0;
    }
  }
  .sharePopupBody {
    padding: 10px 15px;

    .inputGroup {
      display: flex;
      margin-bottom: 20px;
      //   border: 1px solid #ccc;

      input {
        flex-grow: 1;
        border-radius: 10px;
        // border: none;
        background-color: transparent;
        border: 1px solid #ccc;
        padding: 0 15px;
      }
      select {
        border-radius: 10px;
        border: 1px solid #ccc;
        margin: 0 5px;
        padding: 0 15px;
        outline: none;
      }
    }
  }
  .shareUserList {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 400px;
    overflow: auto;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ccc;
      padding: 10px 0;

      select {
        border: none;
        outline: none;
      }
    }
  }
}

hr {
  border: none;
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
}

.errorText{
  color: red;
}
