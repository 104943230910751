.boardPopupBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: rgba($color: #000000, $alpha: 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.boardPopup {
  width: 400px;
  max-width: calc(100% - 30px);
  max-height: calc(100vh - 30px);
  overflow: auto;
  border-radius: 24px;
  background-color: #fff;
  padding: 30px;
  text-align: center;
  position: relative;

  .close {
    font-size: 48px;
    position: absolute;
    top: 0px;
    right: 15px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  .boardPopupBody {
    display: flex;
    align-items: center;

    & > div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 60px 0;

      button {
        border: none;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          width: 60px;
          height: 60px;
          object-fit: contain;
          margin-bottom: 10px;
        }
      }
    }
  }
}
