.centered {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  // transform: translate(-50%, -50%);
  font-size: 30px;
  word-break: break-all;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  overflow: hidden;
}
.react-flow__edge.selected .react-flow__edge-path,
.react-flow__edge:focus .react-flow__edge-path,
.react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: blue !important;
}

.shapes {
  position: absolute;
  background-color: #f3f3f3;
  border-radius: 15px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  display: flex;

  img {
    margin: 0 10px;
  }
}
.shape-top,
.shape-bottom {
  left: 50%;
  transform: translateX(-50%);
}
.shape-top {
  top: -80px;
}
.shape-bottom {
  bottom: -80px;
}
.shape-left,
.shape-right {
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
}
.shape-left {
  left: -120px;
}
.shape-right {
  right: -120px;
}
.rotateHandle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #3367d9;
  left: 50%;
  top: -30px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  cursor: alias;
}
.dragHandle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -70px;
}
.canvas-shape {
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    object-fit: fill;
  }
}
