@import "../../../../shared/variables";

.left-sidebar {
  min-width: 300px;
  width: 300px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $whiteColor;
  border-right: 1px solid $borderColor2;
  padding: 30px 15px;
  gap: 30px;
}

.flowBtn {
  border: none;
  background-color: $whiteColor;
}

.flowBtn:hover {
  border: none;
  background-color: transparent;
}

.upgradeCard-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $borderColor2;
  background: $secondaryColor;
  background: linear-gradient(0deg, $secondaryColor 0%, $primaryColor 100%);
  background: -moz-linear-gradient(
    0deg,
    $secondaryColor 0%,
    $primaryColor 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    $secondaryColor 0%,
    $primaryColor 100%
  );
  box-shadow: rgba($color: $blackColor, $alpha: 0.05) 0px 6px 24px 0px,
    rgba($color: $blackColor, $alpha: 0.08) 0px 0px 0px 1px;
  z-index: 1;
  border-radius: 14px;

  .centered-image {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.upgradeContainer {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  padding: 26px 4px;
  align-items: center;
}

.upgradeTxt {
  text-align: center;
  font-size: 0.9rem;
  line-height: 2.5vh;
  color: $whiteColor;
}

.premiumTxt {
  font-size: 1.4rem;
  font-weight: bold;
  color: $whiteColor;
}
.upgradeBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 18px;
  white-space: nowrap;
  background-color: $whiteColor;
  color: $primaryColor;
}

.upgradeBtn:hover {
  background-color: $secondaryColor;
  color: $primaryColor;
}

.sidebarContainer {
  height: 100%;
  min-height: 0;
  overflow: auto;
  margin: 0;

  & > li {
    margin-top: 5px;

    li {
      margin-top: 5px;
    }

    a {
      display: flex;
      gap: 10px;
      padding: 10px 15px;
      border-radius: 5px;

      .nav-arrow {
        margin-left: auto;
        transition: all ease 500ms;
      }
    }

    &:hover {
      & > a {
        background-color: $borderColor;
        color: $primaryColor;
      }
    }

    &.active {
      & > a {
        background-color: $secondaryColor;
        color: $primaryColor;

        .nav-arrow {
          transform: rotate(90deg);
        }
      }
    }

    ul {
      margin-left: 25px;

      li {
        a {
          font-weight: normal;
          color: $textColor;
        }

        &.active,
        &:hover {
          a {
            color: $primaryColor;
          }
        }
        &.active {
          a {
            font-weight: bold;
          }
        }
        &:hover {
          a {
            background-color: $borderColor;
          }
        }
      }
    }
  }
}
