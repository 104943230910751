.folder-icon {
  margin-left: -20px;
  min-height: 40px;
  background-color: #5a03d5;
  color: #333;
  font-weight: bold;
  font-size: 16px;
}

.header-logo {
  margin-left: -20px;
  width: 50px;
  height: 50px;
  padding: 2px;
  background-color: #5a03d5;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.share-icon {
  margin-left: -20px;
  min-height: 35px;
  background-color: #5a03d5;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.fileName {
  margin-top: -15px;
  max-height: 22px;
  color: #fff;
}

.menuBar {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: center;

  & > li {
    margin: 0 5px;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    a:hover,
    .btn-link:hover {
      text-decoration: underline;
    }

    ul {
      position: absolute;
      margin: 0;
      padding: 0;
      display: none;
      z-index: 9;
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #ccc;
      top: 100%;

      li {
        list-style: none;
        white-space: nowrap;

        a,
        .btn-link {
          display: block;
          padding: 5px 10px;
        }
      }
    }

    &:hover {
      ul {
        display: block;
      }
    }
  }
}

.colorPicker {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  li {
    position: relative;
    width: 40px;
    margin: 0 5px;

    button {
      width: 40px;
      height: 40px;
      font-size: 18px;
      background-color: #fff;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: #555;

      .border {
        height: 5px;
        background-color: #000;
        margin-top: 3px;
      }
    }

    .colorPickerPopup {
      position: absolute;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.canvas-menu {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  // padding-bottom: 8px;
  // padding-left: 14px;
  padding: 4px 15px;

  .left-btns {
    background-color: transparent;
    // border: 1px solid #e9e9e9;
    border: none;
    border-radius: 2px;
    margin: 0 1px;
    transition: all 0.3s ease;
    padding: 1px 12px;
    cursor: pointer;

    &.disabled {
      color: #ccc;
      cursor: not-allowed;
    }
  }

  .right-btns {
    background-color: transparent;
    border: none;
    border-radius: 2px;
    margin: 0 1px;
    transition: all 0.3s ease;
    padding: 0px 12px;
    cursor: pointer;
    margin-top: 4px;

    &:disabled {
      cursor: not-allowed;
      color: #ccc;
    }
  }

  .menu-block {
    padding: 4px 5px !important;
    background-color: #fff;
    border-radius: 2px;
  }

  ul.menu-block {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;

    li {
      position: relative;
      // padding: 4px 5px;
      display: flex;
      align-items: center;
      // border-right: 1px solid #dee2e6;

      .colorPickerPopup {
        position: absolute;
        top: 100%;
        z-index: 99;
      }
    }
  }

  .border-right {
    border-right: 1px solid #dee2e6;
  }

  .flex-fill {
    flex: 1 1 auto;
  }

  select {
    background-color: transparent;
    // border: 1px solid #e9e9e9;
    border: none;
    margin: 0 5px;
    cursor: pointer;
    word-wrap: normal;
    padding: 2px 2px;
  }
}
.custom-selectbox-dropdown {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 5px;
}
.svg-line-box {
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  cursor: pointer;
}
.box-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  // border: 1px solid #e9e9e9;
  border: none;
  // margin: 0 5px;
  cursor: pointer;
  // height: 26px;
  padding-right: 5px;
  border-right: 1px solid #e9e9e9;

  &.disabled {
    cursor: not-allowed;
    color: #ccc;

    path {
      stroke: #ccc;
    }
  }

  &::after {
    content: "▼";
    font-size: 12px;
    line-height: 100%;
    margin-left: auto;
  }
}
select:disabled {
  color: #ccc;
  cursor: not-allowed;
}
.btn-link {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.undoRedo-container {
  border: 1px solid #333;
  border-radius: 4px;
  padding: 4px 3px;
  margin-right: 10px;
  display: flex;
}

.color-container {
  border: 1px solid #333;
  border-radius: 4px;
  padding: 0px 3px;
  margin-right: 10px;
}

.borderOptions-container {
  border: 1px solid #333;
  border-radius: 4px;
  padding: 0px 3px;
}

.username-text {
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.warningText {
  color: #000;
}
