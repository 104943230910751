@import "../../../../shared/variables";

.rightSidebar-container {
  display: flex;
  flex-direction: row-reverse;
  background-color: #d9d9d9;
  border: 3px solid #ccc;
  border-width: 3px 0 0 3px;
}

.workflowContainer {
  position: relative;
  background-color: #e0e0e0;
  // min-height: 100vh;
  width: 250px;
  // min-width: 13vw;
  // max-height: 100vh;
  max-width: 250px;
  border-right: 1px solid #bebebe;
  // display: flex;
  // justify-content: center;

  .ant-card-body {
    padding: 2px 8px;
  }

  .workflow-title {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .comment-title {
    font-size: 12px;
    font-weight: bold;
  }

  .play-btn {
    border: none;
    background-color: $secondaryColor;
    color: $primaryColor;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .play-icon {
    font-size: 22px;
    color: $primaryColor;
    margin-left: 2px;
  }

  .more-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .more-icon {
    margin-left: 2px;
    font-size: 24px;
    color: $primaryColor;
  }

  .workflow-desc {
    font-size: 10px;
    font-weight: 200;
    color: #888;
    margin-top: 5px;
  }

  .comment-desc {
    font-size: 10px;
    font-weight: 200;
    margin-top: -14px;
  }

  .comments {
    font-size: 12px;
    font-weight: 200;
  }
}

.action-cols {
  :hover {
    background-color: $secondaryColor;

    .action-icons {
      color: $primaryColor !important;
    }
  }
}

.action-btns {
  border: none;
  // margin-left: 20%;
  margin: 0 0.6rem;
  margin-top: 17px;
  padding: 8px 10px;
  border-radius: 8px;
}

.active {
  // background-color: $secondaryColor;
  color: $primaryColor;
}

.action-icons {
  font-size: 20px;
}

.dlt-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dlt-icon {
  font-size: 20px;
  color: $primaryColor;
}

.addShape-container {
  background-color: $primaryColor;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  min-width: 11vw;
  max-width: 11vw;
}

.animateContainer {
  transition: left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
}

.editWorkflow-btn {
  border: none;
  background-color: transparent;
}

object {
  img {
    width: 100%;
  }
}

.closePanel {
  color: $primaryColor;
  font-size: 25px;
}

.bottom-action {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);

  button {
    background-color: #ebebeb;
    border: 1px solid #ccc;
    padding: 15px 0px;
    opacity: 0.5;
    color: #999 !important;
    border-radius: 8px 0 0 8px;
    transition: all ease 500ms;

    &:hover {
      opacity: 1;
    }
  }
}
