.active-comment {
  cursor: url("comment.cur"), cell;

  .react-flow__pane {
    cursor: url("comment.cur"), cell;
    * {
      cursor: url("comment.cur"), cell;
    }
  }
}
.workflow.actions {
  left: 0;
  right: 0;
  transform: translate(0);
  margin: 0;
}

// .autodesigner-popup {
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   z-index: 99;
//   background-color: rgba($color: #000000, $alpha: 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   .ad-popup {
//     padding: 15px;
//     background-color: #fff;
//     width: 600px;
//     max-height: calc(100vh - 30px);
//     max-width: calc(100% - 30px);
//     border-radius: 10px;
//     position: relative;

//     .close {
//       position: absolute;
//       right: 15px;
//       top: 5px;
//       z-index: 999;
//       font-size: xx-large;
//     }
//   }
// }
.light-btn {
  border-color: #fff;
}
.primary-btn {
  background-color: #5a03d5 !important;
  color: #fff !important;
}
