:where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-bottom > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-bottom
  > div
  > .ant-tabs-nav {
  margin-top: 0px !important;
}
:where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-card
  > .ant-tabs-nav
  .ant-tabs-tab,
:where(.css-dev-only-do-not-override-1n7nwfa).ant-tabs-card
  > div
  > .ant-tabs-nav
  .ant-tabs-tab {
  padding: 3px 6px;
}

.footer-addBtn {
  float: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.footer-dropBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 15px;
}

.menu-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.menu-btn:hover {
  background-color: rgba(0, 0, 0, 0.02);
  font-weight: bold;
}

.pageName-input {
  // width: 80px;
}

.footer-dropBtn:focus-visible {
  outline: none;
}

.zoom-btn {
  margin-top: 8px;
}

.pages {
  margin: 0px 5px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: 40px;

  li {
    margin: 10px 2px;
    margin-top: 0;
    position: relative;

    span,
    .span {
      display: block;
      padding: 5px 15px;
      padding-right: 25px;
      background-color: #ebebeb;
      border-radius: 0 0 5px 5px;
      border: 1px solid #ccc;
      border-top: 0;
      cursor: pointer;
    }

    &.active {
      span,
      .span {
        background-color: #fff;
      }
    }

    .savePageName {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      background-color: transparent;
      color: #666;
      cursor: pointer;
      border: 0;
    }

    .removeBtn {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      background-color: transparent;
      color: #666;
      cursor: pointer;
      border: 0;
    }

    &.addBtn {
      span {
        padding: 3px 0;
        background-color: transparent;
        color: #999;
        border: 0;
      }
    }
  }
}

.addShapeContainer {
  display: flex;
  justify-content: flex-start;
}

.addShape-btn {
  background-color: #5a03d5;
  color: #fff;
  font-weight: bold;
  min-width: 13.5rem;
  max-width: 13.5rem;
  margin-left: 14px;
  margin-top: 7px;
  margin-bottom: 5px;
}
