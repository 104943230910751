.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
  background-color: rgb(34, 34, 34);
  color: #fff;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
}

.loginTitle {
  font-size: 22px;
  font-weight: 500;
  // color: #007bff;
}

.forgotLogo {
  width: 400px;
  height: 140px;
  padding-left: 80px;
  padding-right: 100px;
  padding-bottom: 30px;
}

.input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.form-group {
  margin-bottom: 20px;
}

.submit-btn {
  width: 100%;
  background-color: #5007cf;
  color: #fff;
  padding: 10px 0;
  border-radius: 3px;
  transition: all ease 500ms;

  &:hover {
    background-color: #49bbfd;
  }
}
