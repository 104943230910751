@import "../../shared/variables";

.notiIcon {
  color: $primaryColor;
  font-size: 30px;
}

.notification {
  display: flex;
  border: 1px solid $borderColor;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 5px;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: $whiteColor;
  }

  &.highlight {
    border-color: $primaryColor;
  }

  &:not(.highlight) {
    opacity: 0.7;
  }

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
  }
}
