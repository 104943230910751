.alpha {
  border-radius: 50px;
  border-bottom-left-radius: 0;
  // display: flex;
  padding: 10px;
  font-size: 24px;
  background-color: #fff;
  cursor: auto !important;
  box-shadow: 5px 5px 10px #ccc;
  max-width: 300px;

  * {
    cursor: auto !important;
  }

  &.activeComment:hover {
    padding: 20px;
    border-radius: 30px;
    border-bottom-left-radius: 0;
    min-width: 200px;
  }

  span {
    display: block;
    background-color: palevioletred;
    border-radius: 50%;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    font-size: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    font-weight: bold;
    line-height: 100%;
    margin-bottom: 0;
  }

  .comment,
  .reply-container {
    display: none;
    margin-left: 15px;
  }

  &:hover,
  &.open {
    padding: 20px;
    border-radius: 30px 30px 30px 2px;
    .comment,
    .reply-container {
      display: block;
    }
  }
}

.comment-title {
  font-weight: bold;
}
