$primaryColor: #5a03d5;
$secondaryColor: #bea6ff;
$whiteColor: #fff;
$bodyColor: #e5e0f2;
$borderColor: #ccc;
$borderColor2: #f0f0f0;
$blackColor: #000;
$textColor: #444;

/*
accentColor
textColor
linkColor
hoverColor
activeColor
errorColor
successColor
warningColor
infoColor
darkColor
lightColor
mutedColor
gradientStartColor
gradientEndColor
highlightColor
*/
