.workflow-action {
  background: #d8d9de;
  padding: 10px 5px;

  .d-flex {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .play-btn {
    color: #6e55fb;
    padding: 0;
    border: 1px solid #6e55fb;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    &:disabled {
      border-color: #909090;
      color: #909090;
      cursor: not-allowed;
    }
  }

  select {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 6px 10px;
    background: #90b4fe;
  }

  .progress-bar {
    height: 30px;
    background-color: #90b4fe;
    margin: 5px 5px;
    margin-bottom: 10px;
    border-radius: 3px;
    overflow: hidden;

    .progress {
      background-color: #5060cf;
      height: 100%;
    }
  }
}
