@import "../../shared/variables";

.autoCompleteContainer {
  position: relative;

  .suggestionLists {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 9;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    background-color: $whiteColor;
    max-height: 400px;
    overflow: auto;
    border: 1px solid $borderColor;
    border-radius: 10px;
    box-shadow: 0 0 10px $borderColor;

    li {
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background-color: $secondaryColor;
        color: $whiteColor;
      }
    }
  }
}
