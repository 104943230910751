@keyframes rotateLoading {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(359deg);
  }
}

@keyframes scaling {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(0.5);
  }
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.logoSpinner {
  position: relative;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;

  .innerCircle,
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  .innerCircle {
    width: 100%;
    height: 100%;
    border: 5px solid;
    border-color: #5007cf transparent;
    animation: rotateLoading 1s linear infinite;
    background-color: transparent;
  }

  img {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    object-fit: contain;
    animation: scaling 1s linear infinite;
  }
}
h3 {
  color: #5007cf;
  font-weight: bold;
}
