.actionBtn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.7vw;
}

.contentContainer {
    min-width: 70%;
    max-width: 70%;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
}

.logoContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 1vw;
    margin-left: -1vw;
}

.heroLogo {
    min-width: 20vw;
    max-width: 20vw;
    min-height: 6vh;
    max-height: 6vh;
    object-fit: contain;
}


.hero-section {
    height: 100%;
    display: flex;
    flex-direction: row;

    .infoContainer {
        display: flex;
        justify-content: flex-start;
        margin-top: 15vh;
    }

    .info-title {
        font-size: 4.5vw;
        font-weight: bold;
        white-space: nowrap;
        cursor: default;
    }

    .descContainer {
        display: flex;
        justify-content: flex-start;
    }

    .info-desc {
        font-size: 1.5vw;
        letter-spacing: 0.1vw;
        white-space: nowrap;
    }

    .signbtnContainer {
        display: flex;
        justify-content: flex-start;
    }

    .imageContainer {
        display: flex;
        justify-content: flex-start;
        margin-top: -7vh;
    }

    .sec2HeaderContainer {
        text-align: center;
    }

    .sec2-heading {
        font-size: 3.1vw;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .leftCardContainer {
        display: flex;
        justify-content: center;
        margin-top: 4vh;
        height: 100%;
    }

    .rightCardContainer {
        display: flex;
        justify-content: center;
        margin-top: 4vh;
    }

    .cardHeader {
        text-align: center;
        font-weight: bold;
        font-size: 2.4vw;
    }

    .cardInfo {
        font-size: 22px;
        text-align: center;
        margin-top: 2vh;
    }

    .aboutContainer {
        display: flex;
        flex-direction: row;
    }

    .leftImageContainer {
        display: flex;
        justify-content: flex-start;
    }

    .leftContainer {
        display: flex;
        justify-content: flex-start;
    }

    .leftInfoContainer {
        white-space: nowrap;
    }

    .leftInfo-title {
        font-size: 3.5rem;
        font-weight: bold;
        white-space: nowrap;
    }

    .leftDescContainer {
        margin-top: -1vh;
    }

    .leftInfo-desc {
        font-size: 20px;
        letter-spacing: 2px;
        white-space: nowrap;
    }

    .leftImageContainer2 {
        display: flex;
        justify-content: flex-start;
        margin-top: -5vh;
        margin-left: -13vw;
    }

    .leftInfoContainer2 {
        white-space: nowrap;
        margin-top: 10vh;
    }

    .leftDescContainer2 {
        margin-top: -1vh;
    }

    .leftSignbtnContainer2 {
        display: flex;
        justify-content: flex-start;
        margin-top: 1vh;
    }

    .aiSectionContainer {
        min-width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        min-height: 100%;
        max-height: 100%;
        padding: 5px 10px;
        border: 1px solid #ebe5ff;
        background-color: #ebe5ff;
        border-radius: 40px;
        margin-top: 4vh;
        margin-bottom: 5vh;
    }

    .aiContainer {
        margin-top: 8vh;
        padding-left: 4vw;
    }

    .aiHeading {
        font-size: 2.7rem;
        font-weight: bold;
    }

    .aiDesc {
        font-size: 1.2rem;
        letter-spacing: 1px;
    }

    .aiImageContainer {
        display: flex;
        justify-content: center;
        margin-top: -3vh;
    }
    
    .aiImage {
        min-height: 60vh;
        max-height: 60vh;
        min-width: 45vw;
        max-width: 45vw;
    }

}

#section3 {
    display: flex;
    flex-direction: column;
}

#section4 {
    min-height: 70vh;
    max-height: 70vh;
}

.flowchart-section {
    height: 100%;

    .flowchartHeading {
        font-size: 3.2em;
        font-weight: bold;
        text-align: center;
    }

    .flowchartDesc {
        font-size: 1.4em;
        letter-spacing: 1px;
        text-align: center;
        margin-top: -2.5vh;
    }

    .flowchartImgContainer {
        display: flex;
        justify-content: center;
        margin-top: 5vh;
        min-width: 100%;
        max-width: 100%;
    }
    
    .flowchartImg {
        min-height: 53vh;
        max-height: 53vh;
        min-width: 100%;
        max-width: 100%;
    }

    .flowchartBtnContainer {
        display: flex;
        justify-content: center;
        margin-top: 5vh;
    }
}

.team-section {
    height: 100%;
    margin-top: 10vh;
    margin-bottom: 10vh;

    .flowchartHeading {
        font-size: 3.2em;
        font-weight: bold;
        text-align: center;
    }

    .flowchartDesc {
        font-size: 1.4em;
        letter-spacing: 1px;
        text-align: center;
        margin-top: -2.5vh;
    }
}

.text-cursor {
    cursor: text;
}

#spin:after {
    content: "";
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        content: "Charts";
    }

    40% {
        content: "Workflows"
    }

    80% {
        content: "Whiteboards"
    }
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.leftSignbtnContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 1vh;
}

.teamImg {
    height: 200px;
    width: 170px;
}

.teamTitle {
    font-size: 22px;
    font-weight: bold;
}

.teamDesignation {
    margin-top: -2vh;
}

.teamDesc {
    font-size: 16px;
}

.login-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 18px;
    white-space: nowrap;
    background-color: #5a03d5;
    color: #ffffff;
    border: 1px solid #5a03d5;
}

.login-button:hover {
    background-color: #4b05ad;
    color: #ebe5ff;
}

.login-button:active {
    background-color: #4b05ad;
    color: #ebe5ff;
}

@media (max-width: 767px) {
    .navicon {
      width: 1.125em;
      height: .125em;
    }
  
    .navicon::before,
    .navicon::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all .2s ease-out;
      content: '';
      background: #3D4852;
    }
  
    .navicon::before {
      top: 5px;
    }
  
    .navicon::after {
      top: -5px;
    }
  
    .menu-btn:not(:checked) ~ .menu {
      display: none;
    }
  
    .menu-btn:checked ~ .menu {
      display: block;
    }
  
    .menu-btn:checked ~ .menu-icon .navicon {
      background: transparent;
    }
  
    .menu-btn:checked ~ .menu-icon .navicon::before {
      transform: rotate(-45deg);
    }
  
    .menu-btn:checked ~ .menu-icon .navicon::after {
      transform: rotate(45deg);
    }
  
    .menu-btn:checked ~ .menu-icon .navicon::before,
    .menu-btn:checked ~ .menu-icon .navicon::after {
      top: 0;
    }
  }