@import "../../shared/variables";

.canvasArea {
  padding: 10px 50px;
}

.canvasArea svg {
  width: 100%;
  height: 100%;
}

.canvasArea svg g {
  cursor: move;
}

.canvasArea svg text {
  font-size: 8px;
}

#pointers {
  position: relative;
  .pointer {
    position: absolute;
    width: 15px;
    height: 22px;
    white-space: nowrap;
    padding-left: 15px;
    // background: url("https://uploads.codesandbox.io/uploads/user/88acfe5a-77fc-498c-98ee-d1b0b303f6a8/tC4n-pointer.png")
    //   no-repeat -4px 0;
    z-index: 9;

    .mouse-label {
      width: max-content;
      padding: 3px 10px;
      border-radius: 5px;
      color: $whiteColor;
      font-size: 12px;
      margin-left: 10px;
    }
  }
}
