.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0;
  background: hsla(265, 97%, 42%, 1);
  background: linear-gradient(
    180deg,
    hsla(265, 97%, 42%, 1) 30%,
    hsla(256, 100%, 83%, 1) 100%
  );
  background: -moz-linear-gradient(
    180deg,
    hsla(265, 97%, 42%, 1) 30%,
    hsla(256, 100%, 83%, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    hsla(265, 97%, 42%, 1) 30%,
    hsla(256, 100%, 83%, 1) 100%
  );
}
