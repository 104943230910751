.contextMenu {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #ede9e3;
  border: 1px solid #ccc;
  z-index: 99;
  min-width: 100px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 5px 5px 10px #ccc;

  li {
    padding: 2px 5px;
    border-radius: 5px;
    cursor: pointer;

    &:hover:not(.disabled) {
      background-color: #008dd2;
      color: #fff;
    }

    &.disabled {
      color: #ccc;
    }
  }
}
