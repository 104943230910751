@import "../../../shared/variables";

.space-align-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.space-align-block {
  flex: none;
  // margin: 8px 22px;
  // padding: 4px;
}

.templateOptCard {
  margin: 5px 20px;
  border-radius: 16px;
  background: hsla(256, 100%, 83%, 1);

  background: linear-gradient(
    0deg,
    hsla(256, 100%, 83%, 1) 0%,
    hsla(265, 97%, 42%, 1) 52%,
    hsla(258, 100%, 73%, 1) 100%
  );

  background: -moz-linear-gradient(
    0deg,
    hsla(256, 100%, 83%, 1) 0%,
    hsla(265, 97%, 42%, 1) 52%,
    hsla(258, 100%, 73%, 1) 100%
  );

  background: -webkit-linear-gradient(
    0deg,
    hsla(256, 100%, 83%, 1) 0%,
    hsla(265, 97%, 42%, 1) 52%,
    hsla(258, 100%, 73%, 1) 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;
}

.template-header {
  text-align: center;
  color: #fff;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: bold;
}

.template-content {
  text-align: center;
  color: #fff;
  font-size: 15px;
}

.searchInput {
  text-align: center;
  margin: 20px 0px;
  font-size: 18px;
  font-weight: bold;
}

.aigenerate-btn {
  color: #333;
  border: none;
  background-color: #fff;
  font-weight: bold;
  border-radius: 8px;
  padding: 8px 10px;
  margin-left: 15px;
}

.beta-tag {
  background: #4e54c8;
  background: -webkit-linear-gradient(to right, #8f94fb, #4e54c8);
  background: linear-gradient(to right, #8f94fb, #4e54c8);
  padding: 4px 8px;
  border-radius: 8px;
  color: #fff;
}

.chartCard {
  margin: 30px 20px;
}

.popup-bg {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.7);
}
.popup {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: calc(100% - 30px);
  z-index: 999;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;

  .popup-body {
    padding: 20px;
  }

  button:not(.closeBtn) {
    width: 100%;
    background-color: $primaryColor;
    color: #fff;
    padding: 20px;
  }

  .closeBtn {
    background-color: transparent;
    font-size: 48px;
    opacity: 0.5;
    margin-top: -10px;

    &:hover {
      opacity: 1;
    }
  }
}
