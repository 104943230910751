.canvasLeftSidebar {
  .sidebarHeading {
    // font-size: 16px;
    // font-weight: 700;
    // padding: 5px 0;
    // // border-top: 1px solid #ccc;
    // // border-bottom: 1px solid #ccc;
    // margin-bottom: 15px;
    // text-align: center;
    // position: fixed;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 0;
    margin-bottom: 10px;
    text-align: center;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  .sidebarImg {
    width: 100%;
    height: 45px;
  }

  .shape-container {
    margin-bottom: 15px;
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    // max-height: 35%;
    // min-height: 35%;
    overflow: auto;
    // overflow-y: scroll;
    // position: relative;
    // position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    flex: 4;
  }

  .standard-container {
    margin-bottom: 15px;
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    // max-height: 20%;
    // min-height: 20%;
    flex: 2;
  }

  .shapeGroup-container {
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    // max-height: 32%;
    // min-height: 32%;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    flex: 2;
  }

  .templateContainer {
    min-height: 90px;
    max-height: 90px;
    min-width: 90px;
    max-width: 90px;
    background-color: #ebebeb;
    border-radius: 4px;
    margin-top: 10px;
  }

  .addShapeContainer {
    flex: 1;
    display: grid;
  }
}
.shape-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 18px;
  white-space: nowrap;
  background-color: #5a03d5;
  color: #ffffff;
  border: 1px solid #5a03d5;
  // position: absolute;
  // bottom: 10px;
  // left: 5%;
}

.shape-button:hover {
  background-color: #4b05ad;
  color: #ebe5ff;
}

.shape-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  .shape-col {
    width: 20%;
    padding: 0 5px;
    height: 48px;

    img {
      width: 100%;
      height: 48px;
      object-fit: contain;
    }
  }
}
