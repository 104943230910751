@import "../../variables";

.custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 5px 8px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 18px;
  white-space: nowrap;
}

.primary-btn {
  background-color: $primaryColor;
  color: $whiteColor;
  border: 1px solid $primaryColor;
  .button-icon {
    color: $whiteColor;
  }
}

.secondary-btn {
  background-color: $whiteColor;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  .button-icon {
    color: $primaryColor;
  }
}

.primary-btn:hover {
  background-color: $whiteColor;
  color: $whiteColor;
  .button-icon {
    color: $whiteColor;
  }
}

.primary-btn:active {
  background-color: $primaryColor;
  color: $whiteColor;
}

.secondary-btn:hover {
  background-color: #d9ceff;
  color: $primaryColor;
  .button-icon {
    color: $primaryColor;
  }
}

.secondary-btn:active {
  background-color: #d9ceff;
  color: $primaryColor;
}

.primary-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: $primaryColor;
  color: $whiteColor;
  border: 1px solid $primaryColor;
  .button-icon {
    color: $whiteColor;
  }
}

.secondary-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: $whiteColor;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  .button-icon {
    color: $primaryColor;
  }
}

.button-icon {
  margin-right: 8px;
}
