@import "../../shared/variables";

body {
  background-color: $bodyColor;
}
.surveyInfo {
  header {
    box-shadow: 0 0 10px #ccc;
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: $whiteColor;
  }
  .surveyHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding: 20px 0;

    .logo {
      margin-right: auto;
    }
  }
  .customCheckGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .customCheck {
    & > div {
      padding: 10px 20px;
      border: 1px solid $borderColor;
      border-radius: 50px;
      cursor: pointer;
    }
    input[type="checkbox"],
    input[type="radio"] {
      display: none;

      &:checked + div {
        background-color: $secondaryColor;
        border-color: $primaryColor;
        color: $primaryColor;
      }
    }
  }

  .customCheck2 {
    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;

      &::before {
        content: "";
        border: 1px solid $borderColor;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
    input[type="checkbox"],
    input[type="radio"] {
      display: none;

      &:checked + div {
        color: $primaryColor;

        &::before {
          border-color: $primaryColor;
          background-color: $secondaryColor;
        }
      }
    }
  }

  main {
    padding: 60px 0;

    .container {
      max-width: 500px;
    }
  }

  form {
    select,
    input:not([type="checkbox"]):not([type="radio"]),
    textarea {
      width: 100%;
      border: 1px solid $borderColor;
      padding: 10px 20px;
      border-radius: 15px;
    }
    label.formLabel {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
      font-weight: bold;

      // &::after {
      //   content: "*";
      //   color: red;
      // }
    }
    .formGroup {
      margin-bottom: 30px;
    }
  }
}
