
.social-login {
    display: block;
    width: 100%;
    margin-top: 15px;
    text-decoration: none;
    padding: 10px 15px;
    color: #fff;
    border-radius: 3px;
    font-size: 18px;
    transition: all .3s ease-in-out;
    text-align: center;
}

.social-login:hover {
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.4);
    color: #fff;
}

a.google-login {
    background-color: #db4437;
}

a.github-login {
    background-color: #343a40;
}