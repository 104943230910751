@import "../../../../shared/variables";

.commentContainer {
  position: absolute;

  .commentInputContainer {
    border-radius: 50px;
    overflow: hidden;
    border: 1px solid #ccc;
    align-items: center;
  }

  .commentInput {
    position: relative;
    border-radius: 5px;
    padding: 10px;
  }
  .commentText {
    border: none;
    //   color: white;
    width: 256px;
    cursor: text;
    outline: none;
    direction: ltr;
    min-height: 30px;
    line-height: 1.5em;
  }

  .commentPlaceholder {
    position: absolute;
    //   z-index: -1;
    top: 15px;
    left: 10px;
    pointer-events: none;
    color: #ccc;
  }

  button {
    color: $primaryColor;
    font-size: 24px;
  }
}
