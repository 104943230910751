.canvasContent {
  width: 100% !important;
  height: 100;
  box-sizing: border-box !important;
  background-color: rgb(242, 243, 245);
}

.react-flow__attribution {
  display: none !important;
}

.canvasContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
