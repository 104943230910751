.svg-text {
  ul,
  ol {
    margin: 1em 0;
    padding-left: 0;
  }
  ul {
    list-style: inside disc;
  }
  ol {
    list-style: inside decimal;
  }
}
