ul.menuBlock {
  margin: 0;
  padding: 4px 5px;
  display: flex;
  list-style: none;
  align-items: center;

  li {
    margin-right: 10px;

    button:not(:last-child) {
      border-right: 1px solid #e9e9e9;
    }

    button,
    select {
      background-color: #fff !important;
      border-radius: 2px;
      height: 30px;
    }
  }
}

.mr-6 {
  margin-right: 1.5rem;
}
