.workflowDocuments {
  top: 0;
  bottom: 0;
  right: 38px;
  background-color: #585657;
  margin: 0;
  padding: 15px;
  color: #fff;
  white-space: nowrap;
  width: 300px;
  max-width: 300px;
  overflow: auto;

  * {
    color: #fff;
  }

  h4 {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  .wDocCard {
    background-color: #4b4b4b;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 20px;

    img {
      width: 100%;
    }

    h4 {
      display: flex;
      align-items: center;

      .anchorBtn {
        cursor: pointer;
        display: block;
        margin-left: auto;
        background-color: #646464;
        padding: 5px 10px;
        border-radius: 8px;
      }
    }
  }
}
