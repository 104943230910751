.shape-popup-container {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 15px;

  h4 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  .shape-popup-row {
    display: flex;
    width: 300px;
    flex-wrap: wrap;

    .shape-popup {
      width: 20%;
      padding: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
