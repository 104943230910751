.header {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 15px 30px;
  background-color: #fff;
  box-shadow: rgba(#000, 0.1) 0px 20px 25px -5px,
    rgba(#000, 0.04) 0px 10px 10px -5px;

  .pseudo-search {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border: 2px solid #5a03d5;
    border-radius: 10px;
    padding: 8px 15px;
    transition: background-color 0.5s ease-in-out;

    input {
      width: 100%;
      border: 0;
      background-color: transparent;
      color: #5a03d5;
      font-weight: bolder;

      &:focus {
        outline: none;
      }
    }

    button,
    i {
      border: none;
      background: none;
      cursor: pointer;
    }

    select {
      border: none;
    }

    @media (max-width: 768px) {
      .pseudo-search {
        padding: 6px 10px;
      }
    }
  }
}

.logoutBtn {
  background-color: transparent;
  border: none;
}

.username {
  font-size: 16px;
  color: #333;
  font-weight: bold;
}
