@import "../../../../shared/variables";

.replyBtn {
  color: #999;
  font-size: 10px;
}

.commentWrapper {
  height: 100%;
  overflow: auto;
}

.commentContainer {
  display: flex;
  align-items: center;
  margin: 5px 0;
  gap: 5px;

  span {
    min-width: 24px;
    min-height: 24px;
  }

  .commentInputGroup {
    flex-grow: 1;
    border-radius: 10px;
    border: 1px solid #ccc;
    display: flex;
    background-color: #fff;
    overflow: hidden;

    input {
      background-color: transparent;
      flex-grow: 1;
      padding: 5px 10px;
      border: none;
      width: 100%;

      &:focus {
        outline: none;
        border: none;
      }
    }
    button {
      padding: 5px 10px;
      background-color: $primaryColor;
      color: $whiteColor;
    }
  }
}
.more_btn {
  color: $primaryColor;
  font-size: 12px;
  cursor: pointer;
}
.replyLists {
  margin-left: 20px;
  margin-top: 10px;
  overflow: auto;
}
.replyContainer {
  padding-bottom: 10px;
}
.hasReplies {
  border-bottom: 1px solid #aaa;
}
