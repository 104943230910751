.logo {
    min-width: 20vw;
    max-width: 20vw;
    min-height: 6vh;
    max-height: 6vh;
    margin-bottom: 20px;
}

.login-form {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 4px;
    min-width: 32vw;
    max-width: 32vw;
}

// .checkbox {
    :where(.css-dev-only-do-not-override-1n7nwfa).ant-checkbox-checked .ant-checkbox-inner {
        background-color: #5a03d5 !important;
        border-color: #5a03d5 !important;
    }
// }

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form-forgot {
    float: left;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
    background-color: rgb(34, 34, 34);
    color: #fff;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px;
}

.btn-disabled {
    opacity: 0.7;
}

.loginTitle {
    font-size: 22px;
    font-weight: 500;
    // color: #007bff;
}

.social-login {
    display: block;
    width: 100%;
    // margin-top: 15px;
    text-decoration: none;
    padding: 10px 15px;
    color: #fff;
    border-radius: 3px;
    font-size: 18px;
    transition: all .3s ease-in-out;
    text-align: center;
    cursor: pointer;
}

.social-login:hover {
    box-shadow: 0 10px 15px -5px rgba(0, 0, 0, 0.4);
    color: #fff;
}

.google-login {
    background-color: #db4437;
}

.github-login {
    background-color: #343a40;
}

.area {
    background: #002244;
    background: -webkit-linear-gradient(to left, #8f94fb, #002244);
    width: 100%;
    height: 100vh;


}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}