.coverImg {
  min-height: 200px;
  max-height: 200px;
  max-width: 100%;
  position: relative;
  object-fit: contain;
  overflow: hidden;
  background-color: #d8d8d8;
}

.card-productType {
  background-color: #6c757d;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
}

.ant-card .ant-card-actions > li {
  margin: 0px !important;
}

.card-editBtn {
  background-color: #343a40;
  color: #ffffff;
  border: none;
  width: 269px;
  height: 55px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  font-size: 18px;
}

.starBtn {
  border: none;
  background-color: transparent;
  float: right;
  margin-top: 20;
  position: absolute;
  top: 5px;
  right: 15px;
}

.starBtn:not(.filled) {
  display: none;
}
.flowchartCard:hover .starBtn {
  display: block;
}

.flowchartCard {
  .ant-card-body {
    padding: 15px;
  }
  .flowName {
    color: #333333;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.flowchartIcon {
  width: 32px;
  height: 32px;
  margin-top: 6px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  border: 1px solid transparent;
}

.dateModified {
  color: #b3b3b3;
  font-size: 12px;
}

.tempCoverImg {
  min-height: 200px;
  max-height: 200px;
  max-width: 100%;
  position: relative;
  object-fit: contain;
  overflow: hidden;
}

.ant-card .ant-card-actions > li {
  margin: 0px !important;
}

.templateCard {
  background-color: rgb(204, 204, 204);
  padding: 0px;
  border-radius: 4px;
}

.template-editBtn {
  background-color: #343a40;
  color: #ffffff;
  border: none;
  width: 249px;
  height: 55px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

.flowchartCard {
  :where(.css-dev-only-do-not-override-1n7nwfa).ant-card .ant-card-body {
    padding: 15px;
  }
}

.loadingSpinner {
  border: 4px solid #5a03d5;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  width: 40px - (4px * 2);
  height: 40px - (4px * 2);
  opacity: 0.8;
  border-radius: 50%;
  animation: loadingSpinner 0.7s infinite linear;
  -webkit-animation: loadingSpinner 0.7s infinite linear;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
