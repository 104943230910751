.whiteboardIcons {
  position: absolute;
  padding: 5px;
  border-right: 1px solid #ccc;
  background: #ebebeb;
  width: 50px;
  z-index: 999;
  border-radius: 5px;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .whiteboardBtnGroup .btn,
  ul li {
    border: 1px solid #555;
    /* margin: 0 5px; */
    font-size: 5px;
    cursor: pointer;
    padding: 3px;
    background-color: #fff;
    text-align: center;
    border-radius: 5px;
    /* width: 70px; */
    position: relative;
  }

  ul li {
    position: relative;
    display: block;
    margin-top: 7px;

    .whiteboardIconsHover {
      position: absolute;
      top: 0;
      left: 100%;
      padding-left: 10px;
    }

    &:not(:hover) .whiteboardIconsHover {
      display: none;
    }

    .whiteboardIconsHover .iconsDropdownBody {
      border-radius: 3px;
      overflow: hidden;
      background-color: #ebebeb;
      display: flex;
    }

    .whiteboardIconsHover .iconsDropdownBody button {
      padding: 5px;
      background-color: transparent;
      border: 1px solid #ccc;
    }

    .whiteboardIconsHover .iconsDropdownBody button:hover {
      background-color: #fff;
    }
  }

  .whiteboardBtnGroup .btn i,
  ul li i {
    font-size: 18px;
  }
}

//

.workflow-screen .canvas-area {
  background-color: #444548;
}

.workflow-screen #chart,
.workflow-screen #chart svg {
  background: none;
}

.workflow-screen #chart .flowchart-shape:not(.active) path.body.start,
.workflow-screen #chart .flowchart-shape:not(.active) rect.body.start,
.workflow-screen #chart .flowchart-shape:not(.active) circle.body.start {
  fill: #555 !important;
  stroke: #000;
  cursor: pointer;
}
