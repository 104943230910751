.adContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  h3 {
    text-align: center;
    font-size: 16px;
  }
}
