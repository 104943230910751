@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?up761y');
  src:  url('fonts/icomoon.eot?up761y#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?up761y') format('truetype'),
    url('fonts/icomoon.woff?up761y') format('woff'),
    url('fonts/icomoon.svg?up761y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-error:before {
  content: "\e900";
}
.icon-error_outline:before {
  content: "\e901";
}
.icon-warning2:before {
  content: "\e902";
}
.icon-report_problem:before {
  content: "\e902";
}
.icon-add_alert:before {
  content: "\e903";
}
.icon-notification_important:before {
  content: "\e904";
}
.icon-album:before {
  content: "\e905";
}
.icon-av_timer:before {
  content: "\e906";
}
.icon-closed_caption:before {
  content: "\e907";
}
.icon-equalizer1:before {
  content: "\e908";
}
.icon-explicit:before {
  content: "\e909";
}
.icon-fast_forward:before {
  content: "\e90a";
}
.icon-fast_rewind:before {
  content: "\e90b";
}
.icon-games:before {
  content: "\e90c";
}
.icon-gamepad1:before {
  content: "\e90c";
}
.icon-hearing:before {
  content: "\e90d";
}
.icon-high_quality:before {
  content: "\e90e";
}
.icon-loop1:before {
  content: "\e90f";
}
.icon-sync:before {
  content: "\e90f";
}
.icon-mic1:before {
  content: "\e910";
}
.icon-mic_none:before {
  content: "\e911";
}
.icon-mic_off:before {
  content: "\e912";
}
.icon-movie:before {
  content: "\e913";
}
.icon-movie_creation:before {
  content: "\e913";
}
.icon-library_add:before {
  content: "\e914";
}
.icon-queue:before {
  content: "\e914";
}
.icon-add_to_photos:before {
  content: "\e914";
}
.icon-library_books:before {
  content: "\e915";
}
.icon-library_music:before {
  content: "\e916";
}
.icon-new_releases:before {
  content: "\e917";
}
.icon-not_interested:before {
  content: "\e918";
}
.icon-do_not_disturb:before {
  content: "\e918";
}
.icon-pause3:before {
  content: "\e919";
}
.icon-pause_circle_filled:before {
  content: "\e91a";
}
.icon-pause_circle_outline:before {
  content: "\e91b";
}
.icon-play_arrow:before {
  content: "\e91c";
}
.icon-play_circle_filled:before {
  content: "\e91d";
}
.icon-play_circle_outline:before {
  content: "\e91e";
}
.icon-playlist_add:before {
  content: "\e91f";
}
.icon-queue_music:before {
  content: "\e920";
}
.icon-radio:before {
  content: "\e921";
}
.icon-recent_actors:before {
  content: "\e922";
}
.icon-repeat1:before {
  content: "\e923";
}
.icon-repeat_one:before {
  content: "\e924";
}
.icon-replay:before {
  content: "\e925";
}
.icon-shuffle1:before {
  content: "\e926";
}
.icon-skip_next:before {
  content: "\e927";
}
.icon-skip_previous:before {
  content: "\e928";
}
.icon-snooze:before {
  content: "\e929";
}
.icon-stop3:before {
  content: "\e92a";
}
.icon-subtitles:before {
  content: "\e92b";
}
.icon-surround_sound:before {
  content: "\e92c";
}
.icon-video_collection:before {
  content: "\e92d";
}
.icon-videocam:before {
  content: "\e92e";
}
.icon-videocam_off:before {
  content: "\e92f";
}
.icon-volume_down:before {
  content: "\e930";
}
.icon-volume_mute:before {
  content: "\e931";
}
.icon-volume_off:before {
  content: "\e932";
}
.icon-volume_up:before {
  content: "\e933";
}
.icon-web:before {
  content: "\e934";
}
.icon-hd:before {
  content: "\e935";
}
.icon-sort_by_alpha:before {
  content: "\e936";
}
.icon-airplay:before {
  content: "\e937";
}
.icon-forward_10:before {
  content: "\e938";
}
.icon-forward_30:before {
  content: "\e939";
}
.icon-forward_5:before {
  content: "\e93a";
}
.icon-replay_10:before {
  content: "\e93b";
}
.icon-replay_30:before {
  content: "\e93c";
}
.icon-replay_5:before {
  content: "\e93d";
}
.icon-add_to_queue:before {
  content: "\e93e";
}
.icon-fiber_dvr:before {
  content: "\e93f";
}
.icon-fiber_new:before {
  content: "\e940";
}
.icon-playlist_play:before {
  content: "\e941";
}
.icon-art_track:before {
  content: "\e942";
}
.icon-fiber_manual_record:before {
  content: "\e943";
}
.icon-fiber_smart_record:before {
  content: "\e944";
}
.icon-music_video:before {
  content: "\e945";
}
.icon-subscriptions:before {
  content: "\e946";
}
.icon-playlist_add_check:before {
  content: "\e947";
}
.icon-queue_play_next:before {
  content: "\e948";
}
.icon-remove_from_queue:before {
  content: "\e949";
}
.icon-slow_motion_video:before {
  content: "\e94a";
}
.icon-web_asset:before {
  content: "\e94b";
}
.icon-fiber_pin:before {
  content: "\e94c";
}
.icon-branding_watermark:before {
  content: "\e94d";
}
.icon-call_to_action:before {
  content: "\e94e";
}
.icon-featured_play_list:before {
  content: "\e94f";
}
.icon-featured_video:before {
  content: "\e950";
}
.icon-note:before {
  content: "\e951";
}
.icon-video_call:before {
  content: "\e952";
}
.icon-video_label:before {
  content: "\e953";
}
.icon-4k:before {
  content: "\e954";
}
.icon-missed_video_call:before {
  content: "\e955";
}
.icon-control_camera:before {
  content: "\e956";
}
.icon-business:before {
  content: "\e957";
}
.icon-domain:before {
  content: "\e957";
}
.icon-call:before {
  content: "\e958";
}
.icon-call_end:before {
  content: "\e959";
}
.icon-call_made:before {
  content: "\e95a";
}
.icon-call_merge:before {
  content: "\e95b";
}
.icon-merge_type:before {
  content: "\e95b";
}
.icon-call_missed:before {
  content: "\e95c";
}
.icon-call_received:before {
  content: "\e95d";
}
.icon-call_split:before {
  content: "\e95e";
}
.icon-chat:before {
  content: "\e95f";
}
.icon-clear_all:before {
  content: "\e960";
}
.icon-comment1:before {
  content: "\e961";
}
.icon-contacts:before {
  content: "\e962";
}
.icon-dialer_sip:before {
  content: "\e963";
}
.icon-dialpad:before {
  content: "\e964";
}
.icon-email:before {
  content: "\e965";
}
.icon-mail1:before {
  content: "\e965";
}
.icon-markunread:before {
  content: "\e965";
}
.icon-local_post_office:before {
  content: "\e965";
}
.icon-forum:before {
  content: "\e966";
}
.icon-question_answer:before {
  content: "\e966";
}
.icon-import_export:before {
  content: "\e967";
}
.icon-invert_colors_off:before {
  content: "\e968";
}
.icon-live_help:before {
  content: "\e969";
}
.icon-location_off:before {
  content: "\e96a";
}
.icon-location_on:before {
  content: "\e96b";
}
.icon-place:before {
  content: "\e96b";
}
.icon-room:before {
  content: "\e96b";
}
.icon-message:before {
  content: "\e96c";
}
.icon-chat_bubble:before {
  content: "\e96d";
}
.icon-chat_bubble_outline:before {
  content: "\e96e";
}
.icon-no_sim:before {
  content: "\e96f";
}
.icon-signal_cellular_no_sim:before {
  content: "\e96f";
}
.icon-phone2:before {
  content: "\e970";
}
.icon-local_phone:before {
  content: "\e970";
}
.icon-portable_wifi_off:before {
  content: "\e971";
}
.icon-contact_phone:before {
  content: "\e972";
}
.icon-contact_mail:before {
  content: "\e973";
}
.icon-ring_volume:before {
  content: "\e974";
}
.icon-speaker_phone:before {
  content: "\e975";
}
.icon-stay_current_landscape:before {
  content: "\e976";
}
.icon-stay_primary_landscape:before {
  content: "\e976";
}
.icon-stay_current_portrait:before {
  content: "\e977";
}
.icon-stay_primary_portrait:before {
  content: "\e977";
}
.icon-smartphone:before {
  content: "\e977";
}
.icon-swap_calls:before {
  content: "\e978";
}
.icon-textsms:before {
  content: "\e979";
}
.icon-sms:before {
  content: "\e979";
}
.icon-voicemail:before {
  content: "\e97a";
}
.icon-vpn_key:before {
  content: "\e97b";
}
.icon-phonelink_erase:before {
  content: "\e97c";
}
.icon-phonelink_lock:before {
  content: "\e97d";
}
.icon-phonelink_ring:before {
  content: "\e97e";
}
.icon-phonelink_setup:before {
  content: "\e97f";
}
.icon-present_to_all:before {
  content: "\e980";
}
.icon-import_contacts:before {
  content: "\e981";
}
.icon-mail_outline:before {
  content: "\e982";
}
.icon-screen_share:before {
  content: "\e983";
}
.icon-stop_screen_share:before {
  content: "\e984";
}
.icon-call_missed_outgoing:before {
  content: "\e985";
}
.icon-rss_feed:before {
  content: "\e986";
}
.icon-alternate_email:before {
  content: "\e987";
}
.icon-mobile_screen_share:before {
  content: "\e988";
}
.icon-add_call:before {
  content: "\e989";
}
.icon-cancel_presentation:before {
  content: "\e98a";
}
.icon-pause_presentation:before {
  content: "\e98b";
}
.icon-unsubscribe:before {
  content: "\e98c";
}
.icon-cell_wifi:before {
  content: "\e98d";
}
.icon-sentiment_satisfied_alt:before {
  content: "\e98e";
}
.icon-list_alt:before {
  content: "\e98f";
}
.icon-domain_disabled:before {
  content: "\e990";
}
.icon-lightbulb:before {
  content: "\e991";
}
.icon-add:before {
  content: "\e992";
}
.icon-add_box:before {
  content: "\e993";
}
.icon-add_circle:before {
  content: "\e994";
}
.icon-add_circle_outline:before {
  content: "\e995";
}
.icon-control_point:before {
  content: "\e995";
}
.icon-archive1:before {
  content: "\e996";
}
.icon-backspace:before {
  content: "\e997";
}
.icon-block:before {
  content: "\e998";
}
.icon-clear:before {
  content: "\e999";
}
.icon-close1:before {
  content: "\e999";
}
.icon-content_copy:before {
  content: "\e99a";
}
.icon-content_cut:before {
  content: "\e99b";
}
.icon-content_paste:before {
  content: "\e99c";
}
.icon-create:before {
  content: "\e99d";
}
.icon-mode_edit:before {
  content: "\e99d";
}
.icon-edit1:before {
  content: "\e99d";
}
.icon-drafts:before {
  content: "\e99e";
}
.icon-filter_list:before {
  content: "\e99f";
}
.icon-flag2:before {
  content: "\e9a0";
}
.icon-assistant_photo:before {
  content: "\e9a0";
}
.icon-forward4:before {
  content: "\e9a1";
}
.icon-gesture:before {
  content: "\e9a2";
}
.icon-inbox1:before {
  content: "\e9a3";
}
.icon-link2:before {
  content: "\e9a4";
}
.icon-insert_link:before {
  content: "\e9a4";
}
.icon-redo1:before {
  content: "\e9a5";
}
.icon-remove1:before {
  content: "\e9a6";
}
.icon-remove_circle:before {
  content: "\e9a7";
}
.icon-do_not_disturb_on:before {
  content: "\e9a7";
}
.icon-remove_circle_outline:before {
  content: "\e9a8";
}
.icon-reply2:before {
  content: "\e9a9";
}
.icon-reply_all:before {
  content: "\e9aa";
}
.icon-report:before {
  content: "\e9ab";
}
.icon-save1:before {
  content: "\e9ac";
}
.icon-select_all:before {
  content: "\e9ad";
}
.icon-send1:before {
  content: "\e9ae";
}
.icon-sort1:before {
  content: "\e9af";
}
.icon-text_format:before {
  content: "\e9b0";
}
.icon-undo3:before {
  content: "\e9b1";
}
.icon-font_download:before {
  content: "\e9b2";
}
.icon-move_to_inbox:before {
  content: "\e9b3";
}
.icon-unarchive:before {
  content: "\e9b4";
}
.icon-next_week:before {
  content: "\e9b5";
}
.icon-weekend:before {
  content: "\e9b6";
}
.icon-delete_sweep:before {
  content: "\e9b7";
}
.icon-low_priority:before {
  content: "\e9b8";
}
.icon-outlined_flag:before {
  content: "\e9b9";
}
.icon-link_off:before {
  content: "\e9ba";
}
.icon-report_off:before {
  content: "\e9bb";
}
.icon-save_alt:before {
  content: "\e9bc";
}
.icon-ballot:before {
  content: "\e9bd";
}
.icon-file_copy:before {
  content: "\e9be";
}
.icon-how_to_reg:before {
  content: "\e9bf";
}
.icon-how_to_vote:before {
  content: "\e9c0";
}
.icon-waves:before {
  content: "\e9c1";
}
.icon-where_to_vote:before {
  content: "\e9c2";
}
.icon-add_link:before {
  content: "\e9c3";
}
.icon-inventory:before {
  content: "\e9c4";
}
.icon-access_alarm:before {
  content: "\e9c5";
}
.icon-alarm1:before {
  content: "\e9c5";
}
.icon-access_alarms:before {
  content: "\e9c6";
}
.icon-access_time:before {
  content: "\e9c7";
}
.icon-query_builder:before {
  content: "\e9c7";
}
.icon-schedule:before {
  content: "\e9c7";
}
.icon-add_alarm:before {
  content: "\e9c8";
}
.icon-alarm_add:before {
  content: "\e9c8";
}
.icon-airplanemode_inactive:before {
  content: "\e9c9";
}
.icon-airplanemode_active:before {
  content: "\e9ca";
}
.icon-flight:before {
  content: "\e9ca";
}
.icon-local_airport:before {
  content: "\e9ca";
}
.icon-battery_alert:before {
  content: "\e9cb";
}
.icon-battery_charging_full:before {
  content: "\e9cc";
}
.icon-battery_full:before {
  content: "\e9cd";
}
.icon-battery_std:before {
  content: "\e9cd";
}
.icon-battery_unknown:before {
  content: "\e9ce";
}
.icon-bluetooth1:before {
  content: "\e9cf";
}
.icon-bluetooth_connected:before {
  content: "\e9d0";
}
.icon-bluetooth_disabled:before {
  content: "\e9d1";
}
.icon-bluetooth_searching:before {
  content: "\e9d2";
}
.icon-bluetooth_audio:before {
  content: "\e9d2";
}
.icon-brightness_auto:before {
  content: "\e9d3";
}
.icon-brightness_high:before {
  content: "\e9d4";
}
.icon-brightness_7:before {
  content: "\e9d4";
}
.icon-brightness_low:before {
  content: "\e9d5";
}
.icon-brightness_5:before {
  content: "\e9d5";
}
.icon-brightness_medium:before {
  content: "\e9d6";
}
.icon-brightness_6:before {
  content: "\e9d6";
}
.icon-data_usage:before {
  content: "\e9d7";
}
.icon-developer_mode:before {
  content: "\e9d8";
}
.icon-devices:before {
  content: "\e9d9";
}
.icon-phonelink:before {
  content: "\e9d9";
}
.icon-dvr:before {
  content: "\e9da";
}
.icon-gps_fixed:before {
  content: "\e9db";
}
.icon-my_location:before {
  content: "\e9db";
}
.icon-gps_not_fixed:before {
  content: "\e9dc";
}
.icon-location_searching:before {
  content: "\e9dc";
}
.icon-gps_off:before {
  content: "\e9dd";
}
.icon-location_disabled:before {
  content: "\e9dd";
}
.icon-graphic_eq:before {
  content: "\e9de";
}
.icon-network_cell:before {
  content: "\e9df";
}
.icon-network_wifi:before {
  content: "\e9e0";
}
.icon-nfc:before {
  content: "\e9e1";
}
.icon-now_wallpaper:before {
  content: "\e9e2";
}
.icon-now_widgets:before {
  content: "\e9e3";
}
.icon-screen_lock_landscape:before {
  content: "\e9e4";
}
.icon-screen_lock_portrait:before {
  content: "\e9e5";
}
.icon-screen_lock_rotation:before {
  content: "\e9e6";
}
.icon-screen_rotation:before {
  content: "\e9e7";
}
.icon-sd_storage:before {
  content: "\e9e8";
}
.icon-sd_card:before {
  content: "\e9e8";
}
.icon-settings_system_daydream:before {
  content: "\e9e9";
}
.icon-signal_cellular_4_bar:before {
  content: "\e9ea";
}
.icon-signal_cellular_connected_no_internet_4_bar:before {
  content: "\e9eb";
}
.icon-signal_cellular_null:before {
  content: "\e9ec";
}
.icon-signal_cellular_off:before {
  content: "\e9ed";
}
.icon-signal_wifi_4_bar:before {
  content: "\e9ee";
}
.icon-signal_wifi_4_bar_lock:before {
  content: "\e9ef";
}
.icon-signal_wifi_off:before {
  content: "\e9f0";
}
.icon-storage:before {
  content: "\e9f1";
}
.icon-usb1:before {
  content: "\e9f2";
}
.icon-wifi_lock:before {
  content: "\e9f3";
}
.icon-wifi_tethering:before {
  content: "\e9f4";
}
.icon-add_to_home_screen:before {
  content: "\e9f5";
}
.icon-device_thermostat:before {
  content: "\e9f6";
}
.icon-mobile_friendly:before {
  content: "\e9f7";
}
.icon-mobile_off:before {
  content: "\e9f8";
}
.icon-signal_cellular_alt:before {
  content: "\e9f9";
}
.icon-attach_file:before {
  content: "\e9fa";
}
.icon-attach_money:before {
  content: "\e9fb";
}
.icon-border_all:before {
  content: "\e9fc";
}
.icon-border_bottom:before {
  content: "\e9fd";
}
.icon-border_clear:before {
  content: "\e9fe";
}
.icon-border_color:before {
  content: "\e9ff";
}
.icon-border_horizontal:before {
  content: "\ea00";
}
.icon-border_inner:before {
  content: "\ea01";
}
.icon-border_left:before {
  content: "\ea02";
}
.icon-border_outer:before {
  content: "\ea03";
}
.icon-border_right:before {
  content: "\ea04";
}
.icon-border_style:before {
  content: "\ea05";
}
.icon-border_top:before {
  content: "\ea06";
}
.icon-border_vertical:before {
  content: "\ea07";
}
.icon-format_align_center:before {
  content: "\ea08";
}
.icon-format_align_justify:before {
  content: "\ea09";
}
.icon-format_align_left:before {
  content: "\ea0a";
}
.icon-format_align_right:before {
  content: "\ea0b";
}
.icon-format_bold:before {
  content: "\ea0c";
}
.icon-format_clear:before {
  content: "\ea0d";
}
.icon-format_color_fill:before {
  content: "\ea0e";
}
.icon-format_color_reset:before {
  content: "\ea0f";
}
.icon-format_color_text:before {
  content: "\ea10";
}
.icon-format_indent_decrease:before {
  content: "\ea11";
}
.icon-format_indent_increase:before {
  content: "\ea12";
}
.icon-format_italic:before {
  content: "\ea13";
}
.icon-format_line_spacing:before {
  content: "\ea14";
}
.icon-format_list_bulleted:before {
  content: "\ea15";
}
.icon-format_list_numbered:before {
  content: "\ea16";
}
.icon-format_paint:before {
  content: "\ea17";
}
.icon-format_quote:before {
  content: "\ea18";
}
.icon-format_size:before {
  content: "\ea19";
}
.icon-format_strikethrough:before {
  content: "\ea1a";
}
.icon-format_textdirection_l_to_r:before {
  content: "\ea1b";
}
.icon-format_textdirection_r_to_l:before {
  content: "\ea1c";
}
.icon-format_underlined:before {
  content: "\ea1d";
}
.icon-functions:before {
  content: "\ea1e";
}
.icon-insert_chart:before {
  content: "\ea1f";
}
.icon-poll:before {
  content: "\ea1f";
}
.icon-assessment:before {
  content: "\ea1f";
}
.icon-insert_comment:before {
  content: "\ea20";
}
.icon-insert_drive_file:before {
  content: "\ea21";
}
.icon-insert_emoticon:before {
  content: "\ea22";
}
.icon-tag_faces:before {
  content: "\ea22";
}
.icon-mood:before {
  content: "\ea22";
}
.icon-insert_invitation:before {
  content: "\ea23";
}
.icon-event:before {
  content: "\ea23";
}
.icon-insert_photo:before {
  content: "\ea24";
}
.icon-image2:before {
  content: "\ea24";
}
.icon-photo1:before {
  content: "\ea24";
}
.icon-mode_comment:before {
  content: "\ea25";
}
.icon-publish:before {
  content: "\ea26";
}
.icon-space_bar:before {
  content: "\ea27";
}
.icon-strikethrough_s:before {
  content: "\ea28";
}
.icon-vertical_align_bottom:before {
  content: "\ea29";
}
.icon-vertical_align_center:before {
  content: "\ea2a";
}
.icon-vertical_align_top:before {
  content: "\ea2b";
}
.icon-wrap_text:before {
  content: "\ea2c";
}
.icon-money_off:before {
  content: "\ea2d";
}
.icon-drag_handle:before {
  content: "\ea2e";
}
.icon-format_shapes:before {
  content: "\ea2f";
}
.icon-highlight:before {
  content: "\ea30";
}
.icon-linear_scale:before {
  content: "\ea31";
}
.icon-short_text:before {
  content: "\ea32";
}
.icon-text_fields:before {
  content: "\ea33";
}
.icon-monetization_on:before {
  content: "\ea34";
}
.icon-title:before {
  content: "\ea35";
}
.icon-table_chart:before {
  content: "\ea36";
}
.icon-add_comment:before {
  content: "\ea37";
}
.icon-format_list_numbered_rtl:before {
  content: "\ea38";
}
.icon-scatter_plot:before {
  content: "\ea39";
}
.icon-score:before {
  content: "\ea3a";
}
.icon-insert_chart_outlined:before {
  content: "\ea3b";
}
.icon-bar_chart:before {
  content: "\ea3c";
}
.icon-notes:before {
  content: "\ea3d";
}
.icon-attachment1:before {
  content: "\ea3e";
}
.icon-cloud2:before {
  content: "\ea3f";
}
.icon-cloud_circle:before {
  content: "\ea40";
}
.icon-cloud_done:before {
  content: "\ea41";
}
.icon-cloud_download:before {
  content: "\ea42";
}
.icon-cloud_off:before {
  content: "\ea43";
}
.icon-cloud_queue:before {
  content: "\ea44";
}
.icon-cloud_upload:before {
  content: "\ea45";
}
.icon-backup:before {
  content: "\ea45";
}
.icon-file_download:before {
  content: "\ea46";
}
.icon-get_app:before {
  content: "\ea46";
}
.icon-file_upload:before {
  content: "\ea47";
}
.icon-folder2:before {
  content: "\ea48";
}
.icon-folder_open:before {
  content: "\ea49";
}
.icon-folder_shared:before {
  content: "\ea4a";
}
.icon-create_new_folder:before {
  content: "\ea4b";
}
.icon-cast:before {
  content: "\ea4c";
}
.icon-cast_connected:before {
  content: "\ea4d";
}
.icon-computer:before {
  content: "\ea4e";
}
.icon-laptop2:before {
  content: "\ea4e";
}
.icon-desktop_mac:before {
  content: "\ea4f";
}
.icon-desktop_windows:before {
  content: "\ea50";
}
.icon-developer_board:before {
  content: "\ea51";
}
.icon-dock:before {
  content: "\ea52";
}
.icon-headset:before {
  content: "\ea53";
}
.icon-headset_mic:before {
  content: "\ea54";
}
.icon-keyboard1:before {
  content: "\ea55";
}
.icon-keyboard_arrow_down:before {
  content: "\ea56";
}
.icon-keyboard_arrow_left:before {
  content: "\ea57";
}
.icon-keyboard_arrow_right:before {
  content: "\ea58";
}
.icon-keyboard_arrow_up:before {
  content: "\ea59";
}
.icon-keyboard_backspace:before {
  content: "\ea5a";
}
.icon-keyboard_capslock:before {
  content: "\ea5b";
}
.icon-keyboard_hide:before {
  content: "\ea5c";
}
.icon-keyboard_return:before {
  content: "\ea5d";
}
.icon-keyboard_tab:before {
  content: "\ea5e";
}
.icon-keyboard_voice:before {
  content: "\ea5f";
}
.icon-laptop_chromebook:before {
  content: "\ea60";
}
.icon-laptop_mac:before {
  content: "\ea61";
}
.icon-laptop_windows:before {
  content: "\ea62";
}
.icon-memory:before {
  content: "\ea63";
}
.icon-mouse:before {
  content: "\ea64";
}
.icon-phone_android:before {
  content: "\ea65";
}
.icon-phone_iphone:before {
  content: "\ea66";
}
.icon-phonelink_off:before {
  content: "\ea67";
}
.icon-router:before {
  content: "\ea68";
}
.icon-scanner:before {
  content: "\ea69";
}
.icon-security:before {
  content: "\ea6a";
}
.icon-sim_card:before {
  content: "\ea6b";
}
.icon-speaker:before {
  content: "\ea6c";
}
.icon-speaker_group:before {
  content: "\ea6d";
}
.icon-tablet2:before {
  content: "\ea6e";
}
.icon-tablet_android:before {
  content: "\ea6f";
}
.icon-tablet_mac:before {
  content: "\ea70";
}
.icon-toys:before {
  content: "\ea71";
}
.icon-tv2:before {
  content: "\ea72";
}
.icon-watch:before {
  content: "\ea73";
}
.icon-device_hub:before {
  content: "\ea74";
}
.icon-power_input:before {
  content: "\ea75";
}
.icon-devices_other:before {
  content: "\ea76";
}
.icon-videogame_asset:before {
  content: "\ea77";
}
.icon-device_unknown:before {
  content: "\ea78";
}
.icon-headset_off:before {
  content: "\ea79";
}
.icon-adjust1:before {
  content: "\ea7a";
}
.icon-assistant:before {
  content: "\ea7b";
}
.icon-audiotrack:before {
  content: "\ea7c";
}
.icon-blur_circular:before {
  content: "\ea7d";
}
.icon-blur_linear:before {
  content: "\ea7e";
}
.icon-blur_off:before {
  content: "\ea7f";
}
.icon-blur_on:before {
  content: "\ea80";
}
.icon-brightness_1:before {
  content: "\ea81";
}
.icon-brightness_2:before {
  content: "\ea82";
}
.icon-brightness_3:before {
  content: "\ea83";
}
.icon-brightness_4:before {
  content: "\ea84";
}
.icon-broken_image:before {
  content: "\ea85";
}
.icon-brush:before {
  content: "\ea86";
}
.icon-camera2:before {
  content: "\ea87";
}
.icon-camera_alt:before {
  content: "\ea88";
}
.icon-photo_camera:before {
  content: "\ea88";
}
.icon-local_see:before {
  content: "\ea88";
}
.icon-camera_front:before {
  content: "\ea89";
}
.icon-camera_rear:before {
  content: "\ea8a";
}
.icon-camera_roll:before {
  content: "\ea8b";
}
.icon-center_focus_strong:before {
  content: "\ea8c";
}
.icon-center_focus_weak:before {
  content: "\ea8d";
}
.icon-collections:before {
  content: "\ea8e";
}
.icon-photo_library:before {
  content: "\ea8e";
}
.icon-color_lens:before {
  content: "\ea8f";
}
.icon-palette:before {
  content: "\ea8f";
}
.icon-colorize:before {
  content: "\ea90";
}
.icon-compare:before {
  content: "\ea91";
}
.icon-control_point_duplicate:before {
  content: "\ea92";
}
.icon-crop_16_9:before {
  content: "\ea93";
}
.icon-crop_3_2:before {
  content: "\ea94";
}
.icon-crop2:before {
  content: "\ea95";
}
.icon-crop_5_4:before {
  content: "\ea96";
}
.icon-crop_landscape:before {
  content: "\ea96";
}
.icon-crop_7_5:before {
  content: "\ea97";
}
.icon-crop_din:before {
  content: "\ea98";
}
.icon-crop_free:before {
  content: "\ea99";
}
.icon-crop_original:before {
  content: "\ea9a";
}
.icon-crop_portrait:before {
  content: "\ea9b";
}
.icon-crop_square:before {
  content: "\ea9c";
}
.icon-dehaze:before {
  content: "\ea9d";
}
.icon-details:before {
  content: "\ea9e";
}
.icon-exposure:before {
  content: "\ea9f";
}
.icon-exposure_minus_1:before {
  content: "\eaa0";
}
.icon-exposure_minus_2:before {
  content: "\eaa1";
}
.icon-exposure_plus_1:before {
  content: "\eaa2";
}
.icon-exposure_plus_2:before {
  content: "\eaa3";
}
.icon-exposure_zero:before {
  content: "\eaa4";
}
.icon-filter_1:before {
  content: "\eaa5";
}
.icon-filter_2:before {
  content: "\eaa6";
}
.icon-filter_3:before {
  content: "\eaa7";
}
.icon-filter2:before {
  content: "\eaa8";
}
.icon-filter_4:before {
  content: "\eaa9";
}
.icon-filter_5:before {
  content: "\eaaa";
}
.icon-filter_6:before {
  content: "\eaab";
}
.icon-filter_7:before {
  content: "\eaac";
}
.icon-filter_8:before {
  content: "\eaad";
}
.icon-filter_9:before {
  content: "\eaae";
}
.icon-filter_9_plus:before {
  content: "\eaaf";
}
.icon-filter_b_and_w:before {
  content: "\eab0";
}
.icon-filter_center_focus:before {
  content: "\eab1";
}
.icon-filter_drama:before {
  content: "\eab2";
}
.icon-filter_frames:before {
  content: "\eab3";
}
.icon-filter_hdr:before {
  content: "\eab4";
}
.icon-landscape:before {
  content: "\eab4";
}
.icon-terrain:before {
  content: "\eab4";
}
.icon-filter_none:before {
  content: "\eab5";
}
.icon-filter_tilt_shift:before {
  content: "\eab6";
}
.icon-filter_vintage:before {
  content: "\eab7";
}
.icon-flare:before {
  content: "\eab8";
}
.icon-flash_auto:before {
  content: "\eab9";
}
.icon-flash_off:before {
  content: "\eaba";
}
.icon-flash_on:before {
  content: "\eabb";
}
.icon-flip:before {
  content: "\eabc";
}
.icon-gradient:before {
  content: "\eabd";
}
.icon-grain:before {
  content: "\eabe";
}
.icon-grid_off:before {
  content: "\eabf";
}
.icon-grid_on:before {
  content: "\eac0";
}
.icon-hdr_off:before {
  content: "\eac1";
}
.icon-hdr_on:before {
  content: "\eac2";
}
.icon-hdr_strong:before {
  content: "\eac3";
}
.icon-hdr_weak:before {
  content: "\eac4";
}
.icon-healing:before {
  content: "\eac5";
}
.icon-image_aspect_ratio:before {
  content: "\eac6";
}
.icon-iso:before {
  content: "\eac7";
}
.icon-leak_add:before {
  content: "\eac8";
}
.icon-leak_remove:before {
  content: "\eac9";
}
.icon-lens:before {
  content: "\eaca";
}
.icon-looks_3:before {
  content: "\eacb";
}
.icon-looks:before {
  content: "\eacc";
}
.icon-looks_4:before {
  content: "\eacd";
}
.icon-looks_5:before {
  content: "\eace";
}
.icon-looks_6:before {
  content: "\eacf";
}
.icon-looks_one:before {
  content: "\ead0";
}
.icon-looks_two:before {
  content: "\ead1";
}
.icon-loupe:before {
  content: "\ead2";
}
.icon-monochrome_photos:before {
  content: "\ead3";
}
.icon-music_note:before {
  content: "\ead4";
}
.icon-nature:before {
  content: "\ead5";
}
.icon-nature_people:before {
  content: "\ead6";
}
.icon-navigate_before:before {
  content: "\ead7";
}
.icon-chevron_left:before {
  content: "\ead7";
}
.icon-navigate_next:before {
  content: "\ead8";
}
.icon-chevron_right:before {
  content: "\ead8";
}
.icon-panorama:before {
  content: "\ead9";
}
.icon-panorama_fish_eye:before {
  content: "\eada";
}
.icon-radio_button_unchecked:before {
  content: "\eada";
}
.icon-panorama_horizontal:before {
  content: "\eadb";
}
.icon-panorama_vertical:before {
  content: "\eadc";
}
.icon-panorama_wide_angle:before {
  content: "\eadd";
}
.icon-photo_album:before {
  content: "\eade";
}
.icon-picture_as_pdf:before {
  content: "\eadf";
}
.icon-portrait:before {
  content: "\eae0";
}
.icon-remove_red_eye:before {
  content: "\eae1";
}
.icon-visibility:before {
  content: "\eae1";
}
.icon-rotate_90_degrees_ccw:before {
  content: "\eae2";
}
.icon-rotate_left:before {
  content: "\eae3";
}
.icon-rotate_right:before {
  content: "\eae4";
}
.icon-slideshow:before {
  content: "\eae5";
}
.icon-straighten:before {
  content: "\eae6";
}
.icon-style:before {
  content: "\eae7";
}
.icon-switch_camera:before {
  content: "\eae8";
}
.icon-switch_video:before {
  content: "\eae9";
}
.icon-texture:before {
  content: "\eaea";
}
.icon-timelapse:before {
  content: "\eaeb";
}
.icon-timer_10:before {
  content: "\eaec";
}
.icon-timer_3:before {
  content: "\eaed";
}
.icon-timer:before {
  content: "\eaee";
}
.icon-timer_off:before {
  content: "\eaef";
}
.icon-tonality:before {
  content: "\eaf0";
}
.icon-transform:before {
  content: "\eaf1";
}
.icon-tune:before {
  content: "\eaf2";
}
.icon-view_comfortable:before {
  content: "\eaf3";
}
.icon-view_compact:before {
  content: "\eaf4";
}
.icon-wb_auto:before {
  content: "\eaf5";
}
.icon-wb_cloudy:before {
  content: "\eaf6";
}
.icon-wb_incandescent:before {
  content: "\eaf7";
}
.icon-wb_sunny:before {
  content: "\eaf8";
}
.icon-collections_bookmark:before {
  content: "\eaf9";
}
.icon-photo_size_select_actual:before {
  content: "\eafa";
}
.icon-photo_size_select_large:before {
  content: "\eafb";
}
.icon-photo_size_select_small:before {
  content: "\eafc";
}
.icon-vignette:before {
  content: "\eafd";
}
.icon-wb_iridescent:before {
  content: "\eafe";
}
.icon-crop_rotate:before {
  content: "\eaff";
}
.icon-linked_camera:before {
  content: "\eb00";
}
.icon-add_a_photo:before {
  content: "\eb01";
}
.icon-movie_filter:before {
  content: "\eb02";
}
.icon-photo_filter:before {
  content: "\eb03";
}
.icon-burst_mode:before {
  content: "\eb04";
}
.icon-shutter_speed:before {
  content: "\eb05";
}
.icon-add_photo_alternate:before {
  content: "\eb06";
}
.icon-image_search:before {
  content: "\eb07";
}
.icon-music_off:before {
  content: "\eb08";
}
.icon-beenhere:before {
  content: "\eb09";
}
.icon-directions:before {
  content: "\eb0a";
}
.icon-directions_bike:before {
  content: "\eb0b";
}
.icon-directions_bus:before {
  content: "\eb0c";
}
.icon-directions_car:before {
  content: "\eb0d";
}
.icon-directions_ferry:before {
  content: "\eb0e";
}
.icon-directions_subway:before {
  content: "\eb0f";
}
.icon-directions_transit:before {
  content: "\eb0f";
}
.icon-directions_railway:before {
  content: "\eb10";
}
.icon-directions_walk:before {
  content: "\eb11";
}
.icon-hotel1:before {
  content: "\eb12";
}
.icon-local_hotel:before {
  content: "\eb12";
}
.icon-layers:before {
  content: "\eb13";
}
.icon-layers_clear:before {
  content: "\eb14";
}
.icon-local_atm:before {
  content: "\eb15";
}
.icon-local_attraction:before {
  content: "\eb16";
}
.icon-local_play:before {
  content: "\eb16";
}
.icon-local_bar:before {
  content: "\eb17";
}
.icon-local_cafe:before {
  content: "\eb18";
}
.icon-free_breakfast:before {
  content: "\eb18";
}
.icon-local_car_wash:before {
  content: "\eb19";
}
.icon-local_convenience_store:before {
  content: "\eb1a";
}
.icon-local_drink:before {
  content: "\eb1b";
}
.icon-local_florist:before {
  content: "\eb1c";
}
.icon-local_gas_station:before {
  content: "\eb1d";
}
.icon-local_grocery_store:before {
  content: "\eb1e";
}
.icon-shopping_cart:before {
  content: "\eb1e";
}
.icon-local_hospital:before {
  content: "\eb1f";
}
.icon-local_laundry_service:before {
  content: "\eb20";
}
.icon-local_library:before {
  content: "\eb21";
}
.icon-local_mall:before {
  content: "\eb22";
}
.icon-local_movies:before {
  content: "\eb23";
}
.icon-theaters:before {
  content: "\eb23";
}
.icon-local_offer:before {
  content: "\eb24";
}
.icon-local_parking:before {
  content: "\eb25";
}
.icon-local_pharmacy:before {
  content: "\eb26";
}
.icon-local_pizza:before {
  content: "\eb27";
}
.icon-local_print_shop:before {
  content: "\eb28";
}
.icon-print1:before {
  content: "\eb28";
}
.icon-local_restaurant:before {
  content: "\eb29";
}
.icon-restaurant_menu:before {
  content: "\eb29";
}
.icon-local_shipping:before {
  content: "\eb2a";
}
.icon-local_taxi:before {
  content: "\eb2b";
}
.icon-location_history:before {
  content: "\eb2c";
}
.icon-map3:before {
  content: "\eb2d";
}
.icon-navigation:before {
  content: "\eb2e";
}
.icon-pin_drop:before {
  content: "\eb2f";
}
.icon-rate_review:before {
  content: "\eb30";
}
.icon-satellite:before {
  content: "\eb31";
}
.icon-store_mall_directory:before {
  content: "\eb32";
}
.icon-store:before {
  content: "\eb32";
}
.icon-traffic:before {
  content: "\eb33";
}
.icon-directions_run:before {
  content: "\eb34";
}
.icon-add_location:before {
  content: "\eb35";
}
.icon-edit_location:before {
  content: "\eb36";
}
.icon-near_me:before {
  content: "\eb37";
}
.icon-person_pin_circle:before {
  content: "\eb38";
}
.icon-zoom_out_map:before {
  content: "\eb39";
}
.icon-restaurant:before {
  content: "\eb3a";
}
.icon-ev_station:before {
  content: "\eb3b";
}
.icon-streetview:before {
  content: "\eb3c";
}
.icon-subway1:before {
  content: "\eb3d";
}
.icon-train1:before {
  content: "\eb3e";
}
.icon-tram:before {
  content: "\eb3f";
}
.icon-transfer_within_a_station:before {
  content: "\eb40";
}
.icon-atm:before {
  content: "\eb41";
}
.icon-category:before {
  content: "\eb42";
}
.icon-not_listed_location:before {
  content: "\eb43";
}
.icon-departure_board:before {
  content: "\eb44";
}
.icon-360:before {
  content: "\eb45";
}
.icon-edit_attributes:before {
  content: "\eb46";
}
.icon-transit_enterexit:before {
  content: "\eb47";
}
.icon-fastfood:before {
  content: "\eb48";
}
.icon-trip_origin:before {
  content: "\eb49";
}
.icon-compass_calibration:before {
  content: "\eb4a";
}
.icon-money1:before {
  content: "\eb4b";
}
.icon-apps:before {
  content: "\eb4c";
}
.icon-arrow_back:before {
  content: "\eb4d";
}
.icon-arrow_drop_down:before {
  content: "\eb4e";
}
.icon-arrow_drop_down_circle:before {
  content: "\eb4f";
}
.icon-arrow_drop_up:before {
  content: "\eb50";
}
.icon-arrow_forward:before {
  content: "\eb51";
}
.icon-cancel:before {
  content: "\eb52";
}
.icon-check1:before {
  content: "\eb53";
}
.icon-expand_less:before {
  content: "\eb54";
}
.icon-expand_more:before {
  content: "\eb55";
}
.icon-fullscreen:before {
  content: "\eb56";
}
.icon-fullscreen_exit:before {
  content: "\eb57";
}
.icon-menu1:before {
  content: "\eb58";
}
.icon-keyboard_control:before {
  content: "\eb59";
}
.icon-more_vert:before {
  content: "\eb5a";
}
.icon-refresh1:before {
  content: "\eb5b";
}
.icon-unfold_less:before {
  content: "\eb5c";
}
.icon-unfold_more:before {
  content: "\eb5d";
}
.icon-arrow_upward:before {
  content: "\eb5e";
}
.icon-subdirectory_arrow_left:before {
  content: "\eb5f";
}
.icon-subdirectory_arrow_right:before {
  content: "\eb60";
}
.icon-arrow_downward:before {
  content: "\eb61";
}
.icon-first_page:before {
  content: "\eb62";
}
.icon-last_page:before {
  content: "\eb63";
}
.icon-arrow_left:before {
  content: "\eb64";
}
.icon-arrow_right:before {
  content: "\eb65";
}
.icon-arrow_back_ios:before {
  content: "\eb66";
}
.icon-arrow_forward_ios:before {
  content: "\eb67";
}
.icon-adb:before {
  content: "\eb68";
}
.icon-disc_full:before {
  content: "\eb69";
}
.icon-do_not_disturb_alt:before {
  content: "\eb6a";
}
.icon-drive_eta:before {
  content: "\eb6b";
}
.icon-time_to_leave:before {
  content: "\eb6b";
}
.icon-event_available:before {
  content: "\eb6c";
}
.icon-event_busy:before {
  content: "\eb6d";
}
.icon-event_note:before {
  content: "\eb6e";
}
.icon-folder_special:before {
  content: "\eb6f";
}
.icon-mms:before {
  content: "\eb70";
}
.icon-more:before {
  content: "\eb71";
}
.icon-network_locked:before {
  content: "\eb72";
}
.icon-phone_bluetooth_speaker:before {
  content: "\eb73";
}
.icon-phone_forwarded:before {
  content: "\eb74";
}
.icon-phone_in_talk:before {
  content: "\eb75";
}
.icon-phone_locked:before {
  content: "\eb76";
}
.icon-phone_missed:before {
  content: "\eb77";
}
.icon-phone_paused:before {
  content: "\eb78";
}
.icon-sim_card_alert:before {
  content: "\eb79";
}
.icon-sms_failed:before {
  content: "\eb7a";
}
.icon-feedback:before {
  content: "\eb7a";
}
.icon-sync_disabled:before {
  content: "\eb7b";
}
.icon-sync_problem:before {
  content: "\eb7c";
}
.icon-system_update:before {
  content: "\eb7d";
}
.icon-tap_and_play:before {
  content: "\eb7e";
}
.icon-vibration:before {
  content: "\eb7f";
}
.icon-voice_chat:before {
  content: "\eb80";
}
.icon-vpn_lock:before {
  content: "\eb81";
}
.icon-airline_seat_flat:before {
  content: "\eb82";
}
.icon-airline_seat_flat_angled:before {
  content: "\eb83";
}
.icon-airline_seat_individual_suite:before {
  content: "\eb84";
}
.icon-airline_seat_legroom_extra:before {
  content: "\eb85";
}
.icon-airline_seat_legroom_normal:before {
  content: "\eb86";
}
.icon-airline_seat_legroom_reduced:before {
  content: "\eb87";
}
.icon-airline_seat_recline_extra:before {
  content: "\eb88";
}
.icon-airline_seat_recline_normal:before {
  content: "\eb89";
}
.icon-confirmation_number:before {
  content: "\eb8a";
}
.icon-live_tv:before {
  content: "\eb8b";
}
.icon-ondemand_video:before {
  content: "\eb8c";
}
.icon-personal_video:before {
  content: "\eb8d";
}
.icon-power1:before {
  content: "\eb8e";
}
.icon-wc:before {
  content: "\eb8f";
}
.icon-wifi1:before {
  content: "\eb90";
}
.icon-enhanced_encryption:before {
  content: "\eb91";
}
.icon-network_check:before {
  content: "\eb92";
}
.icon-no_encryption:before {
  content: "\eb93";
}
.icon-rv_hookup:before {
  content: "\eb94";
}
.icon-do_not_disturb_off:before {
  content: "\eb95";
}
.icon-priority_high:before {
  content: "\eb96";
}
.icon-power_off:before {
  content: "\eb97";
}
.icon-tv_off:before {
  content: "\eb98";
}
.icon-wifi_off:before {
  content: "\eb99";
}
.icon-phone_callback:before {
  content: "\eb9a";
}
.icon-pie_chart:before {
  content: "\eb9b";
}
.icon-pie_chart_outlined:before {
  content: "\eb9c";
}
.icon-bubble_chart:before {
  content: "\eb9d";
}
.icon-multiline_chart:before {
  content: "\eb9e";
}
.icon-show_chart:before {
  content: "\eb9f";
}
.icon-cake:before {
  content: "\eba0";
}
.icon-group1:before {
  content: "\eba1";
}
.icon-people:before {
  content: "\eba1";
}
.icon-group_add:before {
  content: "\eba2";
}
.icon-location_city:before {
  content: "\eba3";
}
.icon-mood_bad:before {
  content: "\eba4";
}
.icon-notifications:before {
  content: "\eba5";
}
.icon-notifications_none:before {
  content: "\eba6";
}
.icon-notifications_off:before {
  content: "\eba7";
}
.icon-notifications_active:before {
  content: "\eba8";
}
.icon-notifications_paused:before {
  content: "\eba9";
}
.icon-pages:before {
  content: "\ebaa";
}
.icon-party_mode:before {
  content: "\ebab";
}
.icon-people_outline:before {
  content: "\ebac";
}
.icon-person:before {
  content: "\ebad";
}
.icon-person_add:before {
  content: "\ebae";
}
.icon-person_outline:before {
  content: "\ebaf";
}
.icon-perm_identity:before {
  content: "\ebaf";
}
.icon-plus_one:before {
  content: "\ebb0";
}
.icon-public:before {
  content: "\ebb1";
}
.icon-school:before {
  content: "\ebb2";
}
.icon-share3:before {
  content: "\ebb3";
}
.icon-whatshot:before {
  content: "\ebb4";
}
.icon-sentiment_dissatisfied:before {
  content: "\ebb5";
}
.icon-sentiment_neutral:before {
  content: "\ebb6";
}
.icon-sentiment_satisfied:before {
  content: "\ebb7";
}
.icon-sentiment_very_dissatisfied:before {
  content: "\ebb8";
}
.icon-sentiment_very_satisfied:before {
  content: "\ebb9";
}
.icon-thumb_down_alt:before {
  content: "\ebba";
}
.icon-thumb_up_alt:before {
  content: "\ebbb";
}
.icon-check_box:before {
  content: "\ebbc";
}
.icon-check_box_outline_blank:before {
  content: "\ebbd";
}
.icon-radio_button_checked:before {
  content: "\ebbe";
}
.icon-star1:before {
  content: "\ebbf";
}
.icon-grade:before {
  content: "\ebbf";
}
.icon-star_half:before {
  content: "\ebc0";
}
.icon-star_outline:before {
  content: "\ebc1";
}
.icon-3d_rotation:before {
  content: "\ebc2";
}
.icon-accessibility1:before {
  content: "\ebc3";
}
.icon-account_balance:before {
  content: "\ebc4";
}
.icon-account_balance_wallet:before {
  content: "\ebc5";
}
.icon-account_box:before {
  content: "\ebc6";
}
.icon-account_circle:before {
  content: "\ebc7";
}
.icon-add_shopping_cart:before {
  content: "\ebc8";
}
.icon-alarm_off:before {
  content: "\ebc9";
}
.icon-alarm_on:before {
  content: "\ebca";
}
.icon-android2:before {
  content: "\ebcb";
}
.icon-announcement:before {
  content: "\ebcc";
}
.icon-aspect_ratio:before {
  content: "\ebcd";
}
.icon-assignment:before {
  content: "\ebce";
}
.icon-assignment_ind:before {
  content: "\ebcf";
}
.icon-assignment_late:before {
  content: "\ebd0";
}
.icon-assignment_return:before {
  content: "\ebd1";
}
.icon-assignment_returned:before {
  content: "\ebd2";
}
.icon-assignment_turned_in:before {
  content: "\ebd3";
}
.icon-autorenew:before {
  content: "\ebd4";
}
.icon-book2:before {
  content: "\ebd5";
}
.icon-class:before {
  content: "\ebd5";
}
.icon-bookmark2:before {
  content: "\ebd6";
}
.icon-turned_in:before {
  content: "\ebd6";
}
.icon-bookmark_outline:before {
  content: "\ebd7";
}
.icon-turned_in_not:before {
  content: "\ebd7";
}
.icon-bug_report:before {
  content: "\ebd8";
}
.icon-build:before {
  content: "\ebd9";
}
.icon-cached:before {
  content: "\ebda";
}
.icon-change_history:before {
  content: "\ebdb";
}
.icon-check_circle:before {
  content: "\ebdc";
}
.icon-chrome_reader_mode:before {
  content: "\ebdd";
}
.icon-code1:before {
  content: "\ebde";
}
.icon-credit_card:before {
  content: "\ebdf";
}
.icon-payment:before {
  content: "\ebdf";
}
.icon-dashboard1:before {
  content: "\ebe0";
}
.icon-delete:before {
  content: "\ebe1";
}
.icon-description:before {
  content: "\ebe2";
}
.icon-dns:before {
  content: "\ebe3";
}
.icon-done:before {
  content: "\ebe4";
}
.icon-done_all:before {
  content: "\ebe5";
}
.icon-exit_to_app:before {
  content: "\ebe6";
}
.icon-explore:before {
  content: "\ebe7";
}
.icon-extension:before {
  content: "\ebe8";
}
.icon-face:before {
  content: "\ebe9";
}
.icon-favorite:before {
  content: "\ebea";
}
.icon-favorite_outline:before {
  content: "\ebeb";
}
.icon-find_in_page:before {
  content: "\ebec";
}
.icon-find_replace:before {
  content: "\ebed";
}
.icon-flip_to_back:before {
  content: "\ebee";
}
.icon-flip_to_front:before {
  content: "\ebef";
}
.icon-group_work:before {
  content: "\ebf0";
}
.icon-help:before {
  content: "\ebf1";
}
.icon-highlight_remove:before {
  content: "\ebf2";
}
.icon-history2:before {
  content: "\ebf3";
}
.icon-restore:before {
  content: "\ebf3";
}
.icon-home4:before {
  content: "\ebf4";
}
.icon-hourglass_empty:before {
  content: "\ebf5";
}
.icon-hourglass_full:before {
  content: "\ebf6";
}
.icon-https:before {
  content: "\ebf7";
}
.icon-lock2:before {
  content: "\ebf7";
}
.icon-info2:before {
  content: "\ebf8";
}
.icon-info_outline:before {
  content: "\ebf9";
}
.icon-input:before {
  content: "\ebfa";
}
.icon-invert_colors_on:before {
  content: "\ebfb";
}
.icon-label:before {
  content: "\ebfc";
}
.icon-label_outline:before {
  content: "\ebfd";
}
.icon-language1:before {
  content: "\ebfe";
}
.icon-launch:before {
  content: "\ebff";
}
.icon-open_in_new:before {
  content: "\ebff";
}
.icon-list3:before {
  content: "\ec00";
}
.icon-lock_open:before {
  content: "\ec01";
}
.icon-lock_outline:before {
  content: "\ec02";
}
.icon-loyalty:before {
  content: "\ec03";
}
.icon-markunread_mailbox:before {
  content: "\ec04";
}
.icon-note_add:before {
  content: "\ec05";
}
.icon-open_in_browser:before {
  content: "\ec06";
}
.icon-open_with:before {
  content: "\ec07";
}
.icon-pageview:before {
  content: "\ec08";
}
.icon-perm_camera_mic:before {
  content: "\ec09";
}
.icon-perm_contact_calendar:before {
  content: "\ec0a";
}
.icon-perm_data_setting:before {
  content: "\ec0b";
}
.icon-perm_device_information:before {
  content: "\ec0c";
}
.icon-perm_media:before {
  content: "\ec0d";
}
.icon-perm_phone_msg:before {
  content: "\ec0e";
}
.icon-perm_scan_wifi:before {
  content: "\ec0f";
}
.icon-picture_in_picture:before {
  content: "\ec10";
}
.icon-polymer:before {
  content: "\ec11";
}
.icon-power_settings_new:before {
  content: "\ec12";
}
.icon-receipt:before {
  content: "\ec13";
}
.icon-redeem:before {
  content: "\ec14";
}
.icon-card_giftcard:before {
  content: "\ec14";
}
.icon-search2:before {
  content: "\ec15";
}
.icon-settings:before {
  content: "\ec16";
}
.icon-settings_applications:before {
  content: "\ec17";
}
.icon-settings_backup_restore:before {
  content: "\ec18";
}
.icon-settings_bluetooth:before {
  content: "\ec19";
}
.icon-settings_cell:before {
  content: "\ec1a";
}
.icon-settings_brightness:before {
  content: "\ec1b";
}
.icon-settings_ethernet:before {
  content: "\ec1c";
}
.icon-settings_input_antenna:before {
  content: "\ec1d";
}
.icon-settings_input_component:before {
  content: "\ec1e";
}
.icon-settings_input_composite:before {
  content: "\ec1e";
}
.icon-settings_input_hdmi:before {
  content: "\ec1f";
}
.icon-settings_input_svideo:before {
  content: "\ec20";
}
.icon-settings_overscan:before {
  content: "\ec21";
}
.icon-settings_phone:before {
  content: "\ec22";
}
.icon-settings_power:before {
  content: "\ec23";
}
.icon-settings_remote:before {
  content: "\ec24";
}
.icon-settings_voice:before {
  content: "\ec25";
}
.icon-shop:before {
  content: "\ec26";
}
.icon-shop_two:before {
  content: "\ec27";
}
.icon-shopping_basket:before {
  content: "\ec28";
}
.icon-speaker_notes:before {
  content: "\ec29";
}
.icon-spellcheck:before {
  content: "\ec2a";
}
.icon-stars:before {
  content: "\ec2b";
}
.icon-subject:before {
  content: "\ec2c";
}
.icon-supervisor_account:before {
  content: "\ec2d";
}
.icon-swap_horiz:before {
  content: "\ec2e";
}
.icon-swap_vert:before {
  content: "\ec2f";
}
.icon-swap_vertical_circle:before {
  content: "\ec30";
}
.icon-system_update_alt:before {
  content: "\ec31";
}
.icon-tab1:before {
  content: "\ec32";
}
.icon-tab_unselected:before {
  content: "\ec33";
}
.icon-thumb_down:before {
  content: "\ec34";
}
.icon-thumb_up:before {
  content: "\ec35";
}
.icon-thumbs_up_down:before {
  content: "\ec36";
}
.icon-toc:before {
  content: "\ec37";
}
.icon-today:before {
  content: "\ec38";
}
.icon-toll:before {
  content: "\ec39";
}
.icon-track_changes:before {
  content: "\ec3a";
}
.icon-translate:before {
  content: "\ec3b";
}
.icon-trending_down:before {
  content: "\ec3c";
}
.icon-trending_neutral:before {
  content: "\ec3d";
}
.icon-trending_up:before {
  content: "\ec3e";
}
.icon-verified_user:before {
  content: "\ec3f";
}
.icon-view_agenda:before {
  content: "\ec40";
}
.icon-view_array:before {
  content: "\ec41";
}
.icon-view_carousel:before {
  content: "\ec42";
}
.icon-view_column:before {
  content: "\ec43";
}
.icon-view_day:before {
  content: "\ec44";
}
.icon-view_headline:before {
  content: "\ec45";
}
.icon-view_list:before {
  content: "\ec46";
}
.icon-view_module:before {
  content: "\ec47";
}
.icon-view_quilt:before {
  content: "\ec48";
}
.icon-view_stream:before {
  content: "\ec49";
}
.icon-view_week:before {
  content: "\ec4a";
}
.icon-visibility_off:before {
  content: "\ec4b";
}
.icon-card_membership:before {
  content: "\ec4c";
}
.icon-card_travel:before {
  content: "\ec4d";
}
.icon-work:before {
  content: "\ec4e";
}
.icon-youtube_searched_for:before {
  content: "\ec4f";
}
.icon-eject2:before {
  content: "\ec50";
}
.icon-camera_enhance:before {
  content: "\ec51";
}
.icon-help_outline:before {
  content: "\ec52";
}
.icon-reorder1:before {
  content: "\ec53";
}
.icon-zoom_in:before {
  content: "\ec54";
}
.icon-zoom_out:before {
  content: "\ec55";
}
.icon-http:before {
  content: "\ec56";
}
.icon-event_seat:before {
  content: "\ec57";
}
.icon-flight_land:before {
  content: "\ec58";
}
.icon-flight_takeoff:before {
  content: "\ec59";
}
.icon-play_for_work:before {
  content: "\ec5a";
}
.icon-gif:before {
  content: "\ec5b";
}
.icon-indeterminate_check_box:before {
  content: "\ec5c";
}
.icon-offline_pin:before {
  content: "\ec5d";
}
.icon-all_out:before {
  content: "\ec5e";
}
.icon-copyright1:before {
  content: "\ec5f";
}
.icon-fingerprint:before {
  content: "\ec60";
}
.icon-gavel1:before {
  content: "\ec61";
}
.icon-lightbulb_outline:before {
  content: "\ec62";
}
.icon-picture_in_picture_alt:before {
  content: "\ec63";
}
.icon-important_devices:before {
  content: "\ec64";
}
.icon-touch_app:before {
  content: "\ec65";
}
.icon-accessible:before {
  content: "\ec66";
}
.icon-compare_arrows:before {
  content: "\ec67";
}
.icon-date_range:before {
  content: "\ec68";
}
.icon-donut_large:before {
  content: "\ec69";
}
.icon-donut_small:before {
  content: "\ec6a";
}
.icon-line_style:before {
  content: "\ec6b";
}
.icon-line_weight:before {
  content: "\ec6c";
}
.icon-motorcycle1:before {
  content: "\ec6d";
}
.icon-opacity:before {
  content: "\ec6e";
}
.icon-pets:before {
  content: "\ec6f";
}
.icon-pregnant_woman:before {
  content: "\ec70";
}
.icon-record_voice_over:before {
  content: "\ec71";
}
.icon-rounded_corner:before {
  content: "\ec72";
}
.icon-rowing:before {
  content: "\ec73";
}
.icon-timeline:before {
  content: "\ec74";
}
.icon-update:before {
  content: "\ec75";
}
.icon-watch_later:before {
  content: "\ec76";
}
.icon-pan_tool:before {
  content: "\ec77";
}
.icon-euro_symbol:before {
  content: "\ec78";
}
.icon-g_translate:before {
  content: "\ec79";
}
.icon-remove_shopping_cart:before {
  content: "\ec7a";
}
.icon-restore_page:before {
  content: "\ec7b";
}
.icon-speaker_notes_off:before {
  content: "\ec7c";
}
.icon-delete_forever:before {
  content: "\ec7d";
}
.icon-accessibility_new:before {
  content: "\ec7e";
}
.icon-check_circle_outline:before {
  content: "\ec7f";
}
.icon-delete_outline:before {
  content: "\ec80";
}
.icon-done_outline:before {
  content: "\ec81";
}
.icon-maximize:before {
  content: "\ec82";
}
.icon-minimize:before {
  content: "\ec83";
}
.icon-offline_bolt:before {
  content: "\ec84";
}
.icon-swap_horizontal_circle:before {
  content: "\ec85";
}
.icon-accessible_forward:before {
  content: "\ec86";
}
.icon-calendar_today:before {
  content: "\ec87";
}
.icon-calendar_view_day:before {
  content: "\ec88";
}
.icon-label_important:before {
  content: "\ec89";
}
.icon-restore_from_trash:before {
  content: "\ec8a";
}
.icon-supervised_user_circle:before {
  content: "\ec8b";
}
.icon-text_rotate_up:before {
  content: "\ec8c";
}
.icon-text_rotate_vertical:before {
  content: "\ec8d";
}
.icon-text_rotation_angledown:before {
  content: "\ec8e";
}
.icon-text_rotation_angleup:before {
  content: "\ec8f";
}
.icon-text_rotation_down:before {
  content: "\ec90";
}
.icon-text_rotation_none:before {
  content: "\ec91";
}
.icon-commute:before {
  content: "\ec92";
}
.icon-arrow_right_alt:before {
  content: "\ec93";
}
.icon-work_off:before {
  content: "\ec94";
}
.icon-work_outline:before {
  content: "\ec95";
}
.icon-drag_indicator:before {
  content: "\ec96";
}
.icon-horizontal_split:before {
  content: "\ec97";
}
.icon-label_important_outline:before {
  content: "\ec98";
}
.icon-vertical_split:before {
  content: "\ec99";
}
.icon-voice_over_off:before {
  content: "\ec9a";
}
.icon-segment:before {
  content: "\ec9b";
}
.icon-contact_support:before {
  content: "\ec9c";
}
.icon-compress1:before {
  content: "\ec9d";
}
.icon-filter_list_alt:before {
  content: "\ec9e";
}
.icon-expand1:before {
  content: "\ec9f";
}
.icon-edit_off:before {
  content: "\eca0";
}
.icon-10k:before {
  content: "\eca1";
}
.icon-10mp:before {
  content: "\eca2";
}
.icon-11mp:before {
  content: "\eca3";
}
.icon-12mp:before {
  content: "\eca4";
}
.icon-13mp:before {
  content: "\eca5";
}
.icon-14mp:before {
  content: "\eca6";
}
.icon-15mp:before {
  content: "\eca7";
}
.icon-16mp:before {
  content: "\eca8";
}
.icon-17mp:before {
  content: "\eca9";
}
.icon-18mp:before {
  content: "\ecaa";
}
.icon-19mp:before {
  content: "\ecab";
}
.icon-1k:before {
  content: "\ecac";
}
.icon-1k_plus:before {
  content: "\ecad";
}
.icon-20mp:before {
  content: "\ecae";
}
.icon-21mp:before {
  content: "\ecaf";
}
.icon-22mp:before {
  content: "\ecb0";
}
.icon-23mp:before {
  content: "\ecb1";
}
.icon-24mp:before {
  content: "\ecb2";
}
.icon-2k:before {
  content: "\ecb3";
}
.icon-2k_plus:before {
  content: "\ecb4";
}
.icon-2mp:before {
  content: "\ecb5";
}
.icon-3k:before {
  content: "\ecb6";
}
.icon-3k_plus:before {
  content: "\ecb7";
}
.icon-3mp:before {
  content: "\ecb8";
}
.icon-4k_plus:before {
  content: "\ecb9";
}
.icon-4mp:before {
  content: "\ecba";
}
.icon-5k:before {
  content: "\ecbb";
}
.icon-5k_plus:before {
  content: "\ecbc";
}
.icon-5mp:before {
  content: "\ecbd";
}
.icon-6k:before {
  content: "\ecbe";
}
.icon-6k_plus:before {
  content: "\ecbf";
}
.icon-6mp:before {
  content: "\ecc0";
}
.icon-7k:before {
  content: "\ecc1";
}
.icon-7k_plus:before {
  content: "\ecc2";
}
.icon-7mp:before {
  content: "\ecc3";
}
.icon-8k:before {
  content: "\ecc4";
}
.icon-8k_plus:before {
  content: "\ecc5";
}
.icon-8mp:before {
  content: "\ecc6";
}
.icon-9k:before {
  content: "\ecc7";
}
.icon-9k_plus:before {
  content: "\ecc8";
}
.icon-9mp:before {
  content: "\ecc9";
}
.icon-account_tree:before {
  content: "\ecca";
}
.icon-add_chart:before {
  content: "\eccb";
}
.icon-add_ic_call:before {
  content: "\eccc";
}
.icon-add_moderator:before {
  content: "\eccd";
}
.icon-all_inbox:before {
  content: "\ecce";
}
.icon-approval:before {
  content: "\eccf";
}
.icon-assistant_direction:before {
  content: "\ecd0";
}
.icon-assistant_navigation:before {
  content: "\ecd1";
}
.icon-bookmarks1:before {
  content: "\ecd2";
}
.icon-bus_alert:before {
  content: "\ecd3";
}
.icon-cases:before {
  content: "\ecd4";
}
.icon-circle_notifications:before {
  content: "\ecd5";
}
.icon-closed_caption_off:before {
  content: "\ecd6";
}
.icon-connected_tv:before {
  content: "\ecd7";
}
.icon-dangerous:before {
  content: "\ecd8";
}
.icon-dashboard_customize:before {
  content: "\ecd9";
}
.icon-desktop_access_disabled:before {
  content: "\ecda";
}
.icon-drive_file_move_outline:before {
  content: "\ecdb";
}
.icon-drive_file_rename_outline:before {
  content: "\ecdc";
}
.icon-drive_folder_upload:before {
  content: "\ecdd";
}
.icon-duo:before {
  content: "\ecde";
}
.icon-explore_off:before {
  content: "\ecdf";
}
.icon-file_download_done:before {
  content: "\ece0";
}
.icon-rtt:before {
  content: "\ece1";
}
.icon-grid_view:before {
  content: "\ece2";
}
.icon-hail:before {
  content: "\ece3";
}
.icon-home_filled:before {
  content: "\ece4";
}
.icon-imagesearch_roller:before {
  content: "\ece5";
}
.icon-label_off:before {
  content: "\ece6";
}
.icon-library_add_check:before {
  content: "\ece7";
}
.icon-logout:before {
  content: "\ece8";
}
.icon-margin:before {
  content: "\ece9";
}
.icon-mark_as_unread:before {
  content: "\ecea";
}
.icon-menu_open:before {
  content: "\eceb";
}
.icon-mp:before {
  content: "\ecec";
}
.icon-offline_share:before {
  content: "\eced";
}
.icon-padding:before {
  content: "\ecee";
}
.icon-panorama_photosphere:before {
  content: "\ecef";
}
.icon-panorama_photosphere_select:before {
  content: "\ecf0";
}
.icon-person_add_disabled:before {
  content: "\ecf1";
}
.icon-phone_disabled:before {
  content: "\ecf2";
}
.icon-phone_enabled:before {
  content: "\ecf3";
}
.icon-pivot_table_chart:before {
  content: "\ecf4";
}
.icon-print_disabled:before {
  content: "\ecf5";
}
.icon-railway_alert:before {
  content: "\ecf6";
}
.icon-recommend:before {
  content: "\ecf7";
}
.icon-remove_done:before {
  content: "\ecf8";
}
.icon-remove_moderator:before {
  content: "\ecf9";
}
.icon-repeat_on:before {
  content: "\ecfa";
}
.icon-repeat_one_on:before {
  content: "\ecfb";
}
.icon-replay_circle_filled:before {
  content: "\ecfc";
}
.icon-reset_tv:before {
  content: "\ecfd";
}
.icon-sd:before {
  content: "\ecfe";
}
.icon-shield2:before {
  content: "\ecff";
}
.icon-shuffle_on:before {
  content: "\ed00";
}
.icon-speed:before {
  content: "\ed01";
}
.icon-stacked_bar_chart:before {
  content: "\ed02";
}
.icon-stream:before {
  content: "\ed03";
}
.icon-swipe:before {
  content: "\ed04";
}
.icon-switch_account:before {
  content: "\ed05";
}
.icon-tag1:before {
  content: "\ed06";
}
.icon-thumb_down_off_alt:before {
  content: "\ed07";
}
.icon-thumb_up_off_alt:before {
  content: "\ed08";
}
.icon-toggle_off:before {
  content: "\ed09";
}
.icon-toggle_on:before {
  content: "\ed0a";
}
.icon-two_wheeler:before {
  content: "\ed0b";
}
.icon-upload_file:before {
  content: "\ed0c";
}
.icon-view_in_ar:before {
  content: "\ed0d";
}
.icon-waterfall_chart:before {
  content: "\ed0e";
}
.icon-wb_shade:before {
  content: "\ed0f";
}
.icon-wb_twighlight:before {
  content: "\ed10";
}
.icon-home_work:before {
  content: "\ed11";
}
.icon-schedule_send:before {
  content: "\ed12";
}
.icon-bolt1:before {
  content: "\ed13";
}
.icon-send_and_archive:before {
  content: "\ed14";
}
.icon-workspaces_filled:before {
  content: "\ed15";
}
.icon-file_present:before {
  content: "\ed16";
}
.icon-workspaces_outline:before {
  content: "\ed17";
}
.icon-fit_screen:before {
  content: "\ed18";
}
.icon-saved_search:before {
  content: "\ed19";
}
.icon-storefront:before {
  content: "\ed1a";
}
.icon-amp_stories:before {
  content: "\ed1b";
}
.icon-dynamic_feed:before {
  content: "\ed1c";
}
.icon-euro1:before {
  content: "\ed1d";
}
.icon-height:before {
  content: "\ed1e";
}
.icon-policy:before {
  content: "\ed1f";
}
.icon-sync_alt:before {
  content: "\ed20";
}
.icon-menu_book:before {
  content: "\ed21";
}
.icon-emoji_flags:before {
  content: "\ed22";
}
.icon-emoji_food_beverage:before {
  content: "\ed23";
}
.icon-emoji_nature:before {
  content: "\ed24";
}
.icon-emoji_people:before {
  content: "\ed25";
}
.icon-emoji_symbols:before {
  content: "\ed26";
}
.icon-emoji_transportation:before {
  content: "\ed27";
}
.icon-post_add:before {
  content: "\ed28";
}
.icon-people_alt:before {
  content: "\ed29";
}
.icon-emoji_emotions:before {
  content: "\ed2a";
}
.icon-emoji_events:before {
  content: "\ed2b";
}
.icon-emoji_objects:before {
  content: "\ed2c";
}
.icon-sports_basketball:before {
  content: "\ed2d";
}
.icon-sports_cricket:before {
  content: "\ed2e";
}
.icon-sports_esports:before {
  content: "\ed2f";
}
.icon-sports_football:before {
  content: "\ed30";
}
.icon-sports_golf:before {
  content: "\ed31";
}
.icon-sports_hockey:before {
  content: "\ed32";
}
.icon-sports_mma:before {
  content: "\ed33";
}
.icon-sports_motorsports:before {
  content: "\ed34";
}
.icon-sports_rugby:before {
  content: "\ed35";
}
.icon-sports_soccer:before {
  content: "\ed36";
}
.icon-sports:before {
  content: "\ed37";
}
.icon-sports_volleyball:before {
  content: "\ed38";
}
.icon-sports_tennis:before {
  content: "\ed39";
}
.icon-sports_handball:before {
  content: "\ed3a";
}
.icon-sports_kabaddi:before {
  content: "\ed3b";
}
.icon-eco:before {
  content: "\ed3c";
}
.icon-museum:before {
  content: "\ed3d";
}
.icon-flip_camera_android:before {
  content: "\ed3e";
}
.icon-flip_camera_ios:before {
  content: "\ed3f";
}
.icon-cancel_schedule_send:before {
  content: "\ed40";
}
.icon-apartment:before {
  content: "\ed41";
}
.icon-bathtub1:before {
  content: "\ed42";
}
.icon-deck:before {
  content: "\ed43";
}
.icon-fireplace:before {
  content: "\ed44";
}
.icon-house:before {
  content: "\ed45";
}
.icon-king_bed:before {
  content: "\ed46";
}
.icon-nights_stay:before {
  content: "\ed47";
}
.icon-outdoor_grill:before {
  content: "\ed48";
}
.icon-single_bed:before {
  content: "\ed49";
}
.icon-square_foot:before {
  content: "\ed4a";
}
.icon-double_arrow:before {
  content: "\ed4b";
}
.icon-sports_baseball:before {
  content: "\ed4c";
}
.icon-attractions:before {
  content: "\ed4d";
}
.icon-bakery_dining:before {
  content: "\ed4e";
}
.icon-breakfast_dining:before {
  content: "\ed4f";
}
.icon-car_rental:before {
  content: "\ed50";
}
.icon-car_repair:before {
  content: "\ed51";
}
.icon-dinner_dining:before {
  content: "\ed52";
}
.icon-dry_cleaning:before {
  content: "\ed53";
}
.icon-hardware:before {
  content: "\ed54";
}
.icon-liquor:before {
  content: "\ed55";
}
.icon-lunch_dining:before {
  content: "\ed56";
}
.icon-nightlife:before {
  content: "\ed57";
}
.icon-park:before {
  content: "\ed58";
}
.icon-ramen_dining:before {
  content: "\ed59";
}
.icon-celebration:before {
  content: "\ed5a";
}
.icon-theater_comedy:before {
  content: "\ed5b";
}
.icon-badge:before {
  content: "\ed5c";
}
.icon-festival:before {
  content: "\ed5d";
}
.icon-icecream:before {
  content: "\ed5e";
}
.icon-volunteer_activism:before {
  content: "\ed5f";
}
.icon-contactless:before {
  content: "\ed60";
}
.icon-delivery_dining:before {
  content: "\ed61";
}
.icon-brunch_dining:before {
  content: "\ed62";
}
.icon-takeout_dining:before {
  content: "\ed63";
}
.icon-ac_unit:before {
  content: "\ed64";
}
.icon-airport_shuttle:before {
  content: "\ed65";
}
.icon-all_inclusive:before {
  content: "\ed66";
}
.icon-beach_access:before {
  content: "\ed67";
}
.icon-business_center:before {
  content: "\ed68";
}
.icon-casino:before {
  content: "\ed69";
}
.icon-child_care:before {
  content: "\ed6a";
}
.icon-child_friendly:before {
  content: "\ed6b";
}
.icon-fitness_center:before {
  content: "\ed6c";
}
.icon-golf_course:before {
  content: "\ed6d";
}
.icon-hot_tub:before {
  content: "\ed6e";
}
.icon-kitchen:before {
  content: "\ed6f";
}
.icon-pool:before {
  content: "\ed70";
}
.icon-room_service:before {
  content: "\ed71";
}
.icon-smoke_free:before {
  content: "\ed72";
}
.icon-smoking_rooms:before {
  content: "\ed73";
}
.icon-spa:before {
  content: "\ed74";
}
.icon-no_meeting_room:before {
  content: "\ed75";
}
.icon-meeting_room:before {
  content: "\ed76";
}
.icon-goat:before {
  content: "\ed77";
}
.icon-activity:before {
  content: "\ed78";
}
.icon-airplay1:before {
  content: "\ed79";
}
.icon-alert-circle:before {
  content: "\ed7a";
}
.icon-alert-octagon:before {
  content: "\ed7b";
}
.icon-alert-triangle:before {
  content: "\ed7c";
}
.icon-align-center1:before {
  content: "\ed7d";
}
.icon-align-justify1:before {
  content: "\ed7e";
}
.icon-align-left1:before {
  content: "\ed7f";
}
.icon-align-right1:before {
  content: "\ed80";
}
.icon-anchor1:before {
  content: "\ed81";
}
.icon-aperture:before {
  content: "\ed82";
}
.icon-archive2:before {
  content: "\ed83";
}
.icon-arrow-down-circle:before {
  content: "\ed84";
}
.icon-arrow-down-left1:before {
  content: "\ed85";
}
.icon-arrow-down-right1:before {
  content: "\ed86";
}
.icon-arrow-down3:before {
  content: "\ed87";
}
.icon-arrow-left-circle:before {
  content: "\ed88";
}
.icon-arrow-left3:before {
  content: "\ed89";
}
.icon-arrow-right-circle:before {
  content: "\ed8a";
}
.icon-arrow-right3:before {
  content: "\ed8b";
}
.icon-arrow-up-circle:before {
  content: "\ed8c";
}
.icon-arrow-up-left1:before {
  content: "\ed8d";
}
.icon-arrow-up-right1:before {
  content: "\ed8e";
}
.icon-arrow-up3:before {
  content: "\ed8f";
}
.icon-at-sign:before {
  content: "\ed90";
}
.icon-award:before {
  content: "\ed91";
}
.icon-bar-chart-2:before {
  content: "\ed92";
}
.icon-bar-chart1:before {
  content: "\ed93";
}
.icon-battery-charging:before {
  content: "\ed94";
}
.icon-battery1:before {
  content: "\ed95";
}
.icon-bell-off:before {
  content: "\ed96";
}
.icon-bell2:before {
  content: "\ed97";
}
.icon-bluetooth2:before {
  content: "\ed98";
}
.icon-bold2:before {
  content: "\ed99";
}
.icon-book-open:before {
  content: "\ed9a";
}
.icon-book3:before {
  content: "\ed9b";
}
.icon-bookmark3:before {
  content: "\ed9c";
}
.icon-box:before {
  content: "\ed9d";
}
.icon-briefcase2:before {
  content: "\ed9e";
}
.icon-calendar2:before {
  content: "\ed9f";
}
.icon-camera-off:before {
  content: "\eda0";
}
.icon-camera3:before {
  content: "\eda1";
}
.icon-cast1:before {
  content: "\eda2";
}
.icon-check-circle1:before {
  content: "\eda3";
}
.icon-check-square1:before {
  content: "\eda4";
}
.icon-check2:before {
  content: "\eda5";
}
.icon-chevron-down1:before {
  content: "\eda6";
}
.icon-chevron-left1:before {
  content: "\eda7";
}
.icon-chevron-right1:before {
  content: "\eda8";
}
.icon-chevron-up1:before {
  content: "\eda9";
}
.icon-chevrons-down:before {
  content: "\edaa";
}
.icon-chevrons-left:before {
  content: "\edab";
}
.icon-chevrons-right:before {
  content: "\edac";
}
.icon-chevrons-up:before {
  content: "\edad";
}
.icon-chrome2:before {
  content: "\edae";
}
.icon-circle1:before {
  content: "\edaf";
}
.icon-clipboard2:before {
  content: "\edb0";
}
.icon-clock1:before {
  content: "\edb1";
}
.icon-cloud-drizzle:before {
  content: "\edb2";
}
.icon-cloud-lightning:before {
  content: "\edb3";
}
.icon-cloud-off:before {
  content: "\edb4";
}
.icon-cloud-rain:before {
  content: "\edb5";
}
.icon-cloud-snow:before {
  content: "\edb6";
}
.icon-cloud3:before {
  content: "\edb7";
}
.icon-code2:before {
  content: "\edb8";
}
.icon-codepen2:before {
  content: "\edb9";
}
.icon-codesandbox:before {
  content: "\edba";
}
.icon-coffee1:before {
  content: "\edbb";
}
.icon-columns1:before {
  content: "\edbc";
}
.icon-command1:before {
  content: "\edbd";
}
.icon-compass3:before {
  content: "\edbe";
}
.icon-copy2:before {
  content: "\edbf";
}
.icon-corner-down-left:before {
  content: "\edc0";
}
.icon-corner-down-right:before {
  content: "\edc1";
}
.icon-corner-left-down:before {
  content: "\edc2";
}
.icon-corner-left-up:before {
  content: "\edc3";
}
.icon-corner-right-down:before {
  content: "\edc4";
}
.icon-corner-right-up:before {
  content: "\edc5";
}
.icon-corner-up-left:before {
  content: "\edc6";
}
.icon-corner-up-right:before {
  content: "\edc7";
}
.icon-cpu:before {
  content: "\edc8";
}
.icon-credit-card2:before {
  content: "\edc9";
}
.icon-crop3:before {
  content: "\edca";
}
.icon-crosshair:before {
  content: "\edcb";
}
.icon-database2:before {
  content: "\edcc";
}
.icon-delete1:before {
  content: "\edcd";
}
.icon-disc:before {
  content: "\edce";
}
.icon-dollar-sign:before {
  content: "\edcf";
}
.icon-download-cloud:before {
  content: "\edd0";
}
.icon-download4:before {
  content: "\edd1";
}
.icon-droplet1:before {
  content: "\edd2";
}
.icon-edit-2:before {
  content: "\edd3";
}
.icon-edit-3:before {
  content: "\edd4";
}
.icon-edit2:before {
  content: "\edd5";
}
.icon-external-link1:before {
  content: "\edd6";
}
.icon-eye-off:before {
  content: "\edd7";
}
.icon-eye2:before {
  content: "\edd8";
}
.icon-facebook3:before {
  content: "\edd9";
}
.icon-fast-forward1:before {
  content: "\edda";
}
.icon-feather:before {
  content: "\eddb";
}
.icon-figma:before {
  content: "\eddc";
}
.icon-file-minus:before {
  content: "\eddd";
}
.icon-file-plus:before {
  content: "\edde";
}
.icon-file-text3:before {
  content: "\eddf";
}
.icon-file1:before {
  content: "\ede0";
}
.icon-film2:before {
  content: "\ede1";
}
.icon-filter3:before {
  content: "\ede2";
}
.icon-flag3:before {
  content: "\ede3";
}
.icon-folder-minus1:before {
  content: "\ede4";
}
.icon-folder-plus1:before {
  content: "\ede5";
}
.icon-folder3:before {
  content: "\ede6";
}
.icon-framer:before {
  content: "\ede7";
}
.icon-frown:before {
  content: "\ede8";
}
.icon-gift2:before {
  content: "\ede9";
}
.icon-git-branch:before {
  content: "\edea";
}
.icon-git-commit:before {
  content: "\edeb";
}
.icon-git-merge:before {
  content: "\edec";
}
.icon-git-pull-request:before {
  content: "\eded";
}
.icon-github2:before {
  content: "\edee";
}
.icon-gitlab1:before {
  content: "\edef";
}
.icon-globe1:before {
  content: "\edf0";
}
.icon-grid:before {
  content: "\edf1";
}
.icon-hard-drive:before {
  content: "\edf2";
}
.icon-hash:before {
  content: "\edf3";
}
.icon-headphones2:before {
  content: "\edf4";
}
.icon-heart2:before {
  content: "\edf5";
}
.icon-help-circle:before {
  content: "\edf6";
}
.icon-hexagon:before {
  content: "\edf7";
}
.icon-home5:before {
  content: "\edf8";
}
.icon-image3:before {
  content: "\edf9";
}
.icon-inbox2:before {
  content: "\edfa";
}
.icon-info3:before {
  content: "\edfb";
}
.icon-instagram2:before {
  content: "\edfc";
}
.icon-italic2:before {
  content: "\edfd";
}
.icon-key3:before {
  content: "\edfe";
}
.icon-layers1:before {
  content: "\edff";
}
.icon-layout:before {
  content: "\ee00";
}
.icon-life-buoy1:before {
  content: "\ee01";
}
.icon-link-2:before {
  content: "\ee02";
}
.icon-link3:before {
  content: "\ee03";
}
.icon-linkedin3:before {
  content: "\ee04";
}
.icon-list4:before {
  content: "\ee05";
}
.icon-loader:before {
  content: "\ee06";
}
.icon-lock3:before {
  content: "\ee07";
}
.icon-log-in:before {
  content: "\ee08";
}
.icon-log-out:before {
  content: "\ee09";
}
.icon-mail5:before {
  content: "\ee0a";
}
.icon-map-pin1:before {
  content: "\ee0b";
}
.icon-map4:before {
  content: "\ee0c";
}
.icon-maximize-2:before {
  content: "\ee0d";
}
.icon-maximize1:before {
  content: "\ee0e";
}
.icon-meh:before {
  content: "\ee0f";
}
.icon-menu5:before {
  content: "\ee10";
}
.icon-message-circle:before {
  content: "\ee11";
}
.icon-message-square:before {
  content: "\ee12";
}
.icon-mic-off:before {
  content: "\ee13";
}
.icon-mic2:before {
  content: "\ee14";
}
.icon-minimize-2:before {
  content: "\ee15";
}
.icon-minimize1:before {
  content: "\ee16";
}
.icon-minus-circle1:before {
  content: "\ee17";
}
.icon-minus-square1:before {
  content: "\ee18";
}
.icon-minus2:before {
  content: "\ee19";
}
.icon-monitor:before {
  content: "\ee1a";
}
.icon-moon:before {
  content: "\ee1b";
}
.icon-more-horizontal:before {
  content: "\ee1c";
}
.icon-more-vertical:before {
  content: "\ee1d";
}
.icon-mouse-pointer1:before {
  content: "\ee1e";
}
.icon-move:before {
  content: "\ee1f";
}
.icon-music2:before {
  content: "\ee20";
}
.icon-navigation-2:before {
  content: "\ee21";
}
.icon-navigation1:before {
  content: "\ee22";
}
.icon-octagon:before {
  content: "\ee23";
}
.icon-package:before {
  content: "\ee24";
}
.icon-paperclip1:before {
  content: "\ee25";
}
.icon-pause-circle1:before {
  content: "\ee26";
}
.icon-pause4:before {
  content: "\ee27";
}
.icon-pen-tool:before {
  content: "\ee28";
}
.icon-percent1:before {
  content: "\ee29";
}
.icon-phone-call:before {
  content: "\ee2a";
}
.icon-phone-forwarded:before {
  content: "\ee2b";
}
.icon-phone-incoming:before {
  content: "\ee2c";
}
.icon-phone-missed:before {
  content: "\ee2d";
}
.icon-phone-off:before {
  content: "\ee2e";
}
.icon-phone-outgoing:before {
  content: "\ee2f";
}
.icon-phone3:before {
  content: "\ee30";
}
.icon-pie-chart2:before {
  content: "\ee31";
}
.icon-play-circle1:before {
  content: "\ee32";
}
.icon-play4:before {
  content: "\ee33";
}
.icon-plus-circle1:before {
  content: "\ee34";
}
.icon-plus-square1:before {
  content: "\ee35";
}
.icon-plus2:before {
  content: "\ee36";
}
.icon-pocket:before {
  content: "\ee37";
}
.icon-power2:before {
  content: "\ee38";
}
.icon-printer1:before {
  content: "\ee39";
}
.icon-radio1:before {
  content: "\ee3a";
}
.icon-refresh-ccw:before {
  content: "\ee3b";
}
.icon-refresh-cw:before {
  content: "\ee3c";
}
.icon-repeat2:before {
  content: "\ee3d";
}
.icon-rewind:before {
  content: "\ee3e";
}
.icon-rotate-ccw:before {
  content: "\ee3f";
}
.icon-rotate-cw:before {
  content: "\ee40";
}
.icon-rss3:before {
  content: "\ee41";
}
.icon-save2:before {
  content: "\ee42";
}
.icon-scissors2:before {
  content: "\ee43";
}
.icon-search3:before {
  content: "\ee44";
}
.icon-send2:before {
  content: "\ee45";
}
.icon-server1:before {
  content: "\ee46";
}
.icon-settings1:before {
  content: "\ee47";
}
.icon-share-2:before {
  content: "\ee48";
}
.icon-share4:before {
  content: "\ee49";
}
.icon-shield-off:before {
  content: "\ee4a";
}
.icon-shield3:before {
  content: "\ee4b";
}
.icon-shopping-bag1:before {
  content: "\ee4c";
}
.icon-shopping-cart1:before {
  content: "\ee4d";
}
.icon-shuffle2:before {
  content: "\ee4e";
}
.icon-sidebar:before {
  content: "\ee4f";
}
.icon-skip-back:before {
  content: "\ee50";
}
.icon-skip-forward:before {
  content: "\ee51";
}
.icon-slack1:before {
  content: "\ee52";
}
.icon-slash:before {
  content: "\ee53";
}
.icon-sliders1:before {
  content: "\ee54";
}
.icon-smartphone1:before {
  content: "\ee55";
}
.icon-smile1:before {
  content: "\ee56";
}
.icon-speaker1:before {
  content: "\ee57";
}
.icon-square1:before {
  content: "\ee58";
}
.icon-star2:before {
  content: "\ee59";
}
.icon-stop-circle1:before {
  content: "\ee5a";
}
.icon-sun1:before {
  content: "\ee5b";
}
.icon-sunrise:before {
  content: "\ee5c";
}
.icon-sunset:before {
  content: "\ee5d";
}
.icon-tablet3:before {
  content: "\ee5e";
}
.icon-tag2:before {
  content: "\ee5f";
}
.icon-target1:before {
  content: "\ee60";
}
.icon-terminal2:before {
  content: "\ee61";
}
.icon-thermometer1:before {
  content: "\ee62";
}
.icon-thumbs-down1:before {
  content: "\ee63";
}
.icon-thumbs-up1:before {
  content: "\ee64";
}
.icon-toggle-left1:before {
  content: "\ee65";
}
.icon-toggle-right1:before {
  content: "\ee66";
}
.icon-trash-2:before {
  content: "\ee67";
}
.icon-trash1:before {
  content: "\ee68";
}
.icon-trello2:before {
  content: "\ee69";
}
.icon-trending-down:before {
  content: "\ee6a";
}
.icon-trending-up:before {
  content: "\ee6b";
}
.icon-triangle:before {
  content: "\ee6c";
}
.icon-truck2:before {
  content: "\ee6d";
}
.icon-tv3:before {
  content: "\ee6e";
}
.icon-twitter2:before {
  content: "\ee6f";
}
.icon-type:before {
  content: "\ee70";
}
.icon-umbrella1:before {
  content: "\ee71";
}
.icon-underline2:before {
  content: "\ee72";
}
.icon-unlock1:before {
  content: "\ee73";
}
.icon-upload-cloud:before {
  content: "\ee74";
}
.icon-upload4:before {
  content: "\ee75";
}
.icon-user-check1:before {
  content: "\ee76";
}
.icon-user-minus1:before {
  content: "\ee77";
}
.icon-user-plus2:before {
  content: "\ee78";
}
.icon-user-x:before {
  content: "\ee79";
}
.icon-user2:before {
  content: "\ee7a";
}
.icon-users2:before {
  content: "\ee7b";
}
.icon-video-off:before {
  content: "\ee7c";
}
.icon-video:before {
  content: "\ee7d";
}
.icon-voicemail1:before {
  content: "\ee7e";
}
.icon-volume-1:before {
  content: "\ee7f";
}
.icon-volume-2:before {
  content: "\ee80";
}
.icon-volume-x:before {
  content: "\ee81";
}
.icon-volume:before {
  content: "\ee82";
}
.icon-watch1:before {
  content: "\ee83";
}
.icon-wifi-off:before {
  content: "\ee84";
}
.icon-wifi2:before {
  content: "\ee85";
}
.icon-wind:before {
  content: "\ee86";
}
.icon-x-circle:before {
  content: "\ee87";
}
.icon-x-octagon:before {
  content: "\ee88";
}
.icon-x-square:before {
  content: "\ee89";
}
.icon-x:before {
  content: "\ee8a";
}
.icon-youtube3:before {
  content: "\ee8b";
}
.icon-zap-off:before {
  content: "\ee8c";
}
.icon-zap:before {
  content: "\ee8d";
}
.icon-zoom-in1:before {
  content: "\ee8e";
}
.icon-zoom-out1:before {
  content: "\ee8f";
}
.icon-cart:before {
  content: "\ee90";
}
.icon-shopping:before {
  content: "\ee90";
}
.icon-ecommerce:before {
  content: "\ee90";
}
.icon-buy:before {
  content: "\ee90";
}
.icon-purchase:before {
  content: "\ee90";
}
.icon-home:before {
  content: "\ee91";
}
.icon-home2:before {
  content: "\ee92";
}
.icon-home3:before {
  content: "\ee93";
}
.icon-office:before {
  content: "\ee94";
}
.icon-newspaper:before {
  content: "\ee95";
}
.icon-pencil:before {
  content: "\ee96";
}
.icon-pencil2:before {
  content: "\ee97";
}
.icon-quill:before {
  content: "\ee98";
}
.icon-pen:before {
  content: "\ee99";
}
.icon-blog:before {
  content: "\ee9a";
}
.icon-eyedropper:before {
  content: "\ee9b";
}
.icon-droplet:before {
  content: "\ee9c";
}
.icon-paint-format:before {
  content: "\ee9d";
}
.icon-image:before {
  content: "\ee9e";
}
.icon-images:before {
  content: "\ee9f";
}
.icon-camera:before {
  content: "\eea0";
}
.icon-headphones:before {
  content: "\eea1";
}
.icon-music:before {
  content: "\eea2";
}
.icon-play:before {
  content: "\eea3";
}
.icon-film:before {
  content: "\eea4";
}
.icon-video-camera:before {
  content: "\eea5";
}
.icon-dice:before {
  content: "\eea6";
}
.icon-pacman:before {
  content: "\eea7";
}
.icon-spades:before {
  content: "\eea8";
}
.icon-clubs:before {
  content: "\eea9";
}
.icon-diamonds:before {
  content: "\eeaa";
}
.icon-bullhorn:before {
  content: "\eeab";
}
.icon-connection:before {
  content: "\eeac";
}
.icon-podcast:before {
  content: "\eead";
}
.icon-feed:before {
  content: "\eeae";
}
.icon-mic:before {
  content: "\eeaf";
}
.icon-book:before {
  content: "\eeb0";
}
.icon-books:before {
  content: "\eeb1";
}
.icon-library:before {
  content: "\eeb2";
}
.icon-file-text:before {
  content: "\eeb3";
}
.icon-profile:before {
  content: "\eeb4";
}
.icon-file-empty:before {
  content: "\eeb5";
}
.icon-files-empty:before {
  content: "\eeb6";
}
.icon-file-text2:before {
  content: "\eeb7";
}
.icon-file-picture:before {
  content: "\eeb8";
}
.icon-file-music:before {
  content: "\eeb9";
}
.icon-file-play:before {
  content: "\eeba";
}
.icon-file-video:before {
  content: "\eebb";
}
.icon-file-zip:before {
  content: "\eebc";
}
.icon-copy:before {
  content: "\eebd";
}
.icon-paste:before {
  content: "\eebe";
}
.icon-stack:before {
  content: "\eebf";
}
.icon-folder:before {
  content: "\eec0";
}
.icon-folder-open:before {
  content: "\eec1";
}
.icon-folder-plus:before {
  content: "\eec2";
}
.icon-folder-minus:before {
  content: "\eec3";
}
.icon-folder-download:before {
  content: "\eec4";
}
.icon-folder-upload:before {
  content: "\eec5";
}
.icon-price-tag:before {
  content: "\eec6";
}
.icon-price-tags:before {
  content: "\eec7";
}
.icon-barcode:before {
  content: "\eec8";
}
.icon-qrcode:before {
  content: "\eec9";
}
.icon-ticket:before {
  content: "\eeca";
}
.icon-cart1:before {
  content: "\eecb";
}
.icon-coin-dollar:before {
  content: "\eecc";
}
.icon-coin-euro:before {
  content: "\eecd";
}
.icon-coin-pound:before {
  content: "\eece";
}
.icon-coin-yen:before {
  content: "\eecf";
}
.icon-credit-card:before {
  content: "\eed0";
}
.icon-calculator:before {
  content: "\eed1";
}
.icon-lifebuoy:before {
  content: "\eed2";
}
.icon-phone:before {
  content: "\eed3";
}
.icon-phone-hang-up:before {
  content: "\eed4";
}
.icon-address-book:before {
  content: "\eed5";
}
.icon-envelop:before {
  content: "\eed6";
}
.icon-pushpin:before {
  content: "\eed7";
}
.icon-location:before {
  content: "\eed8";
}
.icon-location2:before {
  content: "\eed9";
}
.icon-compass:before {
  content: "\eeda";
}
.icon-compass2:before {
  content: "\eedb";
}
.icon-map:before {
  content: "\eedc";
}
.icon-map2:before {
  content: "\eedd";
}
.icon-history:before {
  content: "\eede";
}
.icon-clock:before {
  content: "\eedf";
}
.icon-clock2:before {
  content: "\eee0";
}
.icon-alarm:before {
  content: "\eee1";
}
.icon-bell:before {
  content: "\eee2";
}
.icon-stopwatch:before {
  content: "\eee3";
}
.icon-calendar:before {
  content: "\eee4";
}
.icon-printer:before {
  content: "\eee5";
}
.icon-keyboard:before {
  content: "\eee6";
}
.icon-display:before {
  content: "\eee7";
}
.icon-laptop:before {
  content: "\eee8";
}
.icon-mobile:before {
  content: "\eee9";
}
.icon-mobile2:before {
  content: "\eeea";
}
.icon-tablet:before {
  content: "\eeeb";
}
.icon-tv:before {
  content: "\eeec";
}
.icon-drawer:before {
  content: "\eeed";
}
.icon-drawer2:before {
  content: "\eeee";
}
.icon-box-add:before {
  content: "\eeef";
}
.icon-box-remove:before {
  content: "\eef0";
}
.icon-download:before {
  content: "\eef1";
}
.icon-upload:before {
  content: "\eef2";
}
.icon-floppy-disk:before {
  content: "\eef3";
}
.icon-drive:before {
  content: "\eef4";
}
.icon-database:before {
  content: "\eef5";
}
.icon-undo:before {
  content: "\eef6";
}
.icon-redo:before {
  content: "\eef7";
}
.icon-undo2:before {
  content: "\eef8";
}
.icon-redo2:before {
  content: "\eef9";
}
.icon-forward:before {
  content: "\eefa";
}
.icon-reply:before {
  content: "\eefb";
}
.icon-bubble:before {
  content: "\eefc";
}
.icon-bubbles:before {
  content: "\eefd";
}
.icon-bubbles2:before {
  content: "\eefe";
}
.icon-bubble2:before {
  content: "\eeff";
}
.icon-bubbles3:before {
  content: "\ef00";
}
.icon-bubbles4:before {
  content: "\ef01";
}
.icon-user:before {
  content: "\ef02";
}
.icon-users:before {
  content: "\ef03";
}
.icon-user-plus:before {
  content: "\ef04";
}
.icon-user-minus:before {
  content: "\ef05";
}
.icon-user-check:before {
  content: "\ef06";
}
.icon-user-tie:before {
  content: "\ef07";
}
.icon-quotes-left:before {
  content: "\ef08";
}
.icon-quotes-right:before {
  content: "\ef09";
}
.icon-hour-glass:before {
  content: "\ef0a";
}
.icon-spinner:before {
  content: "\ef0b";
}
.icon-spinner2:before {
  content: "\ef0c";
}
.icon-spinner3:before {
  content: "\ef0d";
}
.icon-spinner4:before {
  content: "\ef0e";
}
.icon-spinner5:before {
  content: "\ef0f";
}
.icon-spinner6:before {
  content: "\ef10";
}
.icon-spinner7:before {
  content: "\ef11";
}
.icon-spinner8:before {
  content: "\ef12";
}
.icon-spinner9:before {
  content: "\ef13";
}
.icon-spinner10:before {
  content: "\ef14";
}
.icon-spinner11:before {
  content: "\ef15";
}
.icon-binoculars:before {
  content: "\ef16";
}
.icon-search:before {
  content: "\ef17";
}
.icon-zoom-in:before {
  content: "\ef18";
}
.icon-zoom-out:before {
  content: "\ef19";
}
.icon-enlarge:before {
  content: "\ef1a";
}
.icon-shrink:before {
  content: "\ef1b";
}
.icon-enlarge2:before {
  content: "\ef1c";
}
.icon-shrink2:before {
  content: "\ef1d";
}
.icon-key:before {
  content: "\ef1e";
}
.icon-key2:before {
  content: "\ef1f";
}
.icon-lock:before {
  content: "\ef20";
}
.icon-unlocked:before {
  content: "\ef21";
}
.icon-wrench:before {
  content: "\ef22";
}
.icon-equalizer:before {
  content: "\ef23";
}
.icon-equalizer2:before {
  content: "\ef24";
}
.icon-cog:before {
  content: "\ef25";
}
.icon-cogs:before {
  content: "\ef26";
}
.icon-hammer:before {
  content: "\ef27";
}
.icon-magic-wand:before {
  content: "\ef28";
}
.icon-aid-kit:before {
  content: "\ef29";
}
.icon-bug:before {
  content: "\ef2a";
}
.icon-pie-chart:before {
  content: "\ef2b";
}
.icon-stats-dots:before {
  content: "\ef2c";
}
.icon-stats-bars:before {
  content: "\ef2d";
}
.icon-stats-bars2:before {
  content: "\ef2e";
}
.icon-trophy:before {
  content: "\ef2f";
}
.icon-gift:before {
  content: "\ef30";
}
.icon-glass:before {
  content: "\ef31";
}
.icon-glass2:before {
  content: "\ef32";
}
.icon-mug:before {
  content: "\ef33";
}
.icon-spoon-knife:before {
  content: "\ef34";
}
.icon-leaf:before {
  content: "\ef35";
}
.icon-rocket:before {
  content: "\ef36";
}
.icon-meter:before {
  content: "\ef37";
}
.icon-meter2:before {
  content: "\ef38";
}
.icon-hammer2:before {
  content: "\ef39";
}
.icon-fire:before {
  content: "\ef3a";
}
.icon-lab:before {
  content: "\ef3b";
}
.icon-magnet:before {
  content: "\ef3c";
}
.icon-bin:before {
  content: "\ef3d";
}
.icon-bin2:before {
  content: "\ef3e";
}
.icon-briefcase:before {
  content: "\ef3f";
}
.icon-airplane:before {
  content: "\ef40";
}
.icon-truck:before {
  content: "\ef41";
}
.icon-road:before {
  content: "\ef42";
}
.icon-accessibility:before {
  content: "\ef43";
}
.icon-target:before {
  content: "\ef44";
}
.icon-shield:before {
  content: "\ef45";
}
.icon-power:before {
  content: "\ef46";
}
.icon-switch:before {
  content: "\ef47";
}
.icon-power-cord:before {
  content: "\ef48";
}
.icon-clipboard:before {
  content: "\ef49";
}
.icon-list-numbered:before {
  content: "\ef4a";
}
.icon-list:before {
  content: "\ef4b";
}
.icon-list2:before {
  content: "\ef4c";
}
.icon-tree:before {
  content: "\ef4d";
}
.icon-menu:before {
  content: "\ef4e";
}
.icon-menu2:before {
  content: "\ef4f";
}
.icon-menu3:before {
  content: "\ef50";
}
.icon-menu4:before {
  content: "\ef51";
}
.icon-cloud:before {
  content: "\ef52";
}
.icon-cloud-download:before {
  content: "\ef53";
}
.icon-cloud-upload:before {
  content: "\ef54";
}
.icon-cloud-check:before {
  content: "\ef55";
}
.icon-download2:before {
  content: "\ef56";
}
.icon-upload2:before {
  content: "\ef57";
}
.icon-download3:before {
  content: "\ef58";
}
.icon-upload3:before {
  content: "\ef59";
}
.icon-sphere:before {
  content: "\ef5a";
}
.icon-earth:before {
  content: "\ef5b";
}
.icon-link:before {
  content: "\ef5c";
}
.icon-flag:before {
  content: "\ef5d";
}
.icon-attachment:before {
  content: "\ef5e";
}
.icon-eye:before {
  content: "\ef5f";
}
.icon-eye-plus:before {
  content: "\ef60";
}
.icon-eye-minus:before {
  content: "\ef61";
}
.icon-eye-blocked:before {
  content: "\ef62";
}
.icon-bookmark:before {
  content: "\ef63";
}
.icon-bookmarks:before {
  content: "\ef64";
}
.icon-sun:before {
  content: "\ef65";
}
.icon-contrast:before {
  content: "\ef66";
}
.icon-brightness-contrast:before {
  content: "\ef67";
}
.icon-star-empty:before {
  content: "\ef68";
}
.icon-star-half:before {
  content: "\ef69";
}
.icon-star-full:before {
  content: "\ef6a";
}
.icon-heart:before {
  content: "\ef6b";
}
.icon-heart-broken:before {
  content: "\ef6c";
}
.icon-man:before {
  content: "\ef6d";
}
.icon-woman:before {
  content: "\ef6e";
}
.icon-man-woman:before {
  content: "\ef6f";
}
.icon-happy:before {
  content: "\ef70";
}
.icon-happy2:before {
  content: "\ef71";
}
.icon-smile:before {
  content: "\ef72";
}
.icon-smile2:before {
  content: "\ef73";
}
.icon-tongue:before {
  content: "\ef74";
}
.icon-tongue2:before {
  content: "\ef75";
}
.icon-sad:before {
  content: "\ef76";
}
.icon-sad2:before {
  content: "\ef77";
}
.icon-wink:before {
  content: "\ef78";
}
.icon-wink2:before {
  content: "\ef79";
}
.icon-grin:before {
  content: "\ef7a";
}
.icon-grin2:before {
  content: "\ef7b";
}
.icon-cool:before {
  content: "\ef7c";
}
.icon-cool2:before {
  content: "\ef7d";
}
.icon-angry:before {
  content: "\ef7e";
}
.icon-angry2:before {
  content: "\ef7f";
}
.icon-evil:before {
  content: "\ef80";
}
.icon-evil2:before {
  content: "\ef81";
}
.icon-shocked:before {
  content: "\ef82";
}
.icon-shocked2:before {
  content: "\ef83";
}
.icon-baffled:before {
  content: "\ef84";
}
.icon-baffled2:before {
  content: "\ef85";
}
.icon-confused:before {
  content: "\ef86";
}
.icon-confused2:before {
  content: "\ef87";
}
.icon-neutral:before {
  content: "\ef88";
}
.icon-neutral2:before {
  content: "\ef89";
}
.icon-hipster:before {
  content: "\ef8a";
}
.icon-hipster2:before {
  content: "\ef8b";
}
.icon-wondering:before {
  content: "\ef8c";
}
.icon-wondering2:before {
  content: "\ef8d";
}
.icon-sleepy:before {
  content: "\ef8e";
}
.icon-sleepy2:before {
  content: "\ef8f";
}
.icon-frustrated:before {
  content: "\ef90";
}
.icon-frustrated2:before {
  content: "\ef91";
}
.icon-crying:before {
  content: "\ef92";
}
.icon-crying2:before {
  content: "\ef93";
}
.icon-point-up:before {
  content: "\ef94";
}
.icon-point-right:before {
  content: "\ef95";
}
.icon-point-down:before {
  content: "\ef96";
}
.icon-point-left:before {
  content: "\ef97";
}
.icon-warning:before {
  content: "\ef98";
}
.icon-notification:before {
  content: "\ef99";
}
.icon-question:before {
  content: "\ef9a";
}
.icon-plus:before {
  content: "\ef9b";
}
.icon-minus:before {
  content: "\ef9c";
}
.icon-info:before {
  content: "\ef9d";
}
.icon-cancel-circle:before {
  content: "\ef9e";
}
.icon-blocked:before {
  content: "\ef9f";
}
.icon-cross:before {
  content: "\efa0";
}
.icon-checkmark:before {
  content: "\efa1";
}
.icon-checkmark2:before {
  content: "\efa2";
}
.icon-spell-check:before {
  content: "\efa3";
}
.icon-enter:before {
  content: "\efa4";
}
.icon-exit:before {
  content: "\efa5";
}
.icon-play2:before {
  content: "\efa6";
}
.icon-pause:before {
  content: "\efa7";
}
.icon-stop:before {
  content: "\efa8";
}
.icon-previous:before {
  content: "\efa9";
}
.icon-next:before {
  content: "\efaa";
}
.icon-backward:before {
  content: "\efab";
}
.icon-forward2:before {
  content: "\efac";
}
.icon-play3:before {
  content: "\efad";
}
.icon-pause2:before {
  content: "\efae";
}
.icon-stop2:before {
  content: "\efaf";
}
.icon-backward2:before {
  content: "\efb0";
}
.icon-forward3:before {
  content: "\efb1";
}
.icon-first:before {
  content: "\efb2";
}
.icon-last:before {
  content: "\efb3";
}
.icon-previous2:before {
  content: "\efb4";
}
.icon-next2:before {
  content: "\efb5";
}
.icon-eject:before {
  content: "\efb6";
}
.icon-volume-high:before {
  content: "\efb7";
}
.icon-volume-medium:before {
  content: "\efb8";
}
.icon-volume-low:before {
  content: "\efb9";
}
.icon-volume-mute:before {
  content: "\efba";
}
.icon-volume-mute2:before {
  content: "\efbb";
}
.icon-volume-increase:before {
  content: "\efbc";
}
.icon-volume-decrease:before {
  content: "\efbd";
}
.icon-loop:before {
  content: "\efbe";
}
.icon-loop2:before {
  content: "\efbf";
}
.icon-infinite:before {
  content: "\efc0";
}
.icon-shuffle:before {
  content: "\efc1";
}
.icon-arrow-up-left:before {
  content: "\efc2";
}
.icon-arrow-up:before {
  content: "\efc3";
}
.icon-arrow-up-right:before {
  content: "\efc4";
}
.icon-arrow-right:before {
  content: "\efc5";
}
.icon-arrow-down-right:before {
  content: "\efc6";
}
.icon-arrow-down:before {
  content: "\efc7";
}
.icon-arrow-down-left:before {
  content: "\efc8";
}
.icon-arrow-left:before {
  content: "\efc9";
}
.icon-arrow-up-left2:before {
  content: "\efca";
}
.icon-arrow-up2:before {
  content: "\efcb";
}
.icon-arrow-up-right2:before {
  content: "\efcc";
}
.icon-arrow-right2:before {
  content: "\efcd";
}
.icon-arrow-down-right2:before {
  content: "\efce";
}
.icon-arrow-down2:before {
  content: "\efcf";
}
.icon-arrow-down-left2:before {
  content: "\efd0";
}
.icon-arrow-left2:before {
  content: "\efd1";
}
.icon-circle-up:before {
  content: "\efd2";
}
.icon-circle-right:before {
  content: "\efd3";
}
.icon-circle-down:before {
  content: "\efd4";
}
.icon-circle-left:before {
  content: "\efd5";
}
.icon-tab:before {
  content: "\efd6";
}
.icon-move-up:before {
  content: "\efd7";
}
.icon-move-down:before {
  content: "\efd8";
}
.icon-sort-alpha-asc:before {
  content: "\efd9";
}
.icon-sort-alpha-desc:before {
  content: "\efda";
}
.icon-sort-numeric-asc:before {
  content: "\efdb";
}
.icon-sort-numberic-desc:before {
  content: "\efdc";
}
.icon-sort-amount-asc:before {
  content: "\efdd";
}
.icon-sort-amount-desc:before {
  content: "\efde";
}
.icon-command:before {
  content: "\efdf";
}
.icon-shift:before {
  content: "\efe0";
}
.icon-ctrl:before {
  content: "\efe1";
}
.icon-opt:before {
  content: "\efe2";
}
.icon-checkbox-checked:before {
  content: "\efe3";
}
.icon-checkbox-unchecked:before {
  content: "\efe4";
}
.icon-radio-checked:before {
  content: "\efe5";
}
.icon-radio-checked2:before {
  content: "\efe6";
}
.icon-radio-unchecked:before {
  content: "\efe7";
}
.icon-crop:before {
  content: "\efe8";
}
.icon-make-group:before {
  content: "\efe9";
}
.icon-ungroup:before {
  content: "\efea";
}
.icon-scissors:before {
  content: "\efeb";
}
.icon-filter:before {
  content: "\efec";
}
.icon-font:before {
  content: "\efed";
}
.icon-ligature:before {
  content: "\efee";
}
.icon-ligature2:before {
  content: "\efef";
}
.icon-text-height:before {
  content: "\eff0";
}
.icon-text-width:before {
  content: "\eff1";
}
.icon-font-size:before {
  content: "\eff2";
}
.icon-bold:before {
  content: "\eff3";
}
.icon-underline:before {
  content: "\eff4";
}
.icon-italic:before {
  content: "\eff5";
}
.icon-strikethrough:before {
  content: "\eff6";
}
.icon-omega:before {
  content: "\eff7";
}
.icon-sigma:before {
  content: "\eff8";
}
.icon-page-break:before {
  content: "\eff9";
}
.icon-superscript:before {
  content: "\effa";
}
.icon-subscript:before {
  content: "\effb";
}
.icon-superscript2:before {
  content: "\effc";
}
.icon-subscript2:before {
  content: "\effd";
}
.icon-text-color:before {
  content: "\effe";
}
.icon-pagebreak:before {
  content: "\efff";
}
.icon-clear-formatting:before {
  content: "\f000";
}
.icon-table:before {
  content: "\f001";
}
.icon-table2:before {
  content: "\f002";
}
.icon-insert-template:before {
  content: "\f003";
}
.icon-pilcrow:before {
  content: "\f004";
}
.icon-ltr:before {
  content: "\f005";
}
.icon-rtl:before {
  content: "\f006";
}
.icon-section:before {
  content: "\f007";
}
.icon-paragraph-left:before {
  content: "\f008";
}
.icon-paragraph-center:before {
  content: "\f009";
}
.icon-paragraph-right:before {
  content: "\f00a";
}
.icon-paragraph-justify:before {
  content: "\f00b";
}
.icon-indent-increase:before {
  content: "\f00c";
}
.icon-indent-decrease:before {
  content: "\f00d";
}
.icon-share:before {
  content: "\f00e";
}
.icon-new-tab:before {
  content: "\f00f";
}
.icon-embed:before {
  content: "\f010";
}
.icon-embed2:before {
  content: "\f011";
}
.icon-terminal:before {
  content: "\f012";
}
.icon-share2:before {
  content: "\f013";
}
.icon-mail:before {
  content: "\f014";
}
.icon-mail2:before {
  content: "\f015";
}
.icon-mail3:before {
  content: "\f016";
}
.icon-mail4:before {
  content: "\f017";
}
.icon-amazon:before {
  content: "\f018";
}
.icon-google:before {
  content: "\f019";
}
.icon-google2:before {
  content: "\f01a";
}
.icon-google3:before {
  content: "\f01b";
}
.icon-google-plus:before {
  content: "\f01c";
}
.icon-google-plus2:before {
  content: "\f01d";
}
.icon-google-plus3:before {
  content: "\f01e";
}
.icon-hangouts:before {
  content: "\f01f";
}
.icon-google-drive:before {
  content: "\f020";
}
.icon-facebook:before {
  content: "\f021";
}
.icon-facebook2:before {
  content: "\f022";
}
.icon-instagram:before {
  content: "\f023";
}
.icon-whatsapp:before {
  content: "\f024";
}
.icon-spotify:before {
  content: "\f025";
}
.icon-telegram:before {
  content: "\f026";
}
.icon-twitter:before {
  content: "\f027";
}
.icon-vine:before {
  content: "\f028";
}
.icon-vk:before {
  content: "\f029";
}
.icon-renren:before {
  content: "\f02a";
}
.icon-sina-weibo:before {
  content: "\f02b";
}
.icon-rss:before {
  content: "\f02c";
}
.icon-rss2:before {
  content: "\f02d";
}
.icon-youtube:before {
  content: "\f02e";
}
.icon-youtube2:before {
  content: "\f02f";
}
.icon-twitch:before {
  content: "\f030";
}
.icon-vimeo:before {
  content: "\f031";
}
.icon-vimeo2:before {
  content: "\f032";
}
.icon-lanyrd:before {
  content: "\f033";
}
.icon-flickr:before {
  content: "\f034";
}
.icon-flickr2:before {
  content: "\f035";
}
.icon-flickr3:before {
  content: "\f036";
}
.icon-flickr4:before {
  content: "\f037";
}
.icon-dribbble:before {
  content: "\f038";
}
.icon-behance:before {
  content: "\f039";
}
.icon-behance2:before {
  content: "\f03a";
}
.icon-deviantart:before {
  content: "\f03b";
}
.icon-500px:before {
  content: "\f03c";
}
.icon-steam:before {
  content: "\f03d";
}
.icon-steam2:before {
  content: "\f03e";
}
.icon-dropbox:before {
  content: "\f03f";
}
.icon-onedrive:before {
  content: "\f040";
}
.icon-github:before {
  content: "\f041";
}
.icon-npm:before {
  content: "\f042";
}
.icon-basecamp:before {
  content: "\f043";
}
.icon-trello:before {
  content: "\f044";
}
.icon-wordpress:before {
  content: "\f045";
}
.icon-joomla:before {
  content: "\f046";
}
.icon-ello:before {
  content: "\f047";
}
.icon-blogger:before {
  content: "\f048";
}
.icon-blogger2:before {
  content: "\f049";
}
.icon-tumblr:before {
  content: "\f04a";
}
.icon-tumblr2:before {
  content: "\f04b";
}
.icon-yahoo:before {
  content: "\f04c";
}
.icon-yahoo2:before {
  content: "\f04d";
}
.icon-tux:before {
  content: "\f04e";
}
.icon-appleinc:before {
  content: "\f04f";
}
.icon-finder:before {
  content: "\f050";
}
.icon-android:before {
  content: "\f051";
}
.icon-windows:before {
  content: "\f052";
}
.icon-windows8:before {
  content: "\f053";
}
.icon-soundcloud:before {
  content: "\f054";
}
.icon-soundcloud2:before {
  content: "\f055";
}
.icon-skype:before {
  content: "\f056";
}
.icon-reddit:before {
  content: "\f057";
}
.icon-hackernews:before {
  content: "\f058";
}
.icon-wikipedia:before {
  content: "\f059";
}
.icon-linkedin:before {
  content: "\f05a";
}
.icon-linkedin2:before {
  content: "\f05b";
}
.icon-lastfm:before {
  content: "\f05c";
}
.icon-lastfm2:before {
  content: "\f05d";
}
.icon-delicious:before {
  content: "\f05e";
}
.icon-stumbleupon:before {
  content: "\f05f";
}
.icon-stumbleupon2:before {
  content: "\f060";
}
.icon-stackoverflow:before {
  content: "\f061";
}
.icon-pinterest:before {
  content: "\f062";
}
.icon-pinterest2:before {
  content: "\f063";
}
.icon-xing:before {
  content: "\f064";
}
.icon-xing2:before {
  content: "\f065";
}
.icon-flattr:before {
  content: "\f066";
}
.icon-foursquare:before {
  content: "\f067";
}
.icon-yelp:before {
  content: "\f068";
}
.icon-paypal:before {
  content: "\f069";
}
.icon-chrome:before {
  content: "\f06a";
}
.icon-firefox:before {
  content: "\f06b";
}
.icon-IE:before {
  content: "\f06c";
}
.icon-edge:before {
  content: "\f06d";
}
.icon-safari:before {
  content: "\f06e";
}
.icon-opera:before {
  content: "\f06f";
}
.icon-file-pdf:before {
  content: "\f070";
}
.icon-file-openoffice:before {
  content: "\f071";
}
.icon-file-word:before {
  content: "\f072";
}
.icon-file-excel:before {
  content: "\f073";
}
.icon-libreoffice:before {
  content: "\f074";
}
.icon-html-five:before {
  content: "\f075";
}
.icon-html-five2:before {
  content: "\f076";
}
.icon-css3:before {
  content: "\f077";
}
.icon-git:before {
  content: "\f078";
}
.icon-codepen:before {
  content: "\f079";
}
.icon-svg:before {
  content: "\f07a";
}
.icon-IcoMoon:before {
  content: "\f07b";
}
.icon-asterisk:before {
  content: "\f07c";
}
.icon-plus1:before {
  content: "\f07d";
}
.icon-question1:before {
  content: "\f128";
}
.icon-minus1:before {
  content: "\f07e";
}
.icon-glass1:before {
  content: "\f07f";
}
.icon-music1:before {
  content: "\f080";
}
.icon-search1:before {
  content: "\f081";
}
.icon-envelope-o:before {
  content: "\f082";
}
.icon-heart1:before {
  content: "\f083";
}
.icon-star:before {
  content: "\f084";
}
.icon-star-o:before {
  content: "\f085";
}
.icon-user1:before {
  content: "\f086";
}
.icon-film1:before {
  content: "\f087";
}
.icon-th-large:before {
  content: "\f088";
}
.icon-th:before {
  content: "\f089";
}
.icon-th-list:before {
  content: "\f08a";
}
.icon-check:before {
  content: "\f08b";
}
.icon-close:before {
  content: "\f08c";
}
.icon-remove:before {
  content: "\f08c";
}
.icon-times:before {
  content: "\f08c";
}
.icon-search-plus:before {
  content: "\f08d";
}
.icon-search-minus:before {
  content: "\f08e";
}
.icon-power-off:before {
  content: "\f08f";
}
.icon-signal:before {
  content: "\f090";
}
.icon-cog1:before {
  content: "\f091";
}
.icon-gear:before {
  content: "\f091";
}
.icon-trash-o:before {
  content: "\f092";
}
.icon-home1:before {
  content: "\f093";
}
.icon-file-o:before {
  content: "\f094";
}
.icon-clock-o:before {
  content: "\f095";
}
.icon-road1:before {
  content: "\f096";
}
.icon-download1:before {
  content: "\f097";
}
.icon-arrow-circle-o-down:before {
  content: "\f098";
}
.icon-arrow-circle-o-up:before {
  content: "\f099";
}
.icon-inbox:before {
  content: "\f09a";
}
.icon-play-circle-o:before {
  content: "\f09b";
}
.icon-repeat:before {
  content: "\f09c";
}
.icon-rotate-right:before {
  content: "\f09c";
}
.icon-refresh:before {
  content: "\f09d";
}
.icon-list-alt:before {
  content: "\f09e";
}
.icon-lock1:before {
  content: "\f09f";
}
.icon-flag1:before {
  content: "\f0a0";
}
.icon-headphones1:before {
  content: "\f0a1";
}
.icon-volume-off:before {
  content: "\f0a2";
}
.icon-volume-down:before {
  content: "\f0a3";
}
.icon-volume-up:before {
  content: "\f0a4";
}
.icon-qrcode1:before {
  content: "\f0a5";
}
.icon-barcode1:before {
  content: "\f0a6";
}
.icon-tag:before {
  content: "\f0a7";
}
.icon-tags:before {
  content: "\f0a8";
}
.icon-book1:before {
  content: "\f0a9";
}
.icon-bookmark1:before {
  content: "\f0aa";
}
.icon-print:before {
  content: "\f0ab";
}
.icon-camera1:before {
  content: "\f0ac";
}
.icon-font1:before {
  content: "\f0ad";
}
.icon-bold1:before {
  content: "\f0ae";
}
.icon-italic1:before {
  content: "\f0af";
}
.icon-text-height1:before {
  content: "\f0b0";
}
.icon-text-width1:before {
  content: "\f0b1";
}
.icon-align-left:before {
  content: "\f0b2";
}
.icon-align-center:before {
  content: "\f0b3";
}
.icon-align-right:before {
  content: "\f0b4";
}
.icon-align-justify:before {
  content: "\f0b5";
}
.icon-list1:before {
  content: "\f0b6";
}
.icon-dedent:before {
  content: "\f0b7";
}
.icon-outdent:before {
  content: "\f0b7";
}
.icon-indent:before {
  content: "\f0b8";
}
.icon-video-camera1:before {
  content: "\f0b9";
}
.icon-image1:before {
  content: "\f0ba";
}
.icon-photo:before {
  content: "\f0ba";
}
.icon-picture-o:before {
  content: "\f0ba";
}
.icon-pencil1:before {
  content: "\f0bb";
}
.icon-map-marker:before {
  content: "\f0bc";
}
.icon-adjust:before {
  content: "\f0bd";
}
.icon-tint:before {
  content: "\f0be";
}
.icon-edit:before {
  content: "\f0bf";
}
.icon-pencil-square-o:before {
  content: "\f0bf";
}
.icon-share-square-o:before {
  content: "\f0c0";
}
.icon-check-square-o:before {
  content: "\f0c1";
}
.icon-arrows:before {
  content: "\f0c2";
}
.icon-step-backward:before {
  content: "\f0c3";
}
.icon-fast-backward:before {
  content: "\f0c4";
}
.icon-backward1:before {
  content: "\f0c5";
}
.icon-play1:before {
  content: "\f0c6";
}
.icon-pause1:before {
  content: "\f0c7";
}
.icon-stop1:before {
  content: "\f0c8";
}
.icon-forward1:before {
  content: "\f0c9";
}
.icon-fast-forward:before {
  content: "\f0ca";
}
.icon-step-forward:before {
  content: "\f0cb";
}
.icon-eject1:before {
  content: "\f0cc";
}
.icon-chevron-left:before {
  content: "\f0cd";
}
.icon-chevron-right:before {
  content: "\f0ce";
}
.icon-plus-circle:before {
  content: "\f0cf";
}
.icon-minus-circle:before {
  content: "\f0d0";
}
.icon-times-circle:before {
  content: "\f0d1";
}
.icon-check-circle:before {
  content: "\f0d2";
}
.icon-question-circle:before {
  content: "\f0d3";
}
.icon-info-circle:before {
  content: "\f0d4";
}
.icon-crosshairs:before {
  content: "\f0d5";
}
.icon-times-circle-o:before {
  content: "\f0d6";
}
.icon-check-circle-o:before {
  content: "\f0d7";
}
.icon-ban:before {
  content: "\f0d8";
}
.icon-arrow-left1:before {
  content: "\f0d9";
}
.icon-arrow-right1:before {
  content: "\f0da";
}
.icon-arrow-up1:before {
  content: "\f0db";
}
.icon-arrow-down1:before {
  content: "\f0dc";
}
.icon-mail-forward:before {
  content: "\f0dd";
}
.icon-share1:before {
  content: "\f0dd";
}
.icon-expand:before {
  content: "\f0de";
}
.icon-compress:before {
  content: "\f0df";
}
.icon-exclamation-circle:before {
  content: "\f0e0";
}
.icon-gift1:before {
  content: "\f0e1";
}
.icon-leaf1:before {
  content: "\f0e2";
}
.icon-fire1:before {
  content: "\f0e3";
}
.icon-eye1:before {
  content: "\f0e4";
}
.icon-eye-slash:before {
  content: "\f0e5";
}
.icon-exclamation-triangle:before {
  content: "\f0e6";
}
.icon-warning1:before {
  content: "\f0e6";
}
.icon-plane:before {
  content: "\f0e7";
}
.icon-calendar1:before {
  content: "\f0e8";
}
.icon-random:before {
  content: "\f0e9";
}
.icon-comment:before {
  content: "\f0ea";
}
.icon-magnet1:before {
  content: "\f0eb";
}
.icon-chevron-up:before {
  content: "\f0ec";
}
.icon-chevron-down:before {
  content: "\f0ed";
}
.icon-retweet:before {
  content: "\f0ee";
}
.icon-shopping-cart:before {
  content: "\f0ef";
}
.icon-folder1:before {
  content: "\f0f0";
}
.icon-folder-open1:before {
  content: "\f0f1";
}
.icon-arrows-v:before {
  content: "\f0f2";
}
.icon-arrows-h:before {
  content: "\f0f3";
}
.icon-bar-chart:before {
  content: "\f0f4";
}
.icon-bar-chart-o:before {
  content: "\f0f4";
}
.icon-twitter-square:before {
  content: "\f0f5";
}
.icon-facebook-square:before {
  content: "\f0f6";
}
.icon-camera-retro:before {
  content: "\f0f7";
}
.icon-key1:before {
  content: "\f0f8";
}
.icon-cogs1:before {
  content: "\f0f9";
}
.icon-gears:before {
  content: "\f0f9";
}
.icon-comments:before {
  content: "\f0fa";
}
.icon-thumbs-o-up:before {
  content: "\f0fb";
}
.icon-thumbs-o-down:before {
  content: "\f0fc";
}
.icon-star-half1:before {
  content: "\f0fd";
}
.icon-heart-o:before {
  content: "\f0fe";
}
.icon-sign-out:before {
  content: "\f0ff";
}
.icon-linkedin-square:before {
  content: "\f100";
}
.icon-thumb-tack:before {
  content: "\f101";
}
.icon-external-link:before {
  content: "\f102";
}
.icon-sign-in:before {
  content: "\f103";
}
.icon-trophy1:before {
  content: "\f104";
}
.icon-github-square:before {
  content: "\f105";
}
.icon-upload1:before {
  content: "\f106";
}
.icon-lemon-o:before {
  content: "\f107";
}
.icon-phone1:before {
  content: "\f108";
}
.icon-square-o:before {
  content: "\f109";
}
.icon-bookmark-o:before {
  content: "\f10a";
}
.icon-phone-square:before {
  content: "\f10b";
}
.icon-twitter1:before {
  content: "\f10c";
}
.icon-facebook1:before {
  content: "\f10d";
}
.icon-facebook-f:before {
  content: "\f10d";
}
.icon-github1:before {
  content: "\f10e";
}
.icon-unlock:before {
  content: "\f10f";
}
.icon-credit-card1:before {
  content: "\f110";
}
.icon-feed1:before {
  content: "\f111";
}
.icon-rss1:before {
  content: "\f111";
}
.icon-hdd-o:before {
  content: "\f112";
}
.icon-bullhorn1:before {
  content: "\f113";
}
.icon-bell-o:before {
  content: "\f114";
}
.icon-certificate:before {
  content: "\f115";
}
.icon-hand-o-right:before {
  content: "\f116";
}
.icon-hand-o-left:before {
  content: "\f117";
}
.icon-hand-o-up:before {
  content: "\f118";
}
.icon-hand-o-down:before {
  content: "\f119";
}
.icon-arrow-circle-left:before {
  content: "\f11a";
}
.icon-arrow-circle-right:before {
  content: "\f11b";
}
.icon-arrow-circle-up:before {
  content: "\f11c";
}
.icon-arrow-circle-down:before {
  content: "\f11d";
}
.icon-globe:before {
  content: "\f11e";
}
.icon-wrench1:before {
  content: "\f11f";
}
.icon-tasks:before {
  content: "\f120";
}
.icon-filter1:before {
  content: "\f121";
}
.icon-briefcase1:before {
  content: "\f122";
}
.icon-arrows-alt:before {
  content: "\f123";
}
.icon-group:before {
  content: "\f124";
}
.icon-users1:before {
  content: "\f124";
}
.icon-chain:before {
  content: "\f125";
}
.icon-link1:before {
  content: "\f125";
}
.icon-cloud1:before {
  content: "\f126";
}
.icon-flask:before {
  content: "\f127";
}
.icon-cut:before {
  content: "\f129";
}
.icon-scissors1:before {
  content: "\f129";
}
.icon-copy1:before {
  content: "\f12a";
}
.icon-files-o:before {
  content: "\f12a";
}
.icon-paperclip:before {
  content: "\f12b";
}
.icon-floppy-o:before {
  content: "\f12c";
}
.icon-save:before {
  content: "\f12c";
}
.icon-square:before {
  content: "\f12d";
}
.icon-bars:before {
  content: "\f12e";
}
.icon-navicon:before {
  content: "\f12e";
}
.icon-reorder:before {
  content: "\f12e";
}
.icon-list-ul:before {
  content: "\f12f";
}
.icon-list-ol:before {
  content: "\f130";
}
.icon-strikethrough1:before {
  content: "\f131";
}
.icon-underline1:before {
  content: "\f132";
}
.icon-table1:before {
  content: "\f133";
}
.icon-magic:before {
  content: "\f134";
}
.icon-truck1:before {
  content: "\f135";
}
.icon-pinterest1:before {
  content: "\f136";
}
.icon-pinterest-square:before {
  content: "\f137";
}
.icon-google-plus-square:before {
  content: "\f138";
}
.icon-google-plus1:before {
  content: "\f139";
}
.icon-money:before {
  content: "\f13a";
}
.icon-caret-down:before {
  content: "\f13b";
}
.icon-caret-up:before {
  content: "\f13c";
}
.icon-caret-left:before {
  content: "\f13d";
}
.icon-caret-right:before {
  content: "\f13e";
}
.icon-columns:before {
  content: "\f13f";
}
.icon-sort:before {
  content: "\f140";
}
.icon-unsorted:before {
  content: "\f140";
}
.icon-sort-desc:before {
  content: "\f141";
}
.icon-sort-down:before {
  content: "\f141";
}
.icon-sort-asc:before {
  content: "\f142";
}
.icon-sort-up:before {
  content: "\f142";
}
.icon-envelope:before {
  content: "\f143";
}
.icon-linkedin1:before {
  content: "\f144";
}
.icon-rotate-left:before {
  content: "\f145";
}
.icon-undo1:before {
  content: "\f145";
}
.icon-gavel:before {
  content: "\f146";
}
.icon-legal:before {
  content: "\f146";
}
.icon-dashboard:before {
  content: "\f147";
}
.icon-tachometer:before {
  content: "\f147";
}
.icon-comment-o:before {
  content: "\f148";
}
.icon-comments-o:before {
  content: "\f149";
}
.icon-bolt:before {
  content: "\f14a";
}
.icon-flash:before {
  content: "\f14a";
}
.icon-sitemap:before {
  content: "\f14b";
}
.icon-umbrella:before {
  content: "\f14c";
}
.icon-clipboard1:before {
  content: "\f14d";
}
.icon-paste1:before {
  content: "\f14d";
}
.icon-lightbulb-o:before {
  content: "\f14e";
}
.icon-exchange:before {
  content: "\f14f";
}
.icon-cloud-download1:before {
  content: "\f150";
}
.icon-cloud-upload1:before {
  content: "\f151";
}
.icon-user-md:before {
  content: "\f152";
}
.icon-stethoscope:before {
  content: "\f153";
}
.icon-suitcase:before {
  content: "\f154";
}
.icon-bell1:before {
  content: "\f155";
}
.icon-coffee:before {
  content: "\f156";
}
.icon-cutlery:before {
  content: "\f157";
}
.icon-file-text-o:before {
  content: "\f158";
}
.icon-building-o:before {
  content: "\f159";
}
.icon-hospital-o:before {
  content: "\f15a";
}
.icon-ambulance:before {
  content: "\f15b";
}
.icon-medkit:before {
  content: "\f15c";
}
.icon-fighter-jet:before {
  content: "\f15d";
}
.icon-beer:before {
  content: "\f15e";
}
.icon-h-square:before {
  content: "\f15f";
}
.icon-plus-square:before {
  content: "\f160";
}
.icon-angle-double-left:before {
  content: "\f161";
}
.icon-angle-double-right:before {
  content: "\f162";
}
.icon-angle-double-up:before {
  content: "\f163";
}
.icon-angle-double-down:before {
  content: "\f164";
}
.icon-angle-left:before {
  content: "\f165";
}
.icon-angle-right:before {
  content: "\f166";
}
.icon-angle-up:before {
  content: "\f167";
}
.icon-angle-down:before {
  content: "\f168";
}
.icon-desktop:before {
  content: "\f169";
}
.icon-laptop1:before {
  content: "\f16a";
}
.icon-tablet1:before {
  content: "\f16b";
}
.icon-mobile1:before {
  content: "\f16c";
}
.icon-mobile-phone:before {
  content: "\f16c";
}
.icon-circle-o:before {
  content: "\f16d";
}
.icon-quote-left:before {
  content: "\f16e";
}
.icon-quote-right:before {
  content: "\f16f";
}
.icon-spinner1:before {
  content: "\f170";
}
.icon-circle:before {
  content: "\f171";
}
.icon-mail-reply:before {
  content: "\f172";
}
.icon-reply1:before {
  content: "\f172";
}
.icon-github-alt:before {
  content: "\f173";
}
.icon-folder-o:before {
  content: "\f174";
}
.icon-folder-open-o:before {
  content: "\f175";
}
.icon-smile-o:before {
  content: "\f176";
}
.icon-frown-o:before {
  content: "\f177";
}
.icon-meh-o:before {
  content: "\f178";
}
.icon-gamepad:before {
  content: "\f179";
}
.icon-keyboard-o:before {
  content: "\f17a";
}
.icon-flag-o:before {
  content: "\f17b";
}
.icon-flag-checkered:before {
  content: "\f17c";
}
.icon-terminal1:before {
  content: "\f17d";
}
.icon-code:before {
  content: "\f17e";
}
.icon-mail-reply-all:before {
  content: "\f17f";
}
.icon-reply-all:before {
  content: "\f17f";
}
.icon-star-half-empty:before {
  content: "\f180";
}
.icon-star-half-full:before {
  content: "\f180";
}
.icon-star-half-o:before {
  content: "\f180";
}
.icon-location-arrow:before {
  content: "\f181";
}
.icon-crop1:before {
  content: "\f182";
}
.icon-code-fork:before {
  content: "\f183";
}
.icon-chain-broken:before {
  content: "\f184";
}
.icon-unlink:before {
  content: "\f184";
}
.icon-info1:before {
  content: "\f185";
}
.icon-exclamation:before {
  content: "\f186";
}
.icon-superscript1:before {
  content: "\f187";
}
.icon-subscript1:before {
  content: "\f188";
}
.icon-eraser:before {
  content: "\f189";
}
.icon-puzzle-piece:before {
  content: "\f18a";
}
.icon-microphone:before {
  content: "\f18b";
}
.icon-microphone-slash:before {
  content: "\f18c";
}
.icon-shield1:before {
  content: "\f18d";
}
.icon-calendar-o:before {
  content: "\f18e";
}
.icon-fire-extinguisher:before {
  content: "\f18f";
}
.icon-rocket1:before {
  content: "\f190";
}
.icon-maxcdn:before {
  content: "\f191";
}
.icon-chevron-circle-left:before {
  content: "\f192";
}
.icon-chevron-circle-right:before {
  content: "\f193";
}
.icon-chevron-circle-up:before {
  content: "\f194";
}
.icon-chevron-circle-down:before {
  content: "\f195";
}
.icon-html5:before {
  content: "\f196";
}
.icon-css31:before {
  content: "\f197";
}
.icon-anchor:before {
  content: "\f198";
}
.icon-unlock-alt:before {
  content: "\f199";
}
.icon-bullseye:before {
  content: "\f19a";
}
.icon-ellipsis-h:before {
  content: "\f19b";
}
.icon-ellipsis-v:before {
  content: "\f19c";
}
.icon-rss-square:before {
  content: "\f19d";
}
.icon-play-circle:before {
  content: "\f19e";
}
.icon-ticket1:before {
  content: "\f19f";
}
.icon-minus-square:before {
  content: "\f1a0";
}
.icon-minus-square-o:before {
  content: "\f1a1";
}
.icon-level-up:before {
  content: "\f1a2";
}
.icon-level-down:before {
  content: "\f1a3";
}
.icon-check-square:before {
  content: "\f1a4";
}
.icon-pencil-square:before {
  content: "\f1a5";
}
.icon-external-link-square:before {
  content: "\f1a6";
}
.icon-share-square:before {
  content: "\f1a7";
}
.icon-compass1:before {
  content: "\f1a8";
}
.icon-caret-square-o-down:before {
  content: "\f1a9";
}
.icon-toggle-down:before {
  content: "\f1a9";
}
.icon-caret-square-o-up:before {
  content: "\f1aa";
}
.icon-toggle-up:before {
  content: "\f1aa";
}
.icon-caret-square-o-right:before {
  content: "\f1ab";
}
.icon-toggle-right:before {
  content: "\f1ab";
}
.icon-eur:before {
  content: "\f1ac";
}
.icon-euro:before {
  content: "\f1ac";
}
.icon-gbp:before {
  content: "\f1ad";
}
.icon-dollar:before {
  content: "\f1ae";
}
.icon-usd:before {
  content: "\f1ae";
}
.icon-inr:before {
  content: "\f1af";
}
.icon-rupee:before {
  content: "\f1af";
}
.icon-cny:before {
  content: "\f1b0";
}
.icon-jpy:before {
  content: "\f1b0";
}
.icon-rmb:before {
  content: "\f1b0";
}
.icon-yen:before {
  content: "\f1b0";
}
.icon-rouble:before {
  content: "\f1b1";
}
.icon-rub:before {
  content: "\f1b1";
}
.icon-ruble:before {
  content: "\f1b1";
}
.icon-krw:before {
  content: "\f1b2";
}
.icon-won:before {
  content: "\f1b2";
}
.icon-bitcoin:before {
  content: "\f1b3";
}
.icon-btc:before {
  content: "\f1b3";
}
.icon-file:before {
  content: "\f1b4";
}
.icon-file-text1:before {
  content: "\f1b5";
}
.icon-sort-alpha-asc1:before {
  content: "\f1b6";
}
.icon-sort-alpha-desc1:before {
  content: "\f1b7";
}
.icon-sort-amount-asc1:before {
  content: "\f1b8";
}
.icon-sort-amount-desc1:before {
  content: "\f1b9";
}
.icon-sort-numeric-asc1:before {
  content: "\f1ba";
}
.icon-sort-numeric-desc:before {
  content: "\f1bb";
}
.icon-thumbs-up:before {
  content: "\f1bc";
}
.icon-thumbs-down:before {
  content: "\f1bd";
}
.icon-youtube-square:before {
  content: "\f1be";
}
.icon-youtube1:before {
  content: "\f1bf";
}
.icon-xing1:before {
  content: "\f1c0";
}
.icon-xing-square:before {
  content: "\f1c1";
}
.icon-youtube-play:before {
  content: "\f1c2";
}
.icon-dropbox1:before {
  content: "\f1c3";
}
.icon-stack-overflow:before {
  content: "\f1c4";
}
.icon-instagram1:before {
  content: "\f1c5";
}
.icon-flickr1:before {
  content: "\f1c6";
}
.icon-adn:before {
  content: "\f1c7";
}
.icon-bitbucket:before {
  content: "\f1c8";
}
.icon-bitbucket-square:before {
  content: "\f1c9";
}
.icon-tumblr1:before {
  content: "\f1ca";
}
.icon-tumblr-square:before {
  content: "\f1cb";
}
.icon-long-arrow-down:before {
  content: "\f1cc";
}
.icon-long-arrow-up:before {
  content: "\f1cd";
}
.icon-long-arrow-left:before {
  content: "\f1ce";
}
.icon-long-arrow-right:before {
  content: "\f1cf";
}
.icon-apple:before {
  content: "\f1d0";
}
.icon-windows1:before {
  content: "\f1d1";
}
.icon-android1:before {
  content: "\f1d2";
}
.icon-linux:before {
  content: "\f1d3";
}
.icon-dribbble1:before {
  content: "\f1d4";
}
.icon-skype1:before {
  content: "\f1d5";
}
.icon-foursquare1:before {
  content: "\f1d6";
}
.icon-trello1:before {
  content: "\f1d7";
}
.icon-female:before {
  content: "\f1d8";
}
.icon-male:before {
  content: "\f1d9";
}
.icon-gittip:before {
  content: "\f1da";
}
.icon-gratipay:before {
  content: "\f1da";
}
.icon-sun-o:before {
  content: "\f1db";
}
.icon-moon-o:before {
  content: "\f1dc";
}
.icon-archive:before {
  content: "\f1dd";
}
.icon-bug1:before {
  content: "\f1de";
}
.icon-vk1:before {
  content: "\f1df";
}
.icon-weibo:before {
  content: "\f1e0";
}
.icon-renren1:before {
  content: "\f1e1";
}
.icon-pagelines:before {
  content: "\f1e2";
}
.icon-stack-exchange:before {
  content: "\f1e3";
}
.icon-arrow-circle-o-right:before {
  content: "\f1e4";
}
.icon-arrow-circle-o-left:before {
  content: "\f1e5";
}
.icon-caret-square-o-left:before {
  content: "\f1e6";
}
.icon-toggle-left:before {
  content: "\f1e6";
}
.icon-dot-circle-o:before {
  content: "\f1e7";
}
.icon-wheelchair:before {
  content: "\f1e8";
}
.icon-vimeo-square:before {
  content: "\f1e9";
}
.icon-try:before {
  content: "\f1ea";
}
.icon-turkish-lira:before {
  content: "\f1ea";
}
.icon-plus-square-o:before {
  content: "\f1eb";
}
.icon-space-shuttle:before {
  content: "\f1ec";
}
.icon-slack:before {
  content: "\f1ed";
}
.icon-envelope-square:before {
  content: "\f1ee";
}
.icon-wordpress1:before {
  content: "\f1ef";
}
.icon-openid:before {
  content: "\f1f0";
}
.icon-bank:before {
  content: "\f1f1";
}
.icon-institution:before {
  content: "\f1f1";
}
.icon-university:before {
  content: "\f1f1";
}
.icon-graduation-cap:before {
  content: "\f1f2";
}
.icon-mortar-board:before {
  content: "\f1f2";
}
.icon-yahoo1:before {
  content: "\f1f3";
}
.icon-google1:before {
  content: "\f1f4";
}
.icon-reddit1:before {
  content: "\f1f5";
}
.icon-reddit-square:before {
  content: "\f1f6";
}
.icon-stumbleupon-circle:before {
  content: "\f1f7";
}
.icon-stumbleupon1:before {
  content: "\f1f8";
}
.icon-delicious1:before {
  content: "\f1f9";
}
.icon-digg:before {
  content: "\f1fa";
}
.icon-pied-piper-pp:before {
  content: "\f1fb";
}
.icon-pied-piper-alt:before {
  content: "\f1fc";
}
.icon-drupal:before {
  content: "\f1fd";
}
.icon-joomla1:before {
  content: "\f1fe";
}
.icon-language:before {
  content: "\f1ff";
}
.icon-fax:before {
  content: "\f200";
}
.icon-building:before {
  content: "\f201";
}
.icon-child:before {
  content: "\f202";
}
.icon-paw:before {
  content: "\f203";
}
.icon-spoon:before {
  content: "\f204";
}
.icon-cube:before {
  content: "\f205";
}
.icon-cubes:before {
  content: "\f206";
}
.icon-behance1:before {
  content: "\f207";
}
.icon-behance-square:before {
  content: "\f208";
}
.icon-steam1:before {
  content: "\f209";
}
.icon-steam-square:before {
  content: "\f20a";
}
.icon-recycle:before {
  content: "\f20b";
}
.icon-automobile:before {
  content: "\f20c";
}
.icon-car:before {
  content: "\f20c";
}
.icon-cab:before {
  content: "\f20d";
}
.icon-taxi:before {
  content: "\f20d";
}
.icon-tree1:before {
  content: "\f20e";
}
.icon-spotify1:before {
  content: "\f20f";
}
.icon-deviantart1:before {
  content: "\f210";
}
.icon-soundcloud1:before {
  content: "\f211";
}
.icon-database1:before {
  content: "\f212";
}
.icon-file-pdf-o:before {
  content: "\f213";
}
.icon-file-word-o:before {
  content: "\f214";
}
.icon-file-excel-o:before {
  content: "\f215";
}
.icon-file-powerpoint-o:before {
  content: "\f216";
}
.icon-file-image-o:before {
  content: "\f217";
}
.icon-file-photo-o:before {
  content: "\f217";
}
.icon-file-picture-o:before {
  content: "\f217";
}
.icon-file-archive-o:before {
  content: "\f218";
}
.icon-file-zip-o:before {
  content: "\f218";
}
.icon-file-audio-o:before {
  content: "\f219";
}
.icon-file-sound-o:before {
  content: "\f219";
}
.icon-file-movie-o:before {
  content: "\f21a";
}
.icon-file-video-o:before {
  content: "\f21a";
}
.icon-file-code-o:before {
  content: "\f21b";
}
.icon-vine1:before {
  content: "\f21c";
}
.icon-codepen1:before {
  content: "\f21d";
}
.icon-jsfiddle:before {
  content: "\f21e";
}
.icon-life-bouy:before {
  content: "\f21f";
}
.icon-life-buoy:before {
  content: "\f21f";
}
.icon-life-ring:before {
  content: "\f21f";
}
.icon-life-saver:before {
  content: "\f21f";
}
.icon-support:before {
  content: "\f21f";
}
.icon-circle-o-notch:before {
  content: "\f220";
}
.icon-ra:before {
  content: "\f221";
}
.icon-rebel:before {
  content: "\f221";
}
.icon-resistance:before {
  content: "\f221";
}
.icon-empire:before {
  content: "\f222";
}
.icon-ge:before {
  content: "\f222";
}
.icon-git-square:before {
  content: "\f223";
}
.icon-git1:before {
  content: "\f224";
}
.icon-hacker-news:before {
  content: "\f225";
}
.icon-y-combinator-square:before {
  content: "\f225";
}
.icon-yc-square:before {
  content: "\f225";
}
.icon-tencent-weibo:before {
  content: "\f226";
}
.icon-qq:before {
  content: "\f227";
}
.icon-wechat:before {
  content: "\f228";
}
.icon-weixin:before {
  content: "\f228";
}
.icon-paper-plane:before {
  content: "\f229";
}
.icon-send:before {
  content: "\f229";
}
.icon-paper-plane-o:before {
  content: "\f22a";
}
.icon-send-o:before {
  content: "\f22a";
}
.icon-history1:before {
  content: "\f22b";
}
.icon-circle-thin:before {
  content: "\f22c";
}
.icon-header:before {
  content: "\f22d";
}
.icon-paragraph:before {
  content: "\f22e";
}
.icon-sliders:before {
  content: "\f22f";
}
.icon-share-alt:before {
  content: "\f230";
}
.icon-share-alt-square:before {
  content: "\f231";
}
.icon-bomb:before {
  content: "\f232";
}
.icon-futbol-o:before {
  content: "\f233";
}
.icon-soccer-ball-o:before {
  content: "\f233";
}
.icon-tty:before {
  content: "\f234";
}
.icon-binoculars1:before {
  content: "\f235";
}
.icon-plug:before {
  content: "\f236";
}
.icon-slideshare:before {
  content: "\f237";
}
.icon-twitch1:before {
  content: "\f238";
}
.icon-yelp1:before {
  content: "\f239";
}
.icon-newspaper-o:before {
  content: "\f23a";
}
.icon-wifi:before {
  content: "\f23b";
}
.icon-calculator1:before {
  content: "\f23c";
}
.icon-paypal1:before {
  content: "\f23d";
}
.icon-google-wallet:before {
  content: "\f23e";
}
.icon-cc-visa:before {
  content: "\f23f";
}
.icon-cc-mastercard:before {
  content: "\f240";
}
.icon-cc-discover:before {
  content: "\f241";
}
.icon-cc-amex:before {
  content: "\f242";
}
.icon-cc-paypal:before {
  content: "\f243";
}
.icon-cc-stripe:before {
  content: "\f244";
}
.icon-bell-slash:before {
  content: "\f245";
}
.icon-bell-slash-o:before {
  content: "\f246";
}
.icon-trash:before {
  content: "\f247";
}
.icon-copyright:before {
  content: "\f248";
}
.icon-at:before {
  content: "\f249";
}
.icon-eyedropper1:before {
  content: "\f24a";
}
.icon-paint-brush:before {
  content: "\f24b";
}
.icon-birthday-cake:before {
  content: "\f24c";
}
.icon-area-chart:before {
  content: "\f24d";
}
.icon-pie-chart1:before {
  content: "\f24e";
}
.icon-line-chart:before {
  content: "\f24f";
}
.icon-lastfm1:before {
  content: "\f250";
}
.icon-lastfm-square:before {
  content: "\f251";
}
.icon-toggle-off:before {
  content: "\f252";
}
.icon-toggle-on:before {
  content: "\f253";
}
.icon-bicycle:before {
  content: "\f254";
}
.icon-bus:before {
  content: "\f255";
}
.icon-ioxhost:before {
  content: "\f256";
}
.icon-angellist:before {
  content: "\f257";
}
.icon-cc:before {
  content: "\f258";
}
.icon-ils:before {
  content: "\f259";
}
.icon-shekel:before {
  content: "\f259";
}
.icon-sheqel:before {
  content: "\f259";
}
.icon-meanpath:before {
  content: "\f25a";
}
.icon-buysellads:before {
  content: "\f25b";
}
.icon-connectdevelop:before {
  content: "\f25c";
}
.icon-dashcube:before {
  content: "\f25d";
}
.icon-forumbee:before {
  content: "\f25e";
}
.icon-leanpub:before {
  content: "\f25f";
}
.icon-sellsy:before {
  content: "\f260";
}
.icon-shirtsinbulk:before {
  content: "\f261";
}
.icon-simplybuilt:before {
  content: "\f262";
}
.icon-skyatlas:before {
  content: "\f263";
}
.icon-cart-plus:before {
  content: "\f264";
}
.icon-cart-arrow-down:before {
  content: "\f265";
}
.icon-diamond:before {
  content: "\f266";
}
.icon-ship:before {
  content: "\f267";
}
.icon-user-secret:before {
  content: "\f268";
}
.icon-motorcycle:before {
  content: "\f269";
}
.icon-street-view:before {
  content: "\f26a";
}
.icon-heartbeat:before {
  content: "\f26b";
}
.icon-venus:before {
  content: "\f26c";
}
.icon-mars:before {
  content: "\f26d";
}
.icon-mercury:before {
  content: "\f26e";
}
.icon-intersex:before {
  content: "\f26f";
}
.icon-transgender:before {
  content: "\f26f";
}
.icon-transgender-alt:before {
  content: "\f270";
}
.icon-venus-double:before {
  content: "\f271";
}
.icon-mars-double:before {
  content: "\f272";
}
.icon-venus-mars:before {
  content: "\f273";
}
.icon-mars-stroke:before {
  content: "\f274";
}
.icon-mars-stroke-v:before {
  content: "\f275";
}
.icon-mars-stroke-h:before {
  content: "\f276";
}
.icon-neuter:before {
  content: "\f277";
}
.icon-genderless:before {
  content: "\f278";
}
.icon-facebook-official:before {
  content: "\f279";
}
.icon-pinterest-p:before {
  content: "\f27a";
}
.icon-whatsapp1:before {
  content: "\f27b";
}
.icon-server:before {
  content: "\f27c";
}
.icon-user-plus1:before {
  content: "\f27d";
}
.icon-user-times:before {
  content: "\f27e";
}
.icon-bed:before {
  content: "\f27f";
}
.icon-hotel:before {
  content: "\f27f";
}
.icon-viacoin:before {
  content: "\f280";
}
.icon-train:before {
  content: "\f281";
}
.icon-subway:before {
  content: "\f282";
}
.icon-medium:before {
  content: "\f283";
}
.icon-y-combinator:before {
  content: "\f284";
}
.icon-yc:before {
  content: "\f284";
}
.icon-optin-monster:before {
  content: "\f285";
}
.icon-opencart:before {
  content: "\f286";
}
.icon-expeditedssl:before {
  content: "\f287";
}
.icon-battery:before {
  content: "\f288";
}
.icon-battery-4:before {
  content: "\f288";
}
.icon-battery-full:before {
  content: "\f288";
}
.icon-battery-3:before {
  content: "\f289";
}
.icon-battery-three-quarters:before {
  content: "\f289";
}
.icon-battery-2:before {
  content: "\f28a";
}
.icon-battery-half:before {
  content: "\f28a";
}
.icon-battery-1:before {
  content: "\f28b";
}
.icon-battery-quarter:before {
  content: "\f28b";
}
.icon-battery-0:before {
  content: "\f28c";
}
.icon-battery-empty:before {
  content: "\f28c";
}
.icon-mouse-pointer:before {
  content: "\f28d";
}
.icon-i-cursor:before {
  content: "\f28e";
}
.icon-object-group:before {
  content: "\f28f";
}
.icon-object-ungroup:before {
  content: "\f290";
}
.icon-sticky-note:before {
  content: "\f291";
}
.icon-sticky-note-o:before {
  content: "\f292";
}
.icon-cc-jcb:before {
  content: "\f293";
}
.icon-cc-diners-club:before {
  content: "\f294";
}
.icon-clone:before {
  content: "\f295";
}
.icon-balance-scale:before {
  content: "\f296";
}
.icon-hourglass-o:before {
  content: "\f297";
}
.icon-hourglass-1:before {
  content: "\f298";
}
.icon-hourglass-start:before {
  content: "\f298";
}
.icon-hourglass-2:before {
  content: "\f299";
}
.icon-hourglass-half:before {
  content: "\f299";
}
.icon-hourglass-3:before {
  content: "\f29a";
}
.icon-hourglass-end:before {
  content: "\f29a";
}
.icon-hourglass:before {
  content: "\f29b";
}
.icon-hand-grab-o:before {
  content: "\f29c";
}
.icon-hand-rock-o:before {
  content: "\f29c";
}
.icon-hand-paper-o:before {
  content: "\f29d";
}
.icon-hand-stop-o:before {
  content: "\f29d";
}
.icon-hand-scissors-o:before {
  content: "\f29e";
}
.icon-hand-lizard-o:before {
  content: "\f29f";
}
.icon-hand-spock-o:before {
  content: "\f2a0";
}
.icon-hand-pointer-o:before {
  content: "\f2a1";
}
.icon-hand-peace-o:before {
  content: "\f2a2";
}
.icon-trademark:before {
  content: "\f2a3";
}
.icon-registered:before {
  content: "\f2a4";
}
.icon-creative-commons:before {
  content: "\f2a5";
}
.icon-gg:before {
  content: "\f2a6";
}
.icon-gg-circle:before {
  content: "\f2a7";
}
.icon-tripadvisor:before {
  content: "\f2a8";
}
.icon-odnoklassniki:before {
  content: "\f2a9";
}
.icon-odnoklassniki-square:before {
  content: "\f2aa";
}
.icon-get-pocket:before {
  content: "\f2ab";
}
.icon-wikipedia-w:before {
  content: "\f2ac";
}
.icon-safari1:before {
  content: "\f2ad";
}
.icon-chrome1:before {
  content: "\f2ae";
}
.icon-firefox1:before {
  content: "\f2af";
}
.icon-opera1:before {
  content: "\f2b0";
}
.icon-internet-explorer:before {
  content: "\f2b1";
}
.icon-television:before {
  content: "\f2b2";
}
.icon-tv1:before {
  content: "\f2b2";
}
.icon-contao:before {
  content: "\f2b3";
}
.icon-500px1:before {
  content: "\f2b4";
}
.icon-amazon1:before {
  content: "\f2b5";
}
.icon-calendar-plus-o:before {
  content: "\f2b6";
}
.icon-calendar-minus-o:before {
  content: "\f2b7";
}
.icon-calendar-times-o:before {
  content: "\f2b8";
}
.icon-calendar-check-o:before {
  content: "\f2b9";
}
.icon-industry:before {
  content: "\f2ba";
}
.icon-map-pin:before {
  content: "\f2bb";
}
.icon-map-signs:before {
  content: "\f2bc";
}
.icon-map-o:before {
  content: "\f2bd";
}
.icon-map1:before {
  content: "\f2be";
}
.icon-commenting:before {
  content: "\f2bf";
}
.icon-commenting-o:before {
  content: "\f2c0";
}
.icon-houzz:before {
  content: "\f2c1";
}
.icon-vimeo1:before {
  content: "\f2c2";
}
.icon-black-tie:before {
  content: "\f2c3";
}
.icon-fonticons:before {
  content: "\f2c4";
}
.icon-reddit-alien:before {
  content: "\f2c5";
}
.icon-edge1:before {
  content: "\f2c6";
}
.icon-credit-card-alt:before {
  content: "\f2c7";
}
.icon-codiepie:before {
  content: "\f2c8";
}
.icon-modx:before {
  content: "\f2c9";
}
.icon-fort-awesome:before {
  content: "\f2ca";
}
.icon-usb:before {
  content: "\f2cb";
}
.icon-product-hunt:before {
  content: "\f2cc";
}
.icon-mixcloud:before {
  content: "\f2cd";
}
.icon-scribd:before {
  content: "\f2ce";
}
.icon-pause-circle:before {
  content: "\f2cf";
}
.icon-pause-circle-o:before {
  content: "\f2d0";
}
.icon-stop-circle:before {
  content: "\f2d1";
}
.icon-stop-circle-o:before {
  content: "\f2d2";
}
.icon-shopping-bag:before {
  content: "\f2d3";
}
.icon-shopping-basket:before {
  content: "\f2d4";
}
.icon-hashtag:before {
  content: "\f2d5";
}
.icon-bluetooth:before {
  content: "\f2d6";
}
.icon-bluetooth-b:before {
  content: "\f2d7";
}
.icon-percent:before {
  content: "\f2d8";
}
.icon-gitlab:before {
  content: "\f2d9";
}
.icon-wpbeginner:before {
  content: "\f2da";
}
.icon-wpforms:before {
  content: "\f2db";
}
.icon-envira:before {
  content: "\f2dc";
}
.icon-universal-access:before {
  content: "\f2dd";
}
.icon-wheelchair-alt:before {
  content: "\f2de";
}
.icon-question-circle-o:before {
  content: "\f2df";
}
.icon-blind:before {
  content: "\f2e0";
}
.icon-audio-description:before {
  content: "\f2e1";
}
.icon-volume-control-phone:before {
  content: "\f2e2";
}
.icon-braille:before {
  content: "\f2e3";
}
.icon-assistive-listening-systems:before {
  content: "\f2e4";
}
.icon-american-sign-language-interpreting:before {
  content: "\f2e5";
}
.icon-asl-interpreting:before {
  content: "\f2e5";
}
.icon-deaf:before {
  content: "\f2e6";
}
.icon-deafness:before {
  content: "\f2e6";
}
.icon-hard-of-hearing:before {
  content: "\f2e6";
}
.icon-glide:before {
  content: "\f2e7";
}
.icon-glide-g:before {
  content: "\f2e8";
}
.icon-sign-language:before {
  content: "\f2e9";
}
.icon-signing:before {
  content: "\f2e9";
}
.icon-low-vision:before {
  content: "\f2ea";
}
.icon-viadeo:before {
  content: "\f2eb";
}
.icon-viadeo-square:before {
  content: "\f2ec";
}
.icon-snapchat:before {
  content: "\f2ed";
}
.icon-snapchat-ghost:before {
  content: "\f2ee";
}
.icon-snapchat-square:before {
  content: "\f2ef";
}
.icon-pied-piper:before {
  content: "\f2f0";
}
.icon-first-order:before {
  content: "\f2f1";
}
.icon-yoast:before {
  content: "\f2f2";
}
.icon-themeisle:before {
  content: "\f2f3";
}
.icon-google-plus-circle:before {
  content: "\f2f4";
}
.icon-google-plus-official:before {
  content: "\f2f4";
}
.icon-fa:before {
  content: "\f2f5";
}
.icon-font-awesome:before {
  content: "\f2f5";
}
.icon-handshake-o:before {
  content: "\f2f6";
}
.icon-envelope-open:before {
  content: "\f2f7";
}
.icon-envelope-open-o:before {
  content: "\f2f8";
}
.icon-linode:before {
  content: "\f2f9";
}
.icon-address-book1:before {
  content: "\f2fa";
}
.icon-address-book-o:before {
  content: "\f2fb";
}
.icon-address-card:before {
  content: "\f2fc";
}
.icon-vcard:before {
  content: "\f2fc";
}
.icon-address-card-o:before {
  content: "\f2fd";
}
.icon-vcard-o:before {
  content: "\f2fd";
}
.icon-user-circle:before {
  content: "\f2fe";
}
.icon-user-circle-o:before {
  content: "\f2ff";
}
.icon-user-o:before {
  content: "\f300";
}
.icon-id-badge:before {
  content: "\f301";
}
.icon-drivers-license:before {
  content: "\f302";
}
.icon-id-card:before {
  content: "\f302";
}
.icon-drivers-license-o:before {
  content: "\f303";
}
.icon-id-card-o:before {
  content: "\f303";
}
.icon-quora:before {
  content: "\f304";
}
.icon-free-code-camp:before {
  content: "\f305";
}
.icon-telegram1:before {
  content: "\f306";
}
.icon-thermometer:before {
  content: "\f307";
}
.icon-thermometer-4:before {
  content: "\f307";
}
.icon-thermometer-full:before {
  content: "\f307";
}
.icon-thermometer-3:before {
  content: "\f308";
}
.icon-thermometer-three-quarters:before {
  content: "\f308";
}
.icon-thermometer-2:before {
  content: "\f309";
}
.icon-thermometer-half:before {
  content: "\f309";
}
.icon-thermometer-1:before {
  content: "\f30a";
}
.icon-thermometer-quarter:before {
  content: "\f30a";
}
.icon-thermometer-0:before {
  content: "\f30b";
}
.icon-thermometer-empty:before {
  content: "\f30b";
}
.icon-shower:before {
  content: "\f30c";
}
.icon-bath:before {
  content: "\f30d";
}
.icon-bathtub:before {
  content: "\f30d";
}
.icon-s15:before {
  content: "\f30d";
}
.icon-podcast1:before {
  content: "\f30e";
}
.icon-window-maximize:before {
  content: "\f30f";
}
.icon-window-minimize:before {
  content: "\f310";
}
.icon-window-restore:before {
  content: "\f311";
}
.icon-times-rectangle:before {
  content: "\f312";
}
.icon-window-close:before {
  content: "\f312";
}
.icon-times-rectangle-o:before {
  content: "\f313";
}
.icon-window-close-o:before {
  content: "\f313";
}
.icon-bandcamp:before {
  content: "\f314";
}
.icon-grav:before {
  content: "\f315";
}
.icon-etsy:before {
  content: "\f316";
}
.icon-imdb:before {
  content: "\f317";
}
.icon-ravelry:before {
  content: "\f318";
}
.icon-eercast:before {
  content: "\f319";
}
.icon-microchip:before {
  content: "\f31a";
}
.icon-snowflake-o:before {
  content: "\f31b";
}
.icon-superpowers:before {
  content: "\f31c";
}
.icon-wpexplorer:before {
  content: "\f31d";
}
.icon-meetup:before {
  content: "\f31e";
}
