.sgSavePopupContainer {
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  cursor: not-allowed;

  .sgPopupBody {
    z-index: 99;
    cursor: default;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    min-width: 250px;

    .sgForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    input {
      width: 100%;
      border: 1px solid #d9d9d9;
      height: auto;
      outline: none;
      padding: 10px 15px;
      border-radius: 10px;
    }

    .btn {
      margin-top: 10px;
      height: auto;
      padding: 5px 15px;
    }
  }
}
