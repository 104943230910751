@import "../../../../shared/variables";

.shapePopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  .sPopupBg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    cursor: not-allowed;
  }

  .sPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-width: calc(100% - 30px);
    border-radius: 10px;
    overflow: hidden;

    .sPopupHeader {
      background-color: $borderColor2;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sPopupBody {
      background-color: $whiteColor;
      padding: 15px;

      .sFormGroup {
        margin-bottom: 15px;
      }
      .sFormLabel {
        display: block;
        margin-bottom: 5px;
      }
      .sFormControl {
        width: 100%;
        border: 2px solid $borderColor;
        border-radius: 8px;
        padding: 10px 15px;
      }

      .sImageUpload {
        input[type="file"] {
          display: none;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        padding: 15px;
        border: 5px dashed $borderColor;
        text-align: center;
        cursor: pointer;
      }
    }
    .previewImage {
      width: 100px;
      height: 100px;
      object-fit: contain;
      border: 2px solid $borderColor;
      border-radius: 20px;
      margin-bottom: 10px;
    }
  }
}
